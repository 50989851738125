import React from "react";
import { Route, Redirect } from "react-router-dom";
import DashboardComponent from "./modules/dashboard/DashboardComponent";
import SettingsComponent from "./modules/administration/settings/SettingsComponent";
import {} from "./api/HttpClient";
import { LOCALE } from "./properties/Locale";
import {} from "./api/HttpClient";
import {} from "./properties/Locale";
import styles from "./assets/jss/layouts/appStyle";
import { makeStyles } from "@material-ui/core/styles";
import { routes } from "./properties/routes";
import bgImage from "./assets/img/progress.jpg";
import logo from "./assets/img/grb.png";
import Sidebar from "./common/components/Sidebar";
import Header from "./common/components/Header";
import PerfectScrollbar from "perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import { COMMON_ACTIONS } from "./common/actions/CommonActions";
import Snackbar from "./common/components/Snackbar/Snackbar.js";
import UsersComponent from "./modules/administration/users/UsersComponent";
import PrivilegesComponent from "./modules/administration/privileges/PrivilegesComponent";
import GroupsComponent from "./modules/administration/groups/GroupsComponent";
import UserDetailsComponent from "./modules/administration/users/UserDetailsComponent";
import UserEditComponent from "./modules/administration/users/UserEditComponent";
import UserDeleteComponent from "./modules/administration/users/UserDeleteComponent";
import GroupDetailsComponent from "./modules/administration/groups/GroupDetailsComponent";
import GroupEditComponent from "./modules/administration/groups/GroupEditComponent";
import GroupCreateComponent from "./modules/administration/groups/GroupCreateComponent";
import GroupDeleteComponent from "./modules/administration/groups/GroupsDeleteComponent";
import UserCreateComponent from "./modules/administration/users/UserCreateComponent";
import AuditLogComponent from "./modules/administration/audit/AuditLogComponent";
import MailLogComponent from "./modules/administration/mail/MailLogComponent";
import MailTemplateComponent from "./modules/administration/mail/MailTemplateComponent";
import UserProfileComponent from "./modules/profile/UserProfileComponent";
import { UsersRepository } from "./modules/administration/users/UsersRepository";
import { changePageTitle } from "./common/actions/CommonActions";

import { AuthRepository } from "./auth/AuthRepository";
import { SETTINGS } from "./properties/ApiProps";
import MonitoringComponent from "./modules/administration/monitoring/MonitoringComponent";
import ClientChartComponent from "./modules/client/chart/ClientChartComponent";
import UploadReportComponent from "./modules/client/report/UploadReportComponent";
import UpdateImapDetailsComponent from "./modules/client/imapDetails/UpdateImapDetailsComponent";
import AutomatizationComponent from "./modules/client/imapDetails/AutomatizationComponent";
import DomainChecksComponent from "./modules/client/domain/DomainChecksComponent";
import DomainChecksAdminComponent from "./modules/administration/domainCheck/DomainChecksAdminComponent";
import CreateDomainComponent from "./modules/administration/domainCheck/CreateDomainComponent";
import EditDomainComponent from "./modules/administration/domainCheck/EditDomainComponent";
import DeleteDomainComponent from "./modules/administration/domainCheck/DeleteDomainComponent";
import DmarcReportsComponent from "./modules/administration/dmarcReports/DmarcReportsComponent";

let ps;
const useStyles = makeStyles(styles);

export default function App() {
  const classes = useStyles();
  const globalState = useSelector((state) => state);
  const dispatch = useDispatch();
  const mainPanel = React.createRef();

  const resizeFunction = () => {
    dispatch({
      type: COMMON_ACTIONS.MOBILE_SCREEN,
      payload: {
        mobileOpen: false,
      },
    });
  };

  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(mainPanel.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //     document.body.style.overflow = "hidden";
  //   }
  //   window.addEventListener("resize", resizeFunction);
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //     window.removeEventListener("resize", resizeFunction);
  //   };
  // }, [mainPanel, resizeFunction]);

  React.useEffect(() => {
    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("currentUser")
    ) {
      UsersRepository.fetchCurrentUser().then(
        (res) => {},
        (err) => {
          AuthRepository.logout();
        }
      );
    }
  }, []);

  const handleClose = () => {
    if (globalState.open) {
      dispatch({
        type: COMMON_ACTIONS.CLEAR_NOTIFICATIONS,
        payload: null,
      });
    }
  };

  return (
    <div className={classes.wrapper}>
      <div style={{ minHeight: "4px" }}>
        {globalState.loading && <LinearProgress color="secondary" />}
      </div>
      <Sidebar
        routes={routes}
        // logoText={LOCALE.login_h2}
        logo={logo}
        image={bgImage}
        handleDrawerToggle={globalState.mobileOpen}
        open={globalState.mobileOpen}
        color={"blue"}
      />

      {globalState.open && (
        <Snackbar
          place="br"
          icon={globalState.variant}
          color={globalState.variant}
          message={globalState.message}
          open={true}
          closeNotification={handleClose}
          close
        />
      )}

      <div className={classes.mainPanel}>
        <Header />
        <div className={classes.content} ref={mainPanel}>
          <div className={classes.container}>
            <Route>
              {/* ======================= DEFAULT REDIRECT ROUTE ======================= */}
              <Route
                exact
                path="/"
                render={() => <Redirect to="/app/dashboard" />}
              />

              {/* ======================= DASHBOARD ROUTES ======================= */}
              <Route
                path="/app/dashboard"
                exact
                component={DashboardComponent}
              />
              {/* ======================= SETTINGS ROUTES ======================= */}
              <Route
                path="/app/admin/settings"
                exact
                component={SettingsComponent}
              />

              {/* ======================= USERS ROUTES ======================= */}
              <Route path="/app/admin/users" component={UsersComponent} />
              <Route
                path="/app/admin/users/create"
                component={UserCreateComponent}
              />
              <Route
                path="/app/admin/users/:userId/details"
                component={UserDetailsComponent}
              />
              <Route
                path="/app/admin/users/:userId/edit"
                component={UserEditComponent}
              />
              <Route
                path="/app/admin/users/:userId/delete"
                component={UserDeleteComponent}
              />

              {/* ======================= GROUPS ROUTES ======================= */}
              <Route path="/app/admin/groups" component={GroupsComponent} />
              <Route
                path="/app/admin/privileges"
                exact
                component={PrivilegesComponent}
              />
              <Route
                path="/app/admin/groups/create"
                component={GroupCreateComponent}
              />
              <Route
                path="/app/admin/groups/:groupId/details"
                component={GroupDetailsComponent}
              />
              <Route
                path="/app/admin/groups/:groupId/edit"
                component={GroupEditComponent}
              />
              <Route
                path="/app/admin/groups/:groupId/delete"
                component={GroupDeleteComponent}
              />
              {/* ======================= AUDIT LOG ROUTES ======================= */}
              <Route path="/app/admin/auditLog" component={AuditLogComponent} />

              {/* ======================= MONITORING LOG ROUTES ======================= */}
              <Route
                path="/app/admin/monitoring"
                component={MonitoringComponent}
              />

              <Route path="/app/charts" component={ClientChartComponent} />

              <Route
                path="/app/upload_report"
                component={UploadReportComponent}
              />

              {/* ======================= MAIL ROUTE ======================= */}
              <Route path="/app/admin/mailLog" component={MailLogComponent} />
              <Route
                path="/app/admin/mailTemplate"
                component={MailTemplateComponent}
              />
              {/* ======================= USER PROFILE ROUTE ======================= */}
              <Route path="/app/profile" component={UserProfileComponent} />
              {/* <Route
                path="/app/imap_details"
                component={UpdateImapDetailsComponent}
              /> */}
              <Route
                path="/app/automatization"
                component={AutomatizationComponent}
              />
              {/* ======================= DOMAIN CHECK ROUTES ======================= */}
              <Route
                path="/app/domain_checks"
                component={DomainChecksComponent}
              />
              <Route
                path="/app/admin/domain_checks"
                component={DomainChecksAdminComponent}
              />
              <Route
                path="/app/admin/domain_checks/create"
                component={CreateDomainComponent}
              />
              <Route
                path="/app/admin/domain_checks/:domainId/edit"
                component={EditDomainComponent}
              ></Route>
              <Route
                path="/app/admin/domain_checks/:domainId/delete"
                component={DeleteDomainComponent}
              ></Route>
              <Route
                path="/app/admin/dmarc_reports"
                component={DmarcReportsComponent}
              />
            </Route>
          </div>
        </div>
      </div>
    </div>
  );
}
