import {
  Icon,
  Typography,
  Tooltip,
  Table,
  TableRow,
  TableCell,
  IconButton,
  TableBody,
  TextField,
  Grid,
  Collapse,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";

RowDomainCheckResults.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  expandComponent: PropTypes.any.isRequired,
};

export default function RowDomainCheckResults(props) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const { children, expandComponent, index, ...other } = props;
  const [headersLowerCaseHttp, setHeadersLowerCaseHttp] = React.useState();
  const [headersLowerCaseHttps, setHeadersLowerCaseHttps] = React.useState();

  const convertToLowerCaseHttp = (object) => {
    var json = JSON.stringify(object);
    var newJson = json.toLowerCase();
    setHeadersLowerCaseHttp(JSON.parse(newJson));
  };
  const convertToLowerCaseHttps = (object) => {
    var json = JSON.stringify(object);
    var newJson = json.toLowerCase();
    setHeadersLowerCaseHttps(JSON.parse(newJson));
  };

  useEffect(() => {
    if (
      isExpanded &&
      expandComponent?.domainChecks?.domainCheckType === "DOMAIN_HEADERS"
    ) {
      convertToLowerCaseHttp(JSON.parse(expandComponent?.result).httpHeaders);
      convertToLowerCaseHttps(JSON.parse(expandComponent?.result).httpsHeaders);
    }
  }, [isExpanded]);

  return (
    <React.Fragment>
      <TableRow
        style={{
          backgroundColor: index % 2 == 0 ? "#f5f5f5" : "",
          borderBottomStyle: "hidden",
          borderTop: isExpanded ? "black" : "black",
        }}
      >
        {children}

        <TableCell style={{ textAlign: "right" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? (
              <Icon color="primary">keyboard_arrow_left</Icon>
            ) : (
              <Icon>keyboard_arrow_down</Icon>
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={4}
        >
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Grid container spacing={2}>
              {expandComponent?.domainChecks?.domainCheckType ===
              "DOMAIN_WHOIS" ? (
                <>
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      component="div"
                      style={{ color: "#515A5A", paddingTop: 5 }}
                    >
                      <b>{" DOMAIN WHOIS Report"}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="whois-result"
                      variant="outlined"
                      fullWidth
                      value={
                        JSON.parse(expandComponent?.result).whois ??
                        JSON.parse(expandComponent?.result).whois
                      }
                      multiline
                      rows={30}
                      style={{ marginBottom: 10 }}
                    />
                  </Grid>
                </>
              ) : expandComponent?.domainChecks?.domainCheckType ===
                "MAIL_SERVER" ? (
                <>
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      component="div"
                      style={{ color: "#515A5A", paddingTop: 5 }}
                    >
                      <b>{"MAIL SERVER Report"}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Table size="small">
                      {expandComponent?.result &&
                        Object.keys(JSON.parse(expandComponent?.result)).map(
                          (item, index) => (
                            <>
                              <TableRow>
                                <TableCell
                                  style={{
                                    color: JSON.parse(expandComponent?.result)[
                                      item
                                    ].includes("p=none")
                                      ? "#E65100"
                                      : JSON.parse(expandComponent?.result)[
                                          item
                                        ].includes("p=quarantine")
                                      ? "#E65100"
                                      : JSON.parse(expandComponent?.result)[
                                          item
                                        ].includes("p=reject")
                                      ? "#2E7D32"
                                      : JSON.parse(expandComponent?.result)[
                                          item
                                        ].includes("-all")
                                      ? "#2E7D32"
                                      : JSON.parse(expandComponent?.result)[
                                          item
                                        ].includes("+all")
                                      ? "#E65100"
                                      : JSON.parse(expandComponent?.result)[
                                          item
                                        ].includes("~all")
                                      ? "#2E7D32"
                                      : "",
                                    width: "10%",
                                  }}
                                >
                                  <b>{item}</b>
                                </TableCell>
                                <TableCell
                                  style={{
                                    wordWrap: "break-word",
                                    wordBreak: "break-all",
                                    backgroundColor: JSON.parse(
                                      expandComponent?.result
                                    )[item].includes("p=none")
                                      ? "#FBEEE6"
                                      : JSON.parse(expandComponent?.result)[
                                          item
                                        ].includes("p=quarantine")
                                      ? "#FBEEE6"
                                      : JSON.parse(expandComponent?.result)[
                                          item
                                        ].includes("p=reject")
                                      ? "#EAFAF1"
                                      : JSON.parse(expandComponent?.result)[
                                          item
                                        ].includes("-all")
                                      ? "#EAFAF1"
                                      : JSON.parse(expandComponent?.result)[
                                          item
                                        ].includes("+all")
                                      ? "#FBEEE6"
                                      : JSON.parse(expandComponent?.result)[
                                          item
                                        ].includes("~all")
                                      ? "#EAFAF1"
                                      : "",
                                  }}
                                >
                                  {JSON.parse(expandComponent?.result)[
                                    item
                                  ].includes("p=none") ? (
                                    <div style={{ display: "flex" }}>
                                      {item +
                                        ": " +
                                        JSON.parse(expandComponent?.result)[
                                          item
                                        ]}
                                      <Tooltip
                                        title={
                                          "DMARC policy: p=none. Оди кон повеќе информации"
                                        }
                                      >
                                        <Link
                                          to={`/dmarc`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Icon
                                            style={{
                                              color: "#e65100",
                                              marginLeft: 5,
                                            }}
                                          >
                                            privacy_tip
                                          </Icon>
                                        </Link>
                                      </Tooltip>
                                    </div>
                                  ) : JSON.parse(expandComponent?.result)[
                                      item
                                    ].includes("p=quarantine") ? (
                                    <div style={{ display: "flex" }}>
                                      {item +
                                        ": " +
                                        JSON.parse(expandComponent?.result)[
                                          item
                                        ]}
                                      <Tooltip
                                        title={
                                          "DMARC policy: p=quarantine. Оди кон повеќе информации"
                                        }
                                      >
                                        <Link
                                          to={`/dmarc`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Icon
                                            style={{
                                              color: "#ed6c02",
                                              marginLeft: 5,
                                            }}
                                          >
                                            privacy_tip
                                          </Icon>
                                        </Link>
                                      </Tooltip>
                                    </div>
                                  ) : JSON.parse(expandComponent?.result)[
                                      item
                                    ].includes("p=reject") ? (
                                    <div style={{ display: "flex" }}>
                                      {item +
                                        ": " +
                                        JSON.parse(expandComponent?.result)[
                                          item
                                        ]}
                                      <Tooltip
                                        title={
                                          "DMARC policy: p=reject. Оди кон повеќе информации"
                                        }
                                      >
                                        <Link
                                          to={`/dmarc`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Icon
                                            style={{
                                              color: "#2e7d32",
                                              marginLeft: 5,
                                            }}
                                          >
                                            privacy_tip
                                          </Icon>
                                        </Link>
                                      </Tooltip>
                                    </div>
                                  ) : JSON.parse(expandComponent?.result)[
                                      item
                                    ].includes("-all") ? (
                                    <div style={{ display: "flex" }}>
                                      {
                                        JSON.parse(expandComponent?.result)[
                                          item
                                        ]
                                      }

                                      <Tooltip
                                        title={
                                          "Сите ненаведени сервери не се овластени (пораките ќе бидат одбиени). Оди кон повеќе информации"
                                        }
                                      >
                                        <Link
                                          to={`/spf`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Icon
                                            style={{
                                              color: "#2e7d32",
                                              marginLeft: 5,
                                            }}
                                          >
                                            privacy_tip
                                          </Icon>
                                        </Link>
                                      </Tooltip>
                                    </div>
                                  ) : JSON.parse(expandComponent?.result)[
                                      item
                                    ].includes("+all") ? (
                                    <div style={{ display: "flex" }}>
                                      {
                                        JSON.parse(expandComponent?.result)[
                                          item
                                        ]
                                      }
                                      <Tooltip
                                        title={
                                          "Секој сервер е овластен (непожелна опција) Оди кон повеќе информации"
                                        }
                                      >
                                        <Link
                                          to={`/spf`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Icon
                                            style={{
                                              color: "#e65100",
                                              marginLeft: 5,
                                            }}
                                          >
                                            privacy_tip
                                          </Icon>
                                        </Link>
                                      </Tooltip>
                                    </div>
                                  ) : JSON.parse(expandComponent?.result)[
                                      item
                                    ].includes("~all") ? (
                                    <div style={{ display: "flex" }}>
                                      {
                                        JSON.parse(expandComponent?.result)[
                                          item
                                        ]
                                      }

                                      <Tooltip
                                        title={
                                          "Сите ненаведени сервери не се авторизирани, пораките ќе бидат обележани и прифатена (во спам папката од сандачето за е-пошта). Оди кон повеќе информации"
                                        }
                                      >
                                        <Link
                                          to={`/spf`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Icon
                                            style={{
                                              color: "#2e7d32",
                                              marginLeft: 5,
                                            }}
                                          >
                                            privacy_tip
                                          </Icon>
                                        </Link>
                                      </Tooltip>
                                    </div>
                                  ) : item.includes("DKIM") ? (
                                    <div style={{ display: "flex" }}>
                                      {
                                        JSON.parse(expandComponent?.result)[
                                          item
                                        ]
                                      }

                                      <Tooltip
                                        title={"Оди кон повеќе информации"}
                                      >
                                        <Link
                                          to={`/dkim`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {!JSON.parse(expandComponent?.result)[
                                            item
                                          ].includes(
                                            "Not found, error message:"
                                          ) && (
                                            <Icon
                                              style={{
                                                color: "#9e9e9e",
                                                marginLeft: 5,
                                              }}
                                            >
                                              privacy_tip
                                            </Icon>
                                          )}
                                        </Link>
                                      </Tooltip>
                                    </div>
                                  ) : (
                                    JSON.parse(expandComponent?.result)[item]
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow></TableRow>
                            </>
                          )
                        )}
                    </Table>
                  </Grid>
                </>
              ) : expandComponent?.domainChecks?.domainCheckType ===
                "DNS_LOOKUP" ? (
                <>
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      component="div"
                      style={{ color: "#515A5A", paddingTop: 5 }}
                    >
                      <b>{"DNS LOOKUP Report"}</b>
                      <br />
                    </Typography>
                    <Grid item xs={12}>
                      <Table size="small">
                        {Object.entries(
                          JSON.parse(expandComponent?.result)
                        ).map((entry) => {
                          const [key, value] = entry;
                          return key?.includes("Error") ? (
                            <TableRow>
                              <TableCell
                                style={{ color: "#F44336", fontSize: 16 }}
                              >
                                <b>{key}</b>
                              </TableCell>
                              <TableCell>
                                {value}
                                <Icon
                                  color="error"
                                  style={{ marginLeft: 5, marginTop: -2 }}
                                >
                                  error
                                </Icon>
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell style={{ width: "10%" }}>
                                <b>{key.slice(0, key.length - 2)}</b>
                              </TableCell>
                              <TableCell>{value}</TableCell>
                            </TableRow>
                          );
                        })}
                      </Table>
                    </Grid>
                  </Grid>
                </>
              ) : expandComponent?.domainChecks?.domainCheckType ===
                "DOMAIN_HEADERS" ? (
                <>
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      component="div"
                      style={{ color: "#515A5A", paddingTop: 5 }}
                    >
                      <b>{"DOMAIN HEADERS Report"}</b>
                      <br />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {expandComponent?.result &&
                      Object.entries(JSON.parse(expandComponent?.result)).map(
                        (entry) => {
                          const [key, value] = entry;

                          return (
                            <>
                              {key === "httpsHeaders" ? (
                                <>
                                  <Table size="small">
                                    {Object.keys(value).map((item, index) => (
                                      <>
                                        {index == 0 && (
                                          <>
                                            <TableRow
                                              style={{
                                                backgroundColor: "#e0e0e0",
                                              }}
                                            >
                                              <TableCell colSpan={2}>
                                                <b>HTTPS headers</b>
                                              </TableCell>
                                            </TableRow>
                                          </>
                                        )}
                                        <TableRow
                                          style={
                                            index % 2 === 0
                                              ? { backgroundColor: "#f5f5f5" }
                                              : {
                                                  backgroundColor: "#ffffff",
                                                }
                                          }
                                        >
                                          <TableCell
                                            style={{
                                              color:
                                                item
                                                  .toLowerCase()
                                                  .includes(
                                                    "Content-Security-Policy".toLowerCase()
                                                  ) ||
                                                item
                                                  .toLowerCase()
                                                  .includes(
                                                    "X-Content-Type-Options".toLowerCase()
                                                  ) ||
                                                item
                                                  .toLowerCase()
                                                  .includes(
                                                    "X-FRAME-OPTIONS".toLowerCase()
                                                  ) ||
                                                item
                                                  .toLowerCase()
                                                  .includes(
                                                    "Strict-Transport-Security".toLowerCase()
                                                  ) ||
                                                item
                                                  .toLowerCase()
                                                  .includes(
                                                    "Referrer-Policy".toLowerCase()
                                                  ) ||
                                                item
                                                  .toLowerCase()
                                                  .includes(
                                                    "x-xss-protection".toLowerCase()
                                                  ) ||
                                                item
                                                  .toLowerCase()
                                                  .includes(
                                                    "Permissions-Policy".toLowerCase()
                                                  )
                                                  ? "#28B463"
                                                  : "",
                                              width: "15%",
                                            }}
                                          >
                                            {item}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              wordWrap: "break-word",
                                              wordBreak: "break-all",
                                            }}
                                          >
                                            {value[item]}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}
                                  </Table>
                                  {!Object.keys(value).includes(
                                    "Error getting HTTPS headers"
                                  ) ? (
                                    <Table size="small">
                                      <TableRow
                                        style={{
                                          backgroundColor: "#e0e0e0",
                                        }}
                                      >
                                        <TableCell colSpan={2}>
                                          <b>Missing Headers</b>
                                        </TableCell>
                                      </TableRow>

                                      {headersLowerCaseHttps &&
                                        !Object.keys(
                                          headersLowerCaseHttps
                                        ).includes(
                                          "Referrer-Policy".toLowerCase()
                                        ) && (
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                color: "#D50000",
                                                border: "none",
                                              }}
                                            >
                                              <b>{"Referrer-Policy"}</b>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              {
                                                "Referrer Policy is a new header that allows a site to control how much information the browser includes with navigations away from a document and should be set by all sites."
                                              }
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      {headersLowerCaseHttps &&
                                        !Object.keys(
                                          headersLowerCaseHttps
                                        ).includes(
                                          "Permissions-Policy".toLowerCase()
                                        ) && (
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                color: "#D50000",
                                                border: "none",
                                              }}
                                            >
                                              <b>{"Permissions-Policy"}</b>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              {
                                                "Permissions Policy is a new header that allows a site to control which features and APIs can be used in the browser."
                                              }
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      {headersLowerCaseHttps &&
                                        !Object.keys(
                                          headersLowerCaseHttps
                                        ).includes(
                                          "Strict-Transport-Security".toLowerCase()
                                        ) && (
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                color: "#D50000",
                                                border: "none",
                                              }}
                                            >
                                              <b>
                                                {"Strict-Transport-Security"}
                                              </b>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              {
                                                "HTTP Strict Transport Security is an excellent feature to support on your site and strengthens your implementation of TLS by getting the User Agent to enforce the use of HTTPS. Recommended value 'Strict-Transport-Security: max-age=31536000; includeSubDomains'"
                                              }
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      {headersLowerCaseHttps &&
                                        !Object.keys(
                                          headersLowerCaseHttps
                                        ).includes(
                                          "X-Frame-Options".toLowerCase()
                                        ) && (
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                color: "#D50000",
                                                border: "none",
                                              }}
                                            >
                                              <b>{"X-Frame-Options"}</b>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              {
                                                "X-Frame-Options tells the browser whether you want to allow your site to be framed or not. By preventing a browser from framing your site you can defend against attacks like clickjacking. Recommended value 'X-Frame-Options: SAMEORIGIN'."
                                              }
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      {headersLowerCaseHttps &&
                                        !Object.keys(
                                          headersLowerCaseHttps
                                        ).includes(
                                          "X-Content-Type-Options".toLowerCase()
                                        ) && (
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                color: "#D50000",
                                                border: "none",
                                              }}
                                            >
                                              <b>{"X-Content-Type-Options"}</b>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              {
                                                "	X-Content-Type-Options stops a browser from trying to MIME-sniff the content type and forces it to stick with the declared content-type. The only valid value for this header is 'X-Content-Type-Options: nosniff'."
                                              }
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      {headersLowerCaseHttps &&
                                        !Object.keys(
                                          headersLowerCaseHttps
                                        ).includes(
                                          "Content-Security-Policy".toLowerCase()
                                        ) && (
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                color: "#D50000",
                                                border: "none",
                                              }}
                                            >
                                              <b>{"Content-Security-Policy"}</b>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              {
                                                "Content Security Policy is an effective measure to protect your site from XSS attacks. By whitelisting sources of approved content, you can prevent the browser from loading malicious assets."
                                              }
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      {headersLowerCaseHttps &&
                                        !Object.keys(
                                          headersLowerCaseHttps
                                        ).includes(
                                          "x-xss-protection".toLowerCase()
                                        ) && (
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                color: "#D50000",
                                                border: "none",
                                              }}
                                            >
                                              <b>{"x-xss-protection"}</b>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              {
                                                "X-XSS-Protection sets the configuration for the XSS Auditor built into older browsers. The recommended value was 'X-XSS-Protection: 1; mode=block'"
                                              }
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      <TableRow>
                                        <TableCell colSpan={2}></TableCell>
                                      </TableRow>
                                    </Table>
                                  ) : (
                                    ""
                                  )}

                                  <br />
                                </>
                              ) : key === "httpHeaders" ? (
                                <>
                                  <Table size="small">
                                    {Object.keys(value).map((item, index) => (
                                      <>
                                        {index == 0 && (
                                          <>
                                            <TableRow
                                              style={{
                                                backgroundColor: "#e0e0e0",
                                              }}
                                            >
                                              <TableCell colSpan={2}>
                                                <b>HTTP headers</b>
                                              </TableCell>
                                            </TableRow>
                                          </>
                                        )}
                                        <TableRow
                                          style={
                                            index % 2 === 0
                                              ? { backgroundColor: "#f5f5f5" }
                                              : {
                                                  backgroundColor: "#ffffff",
                                                }
                                          }
                                        >
                                          <TableCell
                                            style={{
                                              color:
                                                item
                                                  .toLowerCase()
                                                  .includes(
                                                    "Content-Security-Policy".toLowerCase()
                                                  ) ||
                                                item
                                                  .toLowerCase()
                                                  .includes(
                                                    "X-Content-Type-Options".toLowerCase()
                                                  ) ||
                                                item
                                                  .toLowerCase()
                                                  .includes(
                                                    "X-FRAME-OPTIONS".toLowerCase()
                                                  ) ||
                                                item
                                                  .toLowerCase()
                                                  .includes(
                                                    "Strict-Transport-Security".toLowerCase()
                                                  ) ||
                                                item
                                                  .toLowerCase()
                                                  .includes(
                                                    "x-xss-protection".toLowerCase()
                                                  ) ||
                                                item
                                                  .toLowerCase()
                                                  .includes(
                                                    "Referrer-Policy".toLowerCase()
                                                  ) ||
                                                item
                                                  .toLowerCase()
                                                  .includes(
                                                    "Permissions-Policy".toLowerCase()
                                                  )
                                                  ? "#28B463"
                                                  : "",
                                              width: "15%",
                                            }}
                                          >
                                            {item}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              wordWrap: "break-word",
                                              wordBreak: "break-all",
                                            }}
                                          >
                                            {value[item]}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))}
                                  </Table>
                                  {!Object.keys(value).includes(
                                    "Error getting HTTP headers"
                                  ) ? (
                                    <Table size="small">
                                      <TableRow
                                        style={{
                                          backgroundColor: "#e0e0e0",
                                        }}
                                      >
                                        <TableCell colSpan={2}>
                                          <b>Missing Headers</b>
                                        </TableCell>
                                      </TableRow>
                                      {headersLowerCaseHttp &&
                                        !Object.keys(
                                          headersLowerCaseHttp
                                        ).includes(
                                          "Referrer-Policy".toLowerCase()
                                        ) && (
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                color: "#D50000",
                                                border: "none",
                                              }}
                                            >
                                              <b>{"Referrer-Policy"}</b>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              {
                                                "Referrer Policy is a new header that allows a site to control how much information the browser includes with navigations away from a document and should be set by all sites."
                                              }
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      {headersLowerCaseHttp &&
                                        !Object.keys(
                                          headersLowerCaseHttp
                                        ).includes(
                                          "Permissions-Policy".toLowerCase()
                                        ) && (
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                color: "#D50000",
                                                border: "none",
                                              }}
                                            >
                                              <b>{"Permissions-Policy"}</b>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              {
                                                "Permissions Policy is a new header that allows a site to control which features and APIs can be used in the browser."
                                              }
                                            </TableCell>
                                          </TableRow>
                                        )}

                                      {headersLowerCaseHttp &&
                                        !Object.keys(
                                          headersLowerCaseHttp
                                        ).includes(
                                          "X-Frame-Options".toLowerCase()
                                        ) && (
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                color: "#D50000",
                                                border: "none",
                                              }}
                                            >
                                              <b>{"X-Frame-Options"}</b>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              {
                                                "X-Frame-Options tells the browser whether you want to allow your site to be framed or not. By preventing a browser from framing your site you can defend against attacks like clickjacking. Recommended value 'X-Frame-Options: SAMEORIGIN'."
                                              }
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      {headersLowerCaseHttp &&
                                        !Object.keys(
                                          headersLowerCaseHttp
                                        ).includes(
                                          "X-Content-Type-Options".toLowerCase()
                                        ) && (
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                color: "#D50000",
                                                border: "none",
                                              }}
                                            >
                                              <b>{"X-Content-Type-Options"}</b>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              {
                                                "	X-Content-Type-Options stops a browser from trying to MIME-sniff the content type and forces it to stick with the declared content-type. The only valid value for this header is 'X-Content-Type-Options: nosniff'."
                                              }
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      {headersLowerCaseHttp &&
                                        !Object.keys(
                                          headersLowerCaseHttp
                                        ).includes(
                                          "Strict-Transport-Security".toLowerCase()
                                        ) && (
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                color: "#D50000",
                                                border: "none",
                                              }}
                                            >
                                              <b>
                                                {"Strict-Transport-Security"}
                                              </b>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              {
                                                "HTTP Strict Transport Security is an excellent feature to support on your site and strengthens your implementation of TLS by getting the User Agent to enforce the use of HTTPS. Recommended value 'Strict-Transport-Security: max-age=31536000; includeSubDomains'"
                                              }
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      {headersLowerCaseHttp &&
                                        !Object.keys(
                                          headersLowerCaseHttp
                                        ).includes(
                                          "Content-Security-Policy".toLowerCase()
                                        ) && (
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                color: "#D50000",
                                                border: "none",
                                              }}
                                            >
                                              <b>{"Content-Security-Policy"}</b>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              {
                                                "Content Security Policy is an effective measure to protect your site from XSS attacks. By whitelisting sources of approved content, you can prevent the browser from loading malicious assets."
                                              }
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      {headersLowerCaseHttp &&
                                        !Object.keys(
                                          headersLowerCaseHttp
                                        ).includes(
                                          "x-xss-protection".toLowerCase()
                                        ) && (
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                color: "#D50000",
                                                border: "none",
                                              }}
                                            >
                                              <b>{"x-xss-protection"}</b>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              {
                                                "X-XSS-Protection sets the configuration for the XSS Auditor built into older browsers. The recommended value was 'X-XSS-Protection: 1; mode=block'"
                                              }
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      <TableRow>
                                        <TableCell colSpan={2}></TableCell>
                                      </TableRow>
                                    </Table>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }
                      )}
                    <br />
                  </Grid>
                </>
              ) : expandComponent?.domainChecks?.domainCheckType ===
                "DOMAIN_SSL" ? (
                <>
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      component="div"
                      style={{ color: "#515A5A", paddingTop: 5 }}
                    >
                      <b>{"DOMAIN SSL Report"}</b>
                      <br />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Table size="small">
                      {Object.keys(
                        JSON.parse(expandComponent?.result)
                      ).includes("Error")
                        ? Object.keys(JSON.parse(expandComponent?.result)).map(
                            (item, index) => (
                              <TableRow>
                                <TableCell
                                  style={{ color: "#F44336", fontSize: 16 }}
                                >
                                  <b>{item}</b>
                                </TableCell>
                                <TableCell>
                                  {JSON.parse(expandComponent?.result)[item]}
                                  <Icon
                                    color="error"
                                    style={{ marginLeft: 5, marginTop: -2 }}
                                  >
                                    error
                                  </Icon>
                                </TableCell>
                              </TableRow>
                            )
                          )
                        : Object.keys(JSON.parse(expandComponent?.result)).map(
                            (item, index) =>
                              Object.keys(
                                JSON.parse(expandComponent?.result)[item]
                              ).map((item2, index2) => {
                                let isExpiringSoon = false;
                                let isExpired = false;

                                if (item2.includes("Valid until")) {
                                  //check if certificate is expiring soon (40 days)
                                  const validUntil = moment(
                                    JSON.parse(expandComponent?.result)[item][
                                      item2
                                    ],
                                    "DD/MM/YYYY HH:mm:ss"
                                  );
                                  const date = moment();
                                  const diff = validUntil.diff(date, "days");
                                  if (diff <= 40) {
                                    isExpiringSoon = true;
                                  }
                                  if (diff <= 0) {
                                    isExpired = true;
                                  }
                                }
                                return (
                                  <>
                                    {index2 === 0 && (
                                      <TableRow
                                        style={{
                                          backgroundColor: "#e0e0e0",
                                        }}
                                      >
                                        <TableCell colSpan={2}>
                                          <b>{item}</b>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                    <TableRow
                                      style={
                                        index2 % 2 === 0
                                          ? { backgroundColor: "#f5f5f5" }
                                          : {
                                              backgroundColor: "#ffffff",
                                            }
                                      }
                                    >
                                      <TableCell
                                        style={{
                                          color: isExpiringSoon
                                            ? "#ED6C02"
                                            : isExpired
                                            ? "#78281F"
                                            : "",
                                        }}
                                      >
                                        {item2}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          wordWrap: "break-word",
                                          wordBreak: "break-all",
                                          backgroundColor: isExpiringSoon
                                            ? "#FBEEE6"
                                            : isExpired
                                            ? "#FADBD8"
                                            : "",
                                        }}
                                      >
                                        {item2.includes("Valid until") ? (
                                          isExpiringSoon === true ? (
                                            <div style={{ display: "inline" }}>
                                              {
                                                JSON.parse(
                                                  expandComponent?.result
                                                )[item][item2]
                                              }
                                              <Tooltip title="Сертификатот истекува наскоро">
                                                <Icon
                                                  style={{
                                                    color: "#ed6c02",
                                                    marginLeft: 15,
                                                    marginBottom: -5,
                                                  }}
                                                >
                                                  error
                                                </Icon>
                                              </Tooltip>
                                            </div>
                                          ) : isExpired === true ? (
                                            <div style={{ display: "inline" }}>
                                              {
                                                JSON.parse(
                                                  expandComponent?.result
                                                )[item][item2]
                                              }
                                              <Tooltip title="Истечен сертификат">
                                                <Icon
                                                  style={{
                                                    color: "#c62828",
                                                    marginLeft: 15,
                                                    marginBottom: -5,
                                                  }}
                                                >
                                                  error
                                                </Icon>
                                              </Tooltip>
                                            </div>
                                          ) : (
                                            JSON.parse(expandComponent?.result)[
                                              item
                                            ][item2]
                                          )
                                        ) : (
                                          JSON.parse(expandComponent?.result)[
                                            item
                                          ][item2]
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })
                          )}
                    </Table>
                    <br />
                  </Grid>
                </>
              ) : expandComponent?.domainChecks?.domainCheckType ===
                "TLS_SSL" ? (
                <>
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      component="div"
                      style={{ color: "#515A5A", paddingTop: 5 }}
                    >
                      <b>{"TLS/SSL Report"}</b>
                      <br />
                    </Typography>
                  </Grid>

                  {Object.keys(JSON.parse(expandComponent?.result)).includes(
                    "Error"
                  ) ? (
                    <Grid item xs={12}>
                      {Object.keys(JSON.parse(expandComponent?.result)).map(
                        (item, index) => (
                          <Table>
                            <TableRow>
                              <TableCell
                                style={{ color: "#F44336", fontSize: 16 }}
                              >
                                <b>{item}</b>
                              </TableCell>
                              <TableCell>
                                {JSON.parse(expandComponent?.result)[item]}
                                <Icon
                                  color="error"
                                  style={{ marginLeft: 5, marginTop: -2 }}
                                >
                                  error
                                </Icon>
                              </TableCell>
                            </TableRow>
                          </Table>
                        )
                      )}
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Table size="small">
                          <TableRow>
                            <TableCell
                              colSpan={2}
                              style={{ backgroundColor: "#E0E0E0" }}
                            >
                              <Typography variant="caption">
                                <b>Enabled TLS protocol versions</b>
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableBody>
                            {JSON.parse(expandComponent?.result) &&
                              Object.keys(
                                JSON.parse(expandComponent?.result)
                              ).map((item, index) => (
                                <>
                                  {item.includes("TLS") && (
                                    <TableRow
                                      style={
                                        index % 2 === 0
                                          ? { backgroundColor: "#f5f5f5" }
                                          : {}
                                      }
                                    >
                                      <TableCell>
                                        <b> {item}</b>
                                      </TableCell>

                                      <TableCell
                                        style={{
                                          color:
                                            item == "TLSv1.3" &&
                                            JSON.parse(expandComponent?.result)[
                                              item
                                            ] == "enabled"
                                              ? "green"
                                              : item == "TLSv1.2" &&
                                                JSON.parse(
                                                  expandComponent?.result
                                                )[item] == "enabled"
                                              ? "green"
                                              : item == "TLSv1.1" &&
                                                JSON.parse(
                                                  expandComponent?.result
                                                )[item] == "disabled"
                                              ? "green"
                                              : item == "TLSv1" &&
                                                JSON.parse(
                                                  expandComponent?.result
                                                )[item] == "disabled"
                                              ? "green"
                                              : item == "SSLv3" &&
                                                JSON.parse(
                                                  expandComponent?.result
                                                )[item] == "disabled"
                                              ? "green"
                                              : item == "SSLv2Hello" &&
                                                JSON.parse(
                                                  expandComponent?.result
                                                )[item] == "disabled"
                                              ? "green"
                                              : "red",
                                        }}
                                      >
                                        {
                                          JSON.parse(expandComponent?.result)[
                                            item
                                          ]
                                        }
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </>
                              ))}
                          </TableBody>
                        </Table>{" "}
                        <br />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Table size="small">
                          <TableRow>
                            <TableCell
                              style={{ backgroundColor: "#E0E0E0" }}
                              colSpan={2}
                            >
                              <Typography variant="caption">
                                <b>Enabled SSL protocol versions</b>
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableBody>
                            {JSON.parse(expandComponent?.result) &&
                              Object.keys(
                                JSON.parse(expandComponent?.result)
                              ).map((item, index) => (
                                <>
                                  {item.includes("SSL") && (
                                    <TableRow
                                      style={
                                        index % 2 === 0
                                          ? { backgroundColor: "#f5f5f5" }
                                          : {}
                                      }
                                    >
                                      <TableCell>
                                        <b> {item}</b>
                                      </TableCell>

                                      <TableCell
                                        style={{
                                          color:
                                            item == "TLSv1.3" &&
                                            JSON.parse(expandComponent?.result)[
                                              item
                                            ] == "enabled"
                                              ? "green"
                                              : item == "TLSv1.2" &&
                                                JSON.parse(
                                                  expandComponent?.result
                                                )[item] == "enabled"
                                              ? "green"
                                              : item == "TLSv1.1" &&
                                                JSON.parse(
                                                  expandComponent?.result
                                                )[item] == "disabled"
                                              ? "green"
                                              : item == "TLSv1" &&
                                                JSON.parse(
                                                  expandComponent?.result
                                                )[item] == "disabled"
                                              ? "green"
                                              : item == "SSLv3" &&
                                                JSON.parse(
                                                  expandComponent?.result
                                                )[item] == "disabled"
                                              ? "green"
                                              : item == "SSLv2Hello" &&
                                                JSON.parse(
                                                  expandComponent?.result
                                                )[item] == "disabled"
                                              ? "green"
                                              : "red",
                                        }}
                                      >
                                        {
                                          JSON.parse(expandComponent?.result)[
                                            item
                                          ]
                                        }
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </>
                              ))}
                          </TableBody>
                        </Table>{" "}
                        <br />
                      </Grid>
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
