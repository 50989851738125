import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";

export const validateLoginForm = yup.object().shape({
  username: yup
    .string()
    .required(LOCALE.login_username + LOCALE.required_field),
  password: yup
    .string()
    .required(LOCALE.login_password + LOCALE.required_field),
});

export const validateCreateForm = yup.object().shape({
  username: yup
    .string()
    .matches(/^[A-Za-z_-][A-Za-z0-9_-]{5,}$/, {
      message:
        LOCALE.incorrect_format +
        " (минимум 6 карактери, дозволени се мали и големи букви, цифри и -_)",
    })
    .required(LOCALE.username + LOCALE.required_field),
  name: yup.string().required(LOCALE.login_name + LOCALE.required_field),
  email: yup
    .string()
    .required(LOCALE.email + LOCALE.required_field)
    .email(LOCALE.invalid_email_address_format),
  password: yup
    .string()
    .required(LOCALE.login_password + LOCALE.required_field)
    .matches(
      /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      "Мора да содржи минимум 8 карактери, барем една голема буква, барем една мала буква, една цифра и еден специјален знак"
    ),
});
