import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  IconButton,
  Icon,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  LinearProgress,
  Grid,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
// @material-ui/styles
import styles from "../../../assets/jss/layouts/appStyle";
import { makeStyles } from "@material-ui/core/styles";
// core components
import { LOCALE } from "../../../properties/Locale";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";
import { UsersRepository } from "./UsersRepository";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
  triggerRerender,
} from "../../../common/actions/CommonActions";
import { useDispatch } from "react-redux";
import {
  validateCreateUserForm,
  validateCreateUserFormClient,
} from "./UserValidations";

const useStyles = makeStyles(styles);

export default function UserCreateComponent() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [userTypes, setUserTypes] = useState();
  const [redirectTo, setRedirectTo] = useState();
  const [user, setUser] = useState({
    name: "",
    email: "",
    username: "",
    password: "",
    imapHost: "",
    imapEmail: "",
    imapPassword: "",
    type: "ADMINISTRATOR",
  });
  const [formErrors, setFormErrors] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    UsersRepository.getUserTypes().then(
      (res) => {
        setUserTypes(res.data);
      },
      (err) => {}
    );
  }, []);

  useEffect(() => {
    resetUser();
  }, [user.type]);

  const createUser = () => {
    showLoadingBar();
    var validFormData = true;
    if (user.type === "ADMINISTRATOR") {
      if (!validateCreateUserForm.isValidSync(user)) {
        validFormData = false;
      }
    } else {
      if (!validateCreateUserFormClient.isValidSync(user)) {
        validFormData = false;
      }
    }
    if (validFormData) {
      UsersRepository.createUser(user).then(
        (res) => {
          hideLoadingBar();
          dispatch(triggerRerender());
          dispatch(
            notifyShowSuccessMessage(LOCALE.user_create_success_message)
          );
          setRedirectTo("/app/admin/users");
        },
        (err) => {
          hideLoadingBar();
          if (err.response?.data) {
            dispatch(notifyShowErrorMessage(err.response?.data));
          } else {
            dispatch(notifyShowErrorMessage(err.message));
          }
        }
      );
    } else {
      if (user.type === "ADMINISTRATOR") {
        var validationErrors = {};
        (async () => {
          await validateCreateUserForm
            .validate(user, { abortEarly: false })
            .catch((err) => {
              err.inner.forEach((validationError) => {
                validationErrors[validationError.path] = {};
                validationErrors[validationError.path] =
                  validationError.message;
              });
            });
          hideLoadingBar();
          setFormErrors(validationErrors);
        })();
      } else {
        var validationErrors = {};
        (async () => {
          await validateCreateUserFormClient
            .validate(user, { abortEarly: false })
            .catch((err) => {
              err.inner.forEach((validationError) => {
                validationErrors[validationError.path] = {};
                validationErrors[validationError.path] =
                  validationError.message;
              });
            });
          hideLoadingBar();
          setFormErrors(validationErrors);
        })();
      }
    }
  };

  const showLoadingBar = () => {
    setLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
  };

  const handleClose = () => {};

  const resetUser = () => {
    let tmpUser = { ...user };
    tmpUser.name = "";
    tmpUser.email = "";
    tmpUser.username = "";
    tmpUser.password = "";
    tmpUser.imapHost = "";
    tmpUser.imapEmail = "";
    tmpUser.imapPassword = "";
    setUser(tmpUser);
  };

  const handleChange = (name, value) => {
    let tmpUser = { ...user };
    tmpUser[name] = value;
    setUser(tmpUser);
  };

  return (
    <div>
      {redirectTo && <Redirect to={redirectTo} />}
      <Card>
        <Dialog
          open={true}
          onClose={handleClose}
          maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              overflowY: "unset",
            },
          }}
        >
          <CardHeader color="theme">
            <h4 className={classes.cardTitleWhite}>
              {`${LOCALE.create_new_user}`}
              <Link to={`/app/admin/users/`} style={{ textDecoration: "none" }}>
                <IconButton
                  style={{ float: "right", color: "white" }}
                  aria-label="close"
                >
                  <Icon>close</Icon>
                </IconButton>
              </Link>
            </h4>
          </CardHeader>
          <CardBody>
            <DialogContent style={{ minHeight: "500px" }}>
              {loading ? <LinearProgress color="secondary" /> : ""}
              <FormControl
                variant="outlined"
                fullWidth
                margin="normal"
                size="small"
              >
                <InputLabel>{LOCALE.role}</InputLabel>
                <Select
                  label={LOCALE.role}
                  onChange={(e) => handleChange("type", e.target.value)}
                  defaultValue="ADMINISTRATOR"
                >
                  {userTypes &&
                    userTypes.map((type, index) => (
                      <MenuItem key={index} value={type}>
                        {LOCALE.getString(type)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <div>
                <Grid container spacing={1}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="name"
                      label={LOCALE.name}
                      name="name"
                      fullWidth={true}
                      value={user.name}
                      onChange={(e) => handleChange("name", e.target.value)}
                      size="small"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      error={formErrors?.name ? true : false}
                      helperText={formErrors?.name}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="email"
                      label={LOCALE.email}
                      name="email"
                      fullWidth={true}
                      value={user.email}
                      onChange={(e) => handleChange("email", e.target.value)}
                      size="small"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      error={formErrors?.email ? true : false}
                      helperText={formErrors?.email}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="username"
                      label={LOCALE.username}
                      name="username"
                      fullWidth={true}
                      value={user.username}
                      onChange={(e) => handleChange("username", e.target.value)}
                      size="small"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      error={formErrors?.username ? true : false}
                      helperText={formErrors?.username}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="password"
                      type="password"
                      label={LOCALE.password}
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      name="password"
                      fullWidth={true}
                      value={user.password}
                      onChange={(e) => handleChange("password", e.target.value)}
                      size="small"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      error={formErrors?.password ? true : false}
                      helperText={formErrors?.password}
                    />
                  </Grid>
                  {user?.type === "CLIENT" && (
                    <Grid item sm={12} xs={12}>
                      <TextField
                        id="domain"
                        label={LOCALE.domain}
                        fullWidth={true}
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="domain"
                        value={user?.domain}
                        onChange={(e) => handleChange("domain", e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        error={formErrors?.domain ? true : false}
                        helperText={formErrors?.domain}
                      />
                    </Grid>
                  )}
                  {/* {user.type === "CLIENT" && (
                    <React.Fragment>
                      {" "}
                      <Grid item sm={12} xs={12}>
                        <Typography
                          style={{ textAlign: "center" }}
                          variant="subtitle1"
                        >
                          Податоци за IMAP серверот на клиентот
                        </Typography>
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <TextField
                          id="imapHost"
                          label={LOCALE.login_imapHost}
                          name="imapHost"
                          fullWidth={true}
                          value={user.imapHost}
                          onChange={(e) =>
                            handleChange("imapHost", e.target.value)
                          }
                          size="small"
                          margin="dense"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <TextField
                          id="imapEmail"
                          label={LOCALE.email}
                          name="imapEmail"
                          fullWidth={true}
                          value={user.imapEmail}
                          onChange={(e) =>
                            handleChange("imapEmail", e.target.value)
                          }
                          size="small"
                          margin="dense"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <TextField
                          id="imapPassword"
                          type="password"
                          label={LOCALE.password}
                          name="imapPassword"
                          fullWidth={true}
                          value={user.imapPassword}
                          onChange={(e) =>
                            handleChange("imapPassword", e.target.value)
                          }
                          size="small"
                          margin="dense"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    </React.Fragment>
                  )} */}
                  <Grid item sm={12} xs={12}>
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => createUser()}
                      fullWidth
                    >
                      {LOCALE.create_new_user}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
          </CardBody>
        </Dialog>
      </Card>
    </div>
  );
}
