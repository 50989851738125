import React, { useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  changePageTitle,
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
} from "../../../common/actions/CommonActions";
import { LOCALE } from "../../../properties/Locale";
import { UsersRepository } from "../../administration/users/UsersRepository";
import { Alert, AlertTitle } from "@material-ui/lab";
import { MainRepositroy } from "../../public/MainRepositroy";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3";
import { UpdateImapDetailsRepository } from "./UpdateImapDetailsRepository";
import { SettingsRepository } from "../../administration/settings/SettingsRepository";

export default function AutomatizationComponent() {
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(false);

  const [dmarcCheckResult, setDmarcCheckResult] = React.useState();
  const [dmarcCheckResultType, setDmarcCheckResultType] = React.useState("");
  const [dmarcRua, setDmarcRua] = React.useState("");
  const [recaptcha, setRecaptcha] = React.useState();
  const [recaptchaToken, setRecaptchaToken] = React.useState();
  const [aecDmarcEmail, setAecDmarcEmail] = React.useState();

  React.useEffect(() => {
    dispatch(changePageTitle(LOCALE.automaticReports));
    loadReCaptcha("6LdkJeoZAAAAAIleGu7k2cJYmWQ45dRFI3YJ7gPz", verifyCallback);
    loadUser();
    SettingsRepository.fetchProbByKey("SMTP_EMAIL_FROM").then((res) => {
      setAecDmarcEmail(res.data.value);
    });
  }, []);

  React.useEffect(() => {
    showLoadingBar();
    if (
      recaptcha != undefined &&
      currentUser?.domain != undefined &&
      recaptchaToken != undefined &&
      dmarcCheckResult === undefined
    ) {
      checkDmarc();
    }
  }, [recaptcha, currentUser?.domain, recaptchaToken]);

  const verifyCallback = (recaptchaToken) => {
    setRecaptchaToken(recaptchaToken);
  };

  const updateToken = () => {
    recaptcha.execute();
  };

  const loadUser = () => {
    showLoadingBar();
    UsersRepository.fetchCurrentUserDetails().then((res) => {
      setCurrentUser(res.data);
      hideLoadingBar();
    });
  };
  const showLoadingBar = () => {
    setLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
  };

  const checkDmarc = () => {
    showLoadingBar();
    updateToken();
    MainRepositroy.checkDmarcRecord(currentUser.domain, recaptchaToken).then(
      (res) => {
        if (res.data[0]) {
          setDmarcCheckResult(res.data[0]);
          setDmarcCheckResultType("success");
          let tmp = res.data[0].split("rua=mailto:")[1].split(";")[0];
          setDmarcRua(tmp);
        } else {
          setDmarcCheckResult(
            "Не е пронајден DMARC запис за дадениот домен: " +
              currentUser?.domain
          );
          setDmarcCheckResultType("error");
        }
        hideLoadingBar();
      },
      (err) => {
        setDmarcCheckResult(
          "Не е пронајден DMARC запис за дадениот домен: " + currentUser?.domain
        );
        setDmarcCheckResultType("error");
        hideLoadingBar();
      }
    );
  };

  return (
    <div>
      <ReCaptcha
        ref={(ref) => setRecaptcha(ref)}
        sitekey="6LdkJeoZAAAAAIleGu7k2cJYmWQ45dRFI3YJ7gPz"
        action="request"
        verifyCallback={verifyCallback}
      />
      <Grid container spacing={2}>
        <Grid item xs={false} sm={3}></Grid>
        <Grid item xs={12} sm={6}>
          <Grid spacing={2} container>
            {loading ? <LinearProgress color="secondary" /> : ""}

            {dmarcCheckResultType && (
              <Grid item sm={12} xs={12}>
                <Alert fullwidth="true" severity={dmarcCheckResultType}>
                  <AlertTitle>Вашиот DMARC рекорд:</AlertTitle>
                  <span style={{ wordWrap: "break-word", maxWidth: "50vw" }}>
                    {dmarcCheckResult}
                  </span>
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Alert fullwidth="true" severity="info">
                <AlertTitle>
                  Напомена за автоматизација на превземањето на DMARC
                  извештаите!
                </AlertTitle>
                Доколку сакате вашите DMARC извештаи да се вчитуваат во системот
                автоматски, потребно е да превземете еден од следниве чекори:
                <br></br>
                <br></br>
                1. Да ги пренасочувате пораките(mail forward or redirect rule)
                од е-поштата на која пристигаат извештаите до следнава е-пошта:
                {aecDmarcEmail ? aecDmarcEmail : ""}
                <br></br>
                <br></br>
                2. Во 'rua' тагот на вашиот DMARC DNS запис вметнете ја следнава
                е-пошта: {aecDmarcEmail ? aecDmarcEmail : ""}
              </Alert>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={false} sm={3}></Grid>
      </Grid>
    </div>
  );
}
