import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  TextField,
  CircularProgress,
  IconButton,
  Icon,
  Tab,
  Tabs,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
// @material-ui/styles
import styles from "../../assets/jss/layouts/appStyle";
import { makeStyles } from "@material-ui/core/styles";
// core components
import { UsersRepository } from "../administration/users/UsersRepository";
import { LOCALE } from "../../properties/Locale";
import EntityMetadata from "../../common/components/EntityMetadata";
import {
  notifyShowSuccessMessage,
  notifyShowErrorMessage,
  triggerRerender,
} from "../../common/actions/CommonActions";
import Card from "../../common/components/Card/Card.js";
import CardHeader from "../../common/components/Card/CardHeader.js";
import CardBody from "../../common/components/Card/CardBody.js";
import {
  validateUserProfile,
  validateChangePasswordUserProfile,
  validateOldPassword,
} from "./UserProfileValidations";
import AdministratorFormComponent from "../administration/users/forms/AdministratorFormComponent";
import ClientFormComponent from "../administration/users/forms/ClientFormComponent";
import { resetWarningCache } from "prop-types";

const useStyles = makeStyles(styles);

export default function UserProfileComponent(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [btnSubmitLoading, setBtnSubmitLoading] = useState(true);
  const [data, setData] = useState();

  const [userProfileType, setuserProfileType] = useState(null);
  const [userProfileTypes, setuserProfileTypes] = useState(null);

  const {
    handleSubmit: editUserPasswordProfileForm,
    register: registerEditPasswordForm,
    setValue,
    getValues,
    errors: errorsRegisterUserProfile,
    reset: resetPassword,
  } = useForm({
    validationSchema: validateChangePasswordUserProfile,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    let audType = ["GENERAL"];
    setuserProfileType(audType);
    setuserProfileTypes(["GENERAL", "PASSWORD"]);
    loadUser();
  }, []);

  const onEditUser = (values) => {
    showLoadingBar();
    let oldPassword = values["oldPassword"];
    delete values["oldPassword"];
    UsersRepository.changeMyPassword(values, oldPassword)
      .then((res) => {
        hideLoadingBar();
        dispatch(triggerRerender());
        dispatch(notifyShowSuccessMessage(LOCALE.user_update_success_message));
        setRedirectTo("/app/dashboard");
      })
      .catch((err) => {
        hideLoadingBar();
        dispatch(notifyShowErrorMessage(err.response?.data));
      });
  };

  const loadUser = () => {
    showLoadingBar();
    resetPassword();
    UsersRepository.fetchCurrentUserDetails().then((res) => {
      setData(res.data);
      hideLoadingBar();
    });
  };

  const showLoadingBar = () => {
    setLoading(true);
    setBtnSubmitLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
    setBtnSubmitLoading(false);
  };

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
    if (newTabIndex === 1) {
      Object.keys(data).map(function (item) {
        if (Object.keys(getValues()).indexOf(item) == -1) {
          registerEditPasswordForm({ name: item, type: "custom" });
        }
      });
      Object.entries(data).map(function (item) {
        setValue(item[0], item[1]);
      });
    }
  };

  return (
    <div>
      {redirectTo && <Redirect to={redirectTo} />}
      <Card>
        <CardHeader color="theme">
          <h4 className={classes.cardTitleWhite}>
            {`${LOCALE.user_profile}${
              data?.username ? ": " + data?.username : ""
            }`}
          </h4>
        </CardHeader>
        <CardBody>
          {loading ? <LinearProgress color="secondary" /> : ""}
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label={LOCALE.user_profile_general} tabIndex="0" />
            <Tab label={LOCALE.change_password} tabIndex="1" />
          </Tabs>
          <div>
            {tabIndex == 0 && (
              <div>
                {data?.type === "ADMINISTRATOR" && (
                  <div>
                    <AdministratorFormComponent
                      data={data}
                      disabled={false}
                      action={"myProfile"}
                      showLoadingBar={showLoadingBar}
                      hideLoadingBar={hideLoadingBar}
                      form={"myProfile"}
                    />
                  </div>
                )}
                {data?.type === "CLIENT" && (
                  <div>
                    <ClientFormComponent
                      data={data}
                      disabled={false}
                      action={"myProfile"}
                      showLoadingBar={showLoadingBar}
                      hideLoadingBar={hideLoadingBar}
                      form={"myProfile"}
                    />
                  </div>
                )}
              </div>
            )}
            {tabIndex == 1 && (
              <form onSubmit={editUserPasswordProfileForm(onEditUser)}>
                <div>
                  <TextField
                    id="oldPassword"
                    type="password"
                    label={LOCALE.old_password}
                    error={errorsRegisterUserProfile?.oldPassword}
                    helperText={errorsRegisterUserProfile?.oldPassword?.message}
                    name="oldPassword"
                    fullWidth={true}
                    size="small"
                    margin="normal"
                    variant="outlined"
                    inputRef={registerEditPasswordForm}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disabled: btnSubmitLoading,
                    }}
                  />
                  <TextField
                    id="password"
                    type="password"
                    label={LOCALE.password}
                    inputProps={{
                      autocomplete: "new-password",
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    error={errorsRegisterUserProfile?.password}
                    helperText={errorsRegisterUserProfile?.password?.message}
                    name="password"
                    fullWidth={true}
                    size="small"
                    margin="normal"
                    variant="outlined"
                    inputRef={registerEditPasswordForm}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disabled: btnSubmitLoading,
                    }}
                  />
                  <TextField
                    variant="outlined"
                    type="password"
                    margin="normal"
                    fullWidth={true}
                    size="small"
                    name="confirmPassword"
                    label={LOCALE.confirm_password}
                    inputRef={registerEditPasswordForm}
                    error={errorsRegisterUserProfile?.confirmPassword}
                    helperText={
                      errorsRegisterUserProfile?.confirmPassword?.message
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disabled: btnSubmitLoading,
                    }}
                  />
                  <DialogActions>
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      disabled={btnSubmitLoading}
                      className={btnSubmitLoading ? "btn-loading" : ""}
                      color="primary"
                    >
                      {LOCALE.save}
                      {btnSubmitLoading && (
                        <CircularProgress
                          color="secondary"
                          size={20}
                          style={{ position: "absolute" }}
                        />
                      )}
                    </Button>
                  </DialogActions>
                </div>
              </form>
            )}
            {/* {tabIndex == "1" && (
              <form onSubmit={editUserPasswordProfileForm(onEditUser)}>
                <div>
                  <TextField
                    id="password"
                    type="password"
                    label={LOCALE.password}
                    error={errorsRegisterUserProfile?.password}
                    helperText={errorsRegisterUserProfile?.password?.message}
                    name="password"
                    fullWidth={true}
                    size="small"
                    margin="normal"
                    variant="outlined"
                    inputRef={registerEditPasswordForm}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disabled: btnSubmitLoading,
                    }}
                  />
                  <TextField
                    variant="outlined"
                    type="password"
                    margin="normal"
                    fullWidth={true}
                    size="small"
                    name="confirmPassword"
                    label={LOCALE.confirm_password}
                    inputRef={registerEditPasswordForm}
                    error={errorsRegisterUserProfile?.confirmPassword}
                    helperText={
                      errorsRegisterUserProfile?.confirmPassword?.message
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      disabled: btnSubmitLoading,
                    }}
                  />
                  <DialogActions>
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      disabled={btnSubmitLoading}
                      className={btnSubmitLoading ? "btn-loading" : ""}
                      color="primary"
                    >
                      {LOCALE.save}
                      {btnSubmitLoading && (
                        <CircularProgress
                          color="secondary"
                          size={20}
                          style={{ position: "absolute" }}
                        />
                      )}
                    </Button>
                  </DialogActions>
                </div>
              </form>
            )} */}
            {/* {tabIndex == 2 && (
              <div>
                {data?.type === "CLIENT" && (
                  <div>
                    <ClientImapFormComponent
                      data={data}
                      disabled={false}
                      action={"myProfile"}
                      showLoadingBar={showLoadingBar}
                      hideLoadingBar={hideLoadingBar}
                      form={"myProfile"}
                    />
                  </div>
                )}
              </div>
            )} */}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
