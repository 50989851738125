import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import {
  TablePagination,
  TableRow,
  TableFooter,
  Icon,
  IconButton,
  Tooltip,
  TableCell,
  TableBody,
  TableHead,
  Table,
  Button,
  Grid,
  Typography,
  TextField,
  Paper,
  Box,
  Collapse,
  Badge,
} from "@material-ui/core";
import { grayColor } from "./../../../assets/jss/mainStyle";
// @material-ui/styles
import Chart from "react-apexcharts";
import {
  DateTimePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/components/tableStyle";
import { LOCALE } from "../../../properties/Locale";
import { changePageTitle } from "../../../common/actions/CommonActions";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";
import { ClientChartRepo } from "./ClientChartRepo";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { format } from "date-fns";
import ReactApexChart from "react-apexcharts";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useStyles = makeStyles(styles);

export default function ClientChartComponent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [feedback, setFeedback] = useState();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [spfData, setSpfData] = React.useState({});
  const [dkimData, setDkimData] = React.useState({});
  const [spfAuthData, setSpfAuthData] = React.useState({});
  const [dkimAuthData, setDkimAuthData] = React.useState({});
  const [dispositionData, setDispositionData] = React.useState({});
  const [spfDataDate, setSpfDataDate] = React.useState({});
  const [dkimDataDate, setDkimDataDate] = React.useState({});
  const [failDataSourceIpSpf, setFailDataSourceIpSpf] = React.useState({});
  const [failDataSourceIpDkim, setFailDataSourceIpDkim] = React.useState({});
  const [messageDispositionDate, setMessageDispositionDate] = React.useState(
    {}
  );
  const [open, setOpen] = React.useState({ index: 0, open: false });
  const [totalEmailCount, setTotalEmailCount] = React.useState(0);
  const [orgNameData, setOrgNameData] = React.useState({});
  const [rerender, triggerRerender] = React.useState(0);
  const [dateFrom, setDateFrom] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [dateTo, setDateTo] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  );

  const WhiteTextTypography = withStyles({
    root: {
      color: "#FFFFFF",
    },
  })(Typography);

  useEffect(() => {
    dispatch(changePageTitle(LOCALE.reports));
  }, []);

  useEffect(() => {
    getFeedbacks();
    prepareSpfData();
    prepareDkimData();
    prepareSpfAuthData();
    prepareDkimAuthData();
    prepareDispositionData();
    prepareSpfDataDate();
    prepareDkimDataDate();
    prepareFailDataSourceIpSpf();
    prepareFailDataSourceIpDkim();
    prepareOrgName();
    prepareMessageDispositionDate();
    prepareTotalEmailCount();
  }, [dateFrom, dateTo]);

  useEffect(() => {
    getFeedbacks();
  }, [page, size]);

  useEffect(() => {
    triggerRerender(rerender + 1);
  }, [dkimData.data, spfData.data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleExpandTable = (index, isOpen) => {
    let tmp;
    if (index !== open.index) {
      tmp = { index: index, open: true };
    } else {
      tmp = { index: index, open: isOpen };
    }
    setOpen(tmp);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
  };

  const prepareTotalEmailCount = () => {
    if (checkIfValidDate(dateFrom) & checkIfValidDate(dateTo)) {
      (async () => {
        await ClientChartRepo.getTotalEmailCount(
          format(dateFrom, ["yyyy-MM-dd HH:mm:ss"]),
          format(dateTo, ["yyyy-MM-dd HH:mm:ss"])
        ).then(
          (res) => {
            setTotalEmailCount(res.data);
          },
          (err) => {}
        );
      })();
    }
  };

  const checkIfValidDate = (date) => {
    if (Object.prototype.toString.call(date) === "[object Date]") {
      // it is a date
      if (isNaN(date.getTime())) {
        // d.valueOf() could also work
        // date is not valid
        return false;
      } else {
        // date is valid
        return true;
      }
    } else {
      // not a date
      return false;
    }
  };

  const getFeedbacks = () => {
    if (checkIfValidDate(dateFrom) & checkIfValidDate(dateTo)) {
      ClientChartRepo.getMyFeedbacks(
        page,
        size,
        format(dateFrom, ["yyyy-MM-dd HH:mm:ss"]),
        format(dateTo, ["yyyy-MM-dd HH:mm:ss"])
      ).then(
        (res) => {
          setFeedback(res.data);
        },
        (err) => {}
      );
    }
  };

  const prepareSpfData = () => {
    if (checkIfValidDate(dateFrom) & checkIfValidDate(dateTo)) {
      (async () => {
        let tmpSpfData = { ...spfData };
        tmpSpfData.labels = [];
        tmpSpfData.data = [];
        await ClientChartRepo.getSpfReport(
          format(dateFrom, ["yyyy-MM-dd HH:mm:ss"]),
          format(dateTo, ["yyyy-MM-dd HH:mm:ss"])
        ).then(
          (res) => {
            res.data.forEach((e) => {
              tmpSpfData.labels.push(e[1]);
              tmpSpfData.data.push(e[0]);
            });
          },
          (err) => {}
        );
        setSpfData(tmpSpfData);
      })();
    }
  };

  const prepareDkimData = () => {
    if (checkIfValidDate(dateFrom) & checkIfValidDate(dateTo)) {
      (async () => {
        const tmpDkimData = { ...dkimData };
        tmpDkimData.data = [];
        tmpDkimData.labels = [];
        await ClientChartRepo.getDkimReport(
          format(dateFrom, ["yyyy-MM-dd HH:mm:ss"]),
          format(dateTo, ["yyyy-MM-dd HH:mm:ss"])
        ).then(
          (res) => {
            res.data.forEach((e) => {
              tmpDkimData.labels.push(e[1]);
              tmpDkimData.data.push(e[0]);
            });
          },
          (err) => {}
        );
        setDkimData(tmpDkimData);
      })();
    }
  };
  const prepareSpfAuthData = () => {
    if (checkIfValidDate(dateFrom) & checkIfValidDate(dateTo)) {
      (async () => {
        let tmpSpfAuthData = { ...spfData };
        tmpSpfAuthData.labels = [];
        tmpSpfAuthData.data = [];
        await ClientChartRepo.getSpfAuthResultReport(
          format(dateFrom, ["yyyy-MM-dd HH:mm:ss"]),
          format(dateTo, ["yyyy-MM-dd HH:mm:ss"])
        ).then(
          (res) => {
            res.data.forEach((e) => {
              tmpSpfAuthData.labels.push(e[1]);
              tmpSpfAuthData.data.push(e[0]);
            });
          },
          (err) => {}
        );
        setSpfAuthData(tmpSpfAuthData);
      })();
    }
  };
  const prepareDkimAuthData = () => {
    if (checkIfValidDate(dateFrom) & checkIfValidDate(dateTo)) {
      (async () => {
        const tmpDkimAuthData = { ...dkimData };
        tmpDkimAuthData.data = [];
        tmpDkimAuthData.labels = [];
        await ClientChartRepo.getDkimAuthResultReport(
          format(dateFrom, ["yyyy-MM-dd HH:mm:ss"]),
          format(dateTo, ["yyyy-MM-dd HH:mm:ss"])
        ).then(
          (res) => {
            res.data.forEach((e) => {
              tmpDkimAuthData.labels.push(e[1]);
              tmpDkimAuthData.data.push(e[0]);
            });
          },
          (err) => {}
        );
        setDkimAuthData(tmpDkimAuthData);
      })();
    }
  };
  const prepareDispositionData = () => {
    if (checkIfValidDate(dateFrom) & checkIfValidDate(dateTo)) {
      (async () => {
        const tmpDispositionData = { ...dispositionData };
        tmpDispositionData.data = [];
        tmpDispositionData.labels = [];
        await ClientChartRepo.getCountDispositionReportDate(
          format(dateFrom, ["yyyy-MM-dd HH:mm:ss"]),
          format(dateTo, ["yyyy-MM-dd HH:mm:ss"])
        ).then(
          (res) => {
            res.data.forEach((e) => {
              tmpDispositionData.labels.push(e[1]);
              tmpDispositionData.data.push(e[0]);
            });
          },
          (err) => {}
        );
        setDispositionData(tmpDispositionData);
      })();
    }
  };

  const prepareSpfDataDate = () => {
    if (checkIfValidDate(dateFrom) & checkIfValidDate(dateTo)) {
      (async () => {
        let tmpSpfDataDate = { ...spfDataDate };
        tmpSpfDataDate.passData = [];
        tmpSpfDataDate.noneData = [];
        tmpSpfDataDate.neutralData = [];
        tmpSpfDataDate.failData = [];
        tmpSpfDataDate.softfailData = [];
        tmpSpfDataDate.temperrorData = [];
        tmpSpfDataDate.permerrorData = [];
        tmpSpfDataDate.date = [];
        await ClientChartRepo.getSpfReportDate(
          format(dateFrom, ["yyyy-MM-dd HH:mm:ss"]),
          format(dateTo, ["yyyy-MM-dd HH:mm:ss"])
        ).then(
          (res) => {
            res.data.forEach((e) => {
              tmpSpfDataDate.passData.push(e[1] === "pass" ? e[0] : 0);
              tmpSpfDataDate.noneData.push(e[1] === "none" ? e[0] : 0);
              tmpSpfDataDate.neutralData.push(e[1] === "neutral" ? e[0] : 0);
              tmpSpfDataDate.failData.push(e[1] === "fail" ? e[0] : 0);
              tmpSpfDataDate.softfailData.push(e[1] === "softfail" ? e[0] : 0);
              tmpSpfDataDate.temperrorData.push(
                e[1] === "temperror" ? e[0] : 0
              );
              tmpSpfDataDate.permerrorData.push(
                e[1] === "permerror" ? e[0] : 0
              );
              tmpSpfDataDate.date.push(e[2]);
            });
          },
          (err) => {}
        );
        setSpfDataDate(tmpSpfDataDate);
        triggerRerender(!rerender);
      })();
    }
  };

  const prepareDkimDataDate = () => {
    if (checkIfValidDate(dateFrom) & checkIfValidDate(dateTo)) {
      (async () => {
        let tmpDkimDataDate = { ...dkimDataDate };
        tmpDkimDataDate.passData = [];
        tmpDkimDataDate.noneData = [];
        tmpDkimDataDate.neutralData = [];
        tmpDkimDataDate.failData = [];
        tmpDkimDataDate.softfailData = [];
        tmpDkimDataDate.temperrorData = [];
        tmpDkimDataDate.permerrorData = [];
        tmpDkimDataDate.date = [];
        await ClientChartRepo.getDkimReportDate(
          format(dateFrom, ["yyyy-MM-dd HH:mm:ss"]),
          format(dateTo, ["yyyy-MM-dd HH:mm:ss"])
        ).then(
          (res) => {
            res.data.forEach((e) => {
              tmpDkimDataDate.passData.push(e[1] === "pass" ? e[0] : 0);
              tmpDkimDataDate.noneData.push(e[1] === "none" ? e[0] : 0);
              tmpDkimDataDate.neutralData.push(e[1] === "neutral" ? e[0] : 0);
              tmpDkimDataDate.failData.push(e[1] === "fail" ? e[0] : 0);
              tmpDkimDataDate.softfailData.push(e[1] === "softfail" ? e[0] : 0);
              tmpDkimDataDate.temperrorData.push(
                e[1] === "temperror" ? e[0] : 0
              );
              tmpDkimDataDate.permerrorData.push(
                e[1] === "permerror" ? e[0] : 0
              );
              tmpDkimDataDate.date.push(e[2]);
            });
          },
          (err) => {}
        );
        setDkimDataDate(tmpDkimDataDate);
        triggerRerender(!rerender);
      })();
    }
  };

  const prepareMessageDispositionDate = () => {
    if (checkIfValidDate(dateFrom) & checkIfValidDate(dateTo)) {
      (async () => {
        let tmpMessageDispositionDate = { ...messageDispositionDate };
        tmpMessageDispositionDate.noneData = [];
        tmpMessageDispositionDate.quarantineData = [];
        tmpMessageDispositionDate.rejectData = [];
        tmpMessageDispositionDate.date = [];
        await ClientChartRepo.getDispositionReportDate(
          format(dateFrom, ["yyyy-MM-dd HH:mm:ss"]),
          format(dateTo, ["yyyy-MM-dd HH:mm:ss"])
        ).then(
          (res) => {
            res.data.forEach((e) => {
              if (e[1] === "none") {
                tmpMessageDispositionDate.noneData.push(e[0]);
                tmpMessageDispositionDate.rejectData.push(0);
                tmpMessageDispositionDate.quarantineData.push(0);
              } else if (e[1] === "reject") {
                tmpMessageDispositionDate.rejectData.push(e[0]);
                tmpMessageDispositionDate.noneData.push(0);
                tmpMessageDispositionDate.quarantineData.push(0);
              } else if (e[1] === "quarantine") {
                tmpMessageDispositionDate.quarantineData.push(e[0]);
                tmpMessageDispositionDate.rejectData.push(0);
                tmpMessageDispositionDate.noneData.push(0);
              }
              tmpMessageDispositionDate.date.push(e[2]);
            });
          },
          (err) => {}
        );
        setMessageDispositionDate(tmpMessageDispositionDate);
        triggerRerender(!rerender);
      })();
    }
  };

  const prepareFailDataSourceIpSpf = () => {
    if (checkIfValidDate(dateFrom) & checkIfValidDate(dateTo)) {
      (async () => {
        let tmpFailDataSourceIp = { ...failDataSourceIpSpf };
        tmpFailDataSourceIp.spfData = [];
        tmpFailDataSourceIp.organizations = [];
        tmpFailDataSourceIp.categories = [];
        await ClientChartRepo.getFailReportSourceIpSpf(
          format(dateFrom, ["yyyy-MM-dd HH:mm:ss"]),
          format(dateTo, ["yyyy-MM-dd HH:mm:ss"])
        ).then(
          (res) => {
            res.data[0].forEach((e) => {
              tmpFailDataSourceIp.spfData.push(e);
            });
            res.data[1].forEach((e) => {
              tmpFailDataSourceIp.categories.push(e);
            });
            res.data[2].forEach((e) => {
              tmpFailDataSourceIp.organizations.push(e);
            });
          },
          (err) => {}
        );
        setFailDataSourceIpSpf(tmpFailDataSourceIp);
        triggerRerender(!rerender);
      })();
    }
  };

  const prepareFailDataSourceIpDkim = () => {
    if (checkIfValidDate(dateFrom) & checkIfValidDate(dateTo)) {
      (async () => {
        let tmpFailDataSourceIp = { ...failDataSourceIpDkim };
        tmpFailDataSourceIp.dkimData = [];
        tmpFailDataSourceIp.organizations = [];
        tmpFailDataSourceIp.categories = [];
        await ClientChartRepo.getFailReportSourceIpDkim(
          format(dateFrom, ["yyyy-MM-dd HH:mm:ss"]),
          format(dateTo, ["yyyy-MM-dd HH:mm:ss"])
        ).then(
          (res) => {
            res.data[0].forEach((e) => {
              tmpFailDataSourceIp.dkimData.push(e);
            });
            res.data[1].forEach((e) => {
              tmpFailDataSourceIp.categories.push(e);
            });
            res.data[2].forEach((e) => {
              tmpFailDataSourceIp.organizations.push(e);
            });
          },
          (err) => {}
        );
        setFailDataSourceIpDkim(tmpFailDataSourceIp);
        triggerRerender(!rerender);
      })();
    }
  };

  const makeSpfData = () => {
    let tmp = [];
    var i;
    for (i = 0; i < failDataSourceIpSpf.spfData.length; i++) {
      tmp.push({
        name: failDataSourceIpSpf.organizations[i],
        data: failDataSourceIpSpf.spfData[i],
      });
    }
    return tmp;
  };

  const makeDkimData = () => {
    let tmp = [];
    var i;
    for (i = 0; i < failDataSourceIpDkim.dkimData.length; i++) {
      tmp.push({
        name: failDataSourceIpDkim.organizations[i],
        data: failDataSourceIpDkim.dkimData[i],
      });
    }
    return tmp;
  };

  const prepareOrgName = () => {
    if (checkIfValidDate(dateFrom) & checkIfValidDate(dateTo)) {
      (async () => {
        let tmpOrgNameData = { ...orgNameData };
        tmpOrgNameData.data = [];
        tmpOrgNameData.labels = [];
        await ClientChartRepo.getReportByOrgName(
          format(dateFrom, ["yyyy-MM-dd HH:mm:ss"]),
          format(dateTo, ["yyyy-MM-dd HH:mm:ss"])
        ).then(
          (res) => {
            res.data.forEach((e) => {
              tmpOrgNameData.labels.push(e[1]);
              tmpOrgNameData.data.push(e[0]);
            });
          },
          (err) => {}
        );
        setOrgNameData(tmpOrgNameData);
        triggerRerender(!rerender);
      })();
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              variant="inline"
              ampm={false}
              label="Датум од:"
              value={dateFrom}
              onChange={setDateFrom}
              // disablePast
              format="dd/MM/yyyy HH:mm"
            />
            <KeyboardDateTimePicker
              variant="inline"
              ampm={false}
              label="Датум до:"
              value={dateTo}
              onChange={setDateTo}
              // disablePast
              format="dd/MM/yyyy HH:mm"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item style={{ marginLeft: "50px" }}>
          <Card style={{ backgroundColor: "#474d4a" }}>
            <Box p={1}>
              <WhiteTextTypography>
                Total email count: {totalEmailCount}
              </WhiteTextTypography>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <br></br>
      <br></br>
      <Grid container spacing={3}>
        <Grid item sm={12} xs={12}>
          <Card>
            <CardHeader color="theme">All reports</CardHeader>
            <CardBody>
              {feedback && (
                <Table aria-label="users table" className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.tableHeadRow}>
                      <TableCell>Org Name</TableCell>
                      <TableCell>Date From</TableCell>
                      <TableCell>Date To</TableCell>
                      <TableCell>Policy domain</TableCell>
                      <TableCell>Dkim alignment</TableCell>
                      <TableCell>SPF alignment</TableCell>
                      <TableCell>Policy</TableCell>
                      <TableCell>Subdomain Policy</TableCell>
                      <TableCell>Expand</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {feedback?.content?.map((row, index) => (
                      <React.Fragment key={row.id}>
                        <TableRow
                          key={row.id}
                          className={classes.tableBodyRow}
                          style={index % 2 ? { background: "#f0f0f0" } : {}}
                        >
                          <TableCell className={classes.tableCell}>
                            {row.report_metadata.org_name}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {format(
                              new Date(
                                row.report_metadata.date_range.begin * 1000
                              ),
                              ["dd-MM-yyyy HH:mm:ss"]
                            )}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {format(
                              new Date(
                                row.report_metadata.date_range.end * 1000
                              ),
                              ["dd-MM-yyyy HH:mm:ss"]
                            )}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.policy_published.domain}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            style={{ textAlign: "center" }}
                          >
                            <Badge
                              color={
                                row.policy_published.adkim === "r"
                                  ? "primary"
                                  : "secondary"
                              }
                              badgeContent={
                                row.policy_published.adkim === "r"
                                  ? "Relaxed"
                                  : "Strict"
                              }
                            ></Badge>
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            style={{ textAlign: "center" }}
                          >
                            <Badge
                              color={
                                row.policy_published.aspf === "r"
                                  ? "primary"
                                  : "secondary"
                              }
                              badgeContent={
                                row.policy_published.aspf === "r"
                                  ? "Relaxed"
                                  : "Strict"
                              }
                            ></Badge>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.policy_published.p}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.policy_published.sp}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() =>
                                handleExpandTable(index, !open.open)
                              }
                            >
                              {index === open.index && open.open ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              paddingBottom: 0,
                              paddingTop: 0,
                              backgroundColor: "#f5f2b34f",
                            }}
                            colSpan={9}
                          >
                            <Collapse
                              in={index === open.index && open.open}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box margin={1}>
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  component="div"
                                >
                                  Records
                                </Typography>
                                <Table className={classes.table}>
                                  <TableHead>
                                    <TableRow className={classes.tableHeadRow}>
                                      <TableCell>Source Ip</TableCell>
                                      <TableCell>Source DNS</TableCell>
                                      <TableCell>Emails count</TableCell>
                                      <TableCell>Header from</TableCell>
                                      <TableCell
                                        style={{
                                          borderLeft: "1px solid black",
                                        }}
                                      >
                                        SPF alignment
                                      </TableCell>
                                      <TableCell>DKIM alignment</TableCell>
                                      <TableCell
                                        style={{
                                          borderRight: "1px solid black",
                                        }}
                                      >
                                        Policy applied
                                      </TableCell>
                                      <TableCell>SPF domain</TableCell>
                                      <TableCell>SPF Auth result</TableCell>
                                      <TableCell>DKIM domain</TableCell>
                                      <TableCell>DKIM Auth result</TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    {row.record?.map((record, index) => (
                                      <TableRow
                                        key={record.id}
                                        style={
                                          index % 2
                                            ? { background: "#f0f0f0" }
                                            : {}
                                        }
                                      >
                                        <TableCell>
                                          {record?.row.source_ip}
                                        </TableCell>
                                        <TableCell
                                          style={{ maxWidth: "120px" }}
                                        >
                                          {record?.row.dns}
                                        </TableCell>
                                        <TableCell>
                                          {record?.row.count}
                                        </TableCell>
                                        <TableCell>
                                          {record?.identifiers?.header_from}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            textAlign: "center",
                                            borderLeft: "1px solid black",
                                          }}
                                        >
                                          <Badge
                                            color={
                                              record?.row?.policy_evaluated
                                                ?.spf === "pass"
                                                ? "primary"
                                                : "secondary"
                                            }
                                            badgeContent={
                                              record?.row?.policy_evaluated?.spf
                                            }
                                          ></Badge>
                                        </TableCell>
                                        <TableCell
                                          style={{ textAlign: "center" }}
                                        >
                                          <Badge
                                            color={
                                              record?.row?.policy_evaluated
                                                ?.dkim === "pass"
                                                ? "primary"
                                                : "secondary"
                                            }
                                            badgeContent={
                                              record?.row?.policy_evaluated
                                                ?.dkim
                                            }
                                          ></Badge>
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            textAlign: "center",
                                            borderRight: "1px solid black",
                                          }}
                                        >
                                          <Badge
                                            color={
                                              record?.row?.policy_evaluated
                                                ?.disposition === "none"
                                                ? "primary"
                                                : "secondary"
                                            }
                                            badgeContent={
                                              record?.row?.policy_evaluated
                                                ?.disposition
                                            }
                                          ></Badge>
                                        </TableCell>
                                        <TableCell>
                                          {record?.auth_results?.spf?.domain}
                                        </TableCell>
                                        <TableCell
                                          style={{ textAlign: "center" }}
                                        >
                                          <Badge
                                            color={
                                              record?.auth_results?.spf
                                                ?.result === "pass"
                                                ? "primary"
                                                : "secondary"
                                            }
                                            badgeContent={
                                              record?.auth_results?.spf?.result
                                            }
                                          ></Badge>
                                        </TableCell>
                                        <TableCell>
                                          {record?.auth_results?.dkim?.domain}
                                        </TableCell>
                                        <TableCell
                                          style={{ textAlign: "center" }}
                                        >
                                          <Badge
                                            color={
                                              record?.auth_results?.dkim
                                                ?.result === "pass"
                                                ? "primary"
                                                : "secondary"
                                            }
                                            badgeContent={
                                              record?.auth_results?.dkim?.result
                                            }
                                          ></Badge>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={9}
                        count={feedback.totalElements}
                        rowsPerPage={size}
                        page={feedback.number}
                        labelRowsPerPage={LOCALE.rows_per_page}
                        SelectProps={{
                          inputProps: { "aria-label": LOCALE.rows_per_page },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              )}
            </CardBody>
          </Card>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Card>
            <CardHeader color="theme">SPF Alignment</CardHeader>
            <CardBody>
              {spfData.labels && spfData.labels.length > 0 && (
                <ReactApexChart
                  options={{
                    labels: spfData.labels,
                    colors:
                      spfData.labels[0] === "fail"
                        ? ["#FF6562", "#00C07F", "#eab110"]
                        : ["#00C07F", "#FF6562", "#eab110"],
                  }}
                  series={spfData.data}
                  type="pie"
                  height={250}
                />
              )}
            </CardBody>
          </Card>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Card>
            <CardHeader color="theme">DKIM Alignment</CardHeader>
            <CardBody>
              <React.Fragment>
                {dkimData.labels && dkimData.labels.length > 0 && (
                  <ReactApexChart
                    options={{
                      labels: dkimData.labels,
                      colors:
                        dkimData.labels[0] === "fail"
                          ? ["#FF6562", "#00C07F", "#eab110"]
                          : ["#00C07F", "#FF6562", "#eab110"],
                    }}
                    series={dkimData.data}
                    type="pie"
                    height={250}
                  />
                )}
              </React.Fragment>
            </CardBody>
          </Card>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Card>
            <CardHeader color="theme">
              Number of reports by organizations
            </CardHeader>
            <CardBody>
              {orgNameData?.data?.length > 0 && (
                <React.Fragment>
                  <ReactApexChart
                    options={{
                      labels: orgNameData.labels,
                    }}
                    series={orgNameData.data}
                    height={250}
                    type="pie"
                  />
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Card>
            <CardHeader color="theme">
              Message disposition(Policy applied)
            </CardHeader>
            <CardBody>
              <React.Fragment>
                {dispositionData.labels &&
                  dispositionData.labels.length > 0 && (
                    <ReactApexChart
                      options={{
                        labels: dispositionData.labels,
                      }}
                      series={dispositionData.data}
                      type="pie"
                      height={250}
                    />
                  )}
              </React.Fragment>
            </CardBody>
          </Card>
        </Grid>
        <Grid item sm={8} xs={12}>
          <Card>
            <CardHeader color="theme">
              Message disposition(Policy applied) over time
            </CardHeader>
            <CardBody>
              {messageDispositionDate?.date?.length > 0 && (
                <React.Fragment>
                  <Chart
                    options={{
                      stroke: {
                        curve: "smooth",
                      },
                      labels: messageDispositionDate.date,
                      colors: ["#00C07F", "#eab110", "#FF6562"],
                    }}
                    series={[
                      {
                        name: "none",
                        type: "line",
                        data: messageDispositionDate.noneData,
                      },
                      {
                        name: "quarantine",
                        type: "line",
                        data: messageDispositionDate.quarantineData,
                      },
                      {
                        name: "reject",
                        type: "line",
                        data: messageDispositionDate.rejectData,
                      },
                    ]}
                    xaxis={{
                      type: "category",
                    }}
                    height={250}
                    type="line"
                  />
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <Card>
            <CardHeader color="theme">SPF Authentication results</CardHeader>
            <CardBody>
              <React.Fragment>
                {spfAuthData.labels && spfAuthData.labels.length > 0 && (
                  <ReactApexChart
                    options={{
                      labels: spfAuthData.labels,
                    }}
                    series={spfAuthData.data}
                    type="pie"
                    height={250}
                  />
                )}
              </React.Fragment>
            </CardBody>
          </Card>
        </Grid>
        <Grid item sm={8} xs={12}>
          <Card>
            <CardHeader color="theme">
              SPF Authentication results by date
            </CardHeader>
            <CardBody>
              {spfDataDate?.date?.length > 0 && (
                <React.Fragment>
                  <Chart
                    options={{
                      stroke: {
                        curve: "smooth",
                      },
                      labels: spfDataDate.date,
                      colors: ["#00C07F", "#FF6562", "#eab110"],
                    }}
                    series={[
                      {
                        name: "pass",
                        type: "line",
                        data: spfDataDate.passData,
                      },
                      {
                        name: "none",
                        type: "line",
                        data: spfDataDate.noneData,
                      },
                      {
                        name: "neutral",
                        type: "line",
                        data: spfDataDate.neutralData,
                      },
                      {
                        name: "fail",
                        type: "line",
                        data: spfDataDate.failData,
                      },
                      {
                        name: "softfail",
                        type: "line",
                        data: spfDataDate.softfailData,
                      },
                      {
                        name: "temperror",
                        type: "line",
                        data: spfDataDate.temperrorData,
                      },
                      {
                        name: "permerror ",
                        type: "line",
                        data: spfDataDate.permerrorData,
                      },
                    ]}
                    height={250}
                    type="line"
                  />
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Card>
            <CardHeader color="theme">DKIM Authentication results</CardHeader>
            <CardBody>
              <React.Fragment>
                {dkimAuthData.labels && dkimAuthData.labels.length > 0 && (
                  <ReactApexChart
                    options={{
                      labels: dkimAuthData.labels,
                    }}
                    series={dkimAuthData.data}
                    type="pie"
                    height={250}
                  />
                )}
              </React.Fragment>
            </CardBody>
          </Card>
        </Grid>
        <Grid item sm={8} xs={12}>
          <Card>
            <CardHeader color="theme">
              DKIM Authentication results by date
            </CardHeader>
            <CardBody>
              {dkimDataDate?.date?.length > 0 && (
                <React.Fragment>
                  <Chart
                    options={{
                      stroke: {
                        curve: "smooth",
                      },
                      labels: dkimDataDate.date,
                      colors: ["#00C07F", "#FF6562", "#eab110"],
                    }}
                    series={[
                      {
                        name: "pass",
                        type: "line",
                        data: dkimDataDate.passData,
                      },
                      {
                        name: "none",
                        type: "line",
                        data: dkimDataDate.noneData,
                      },
                      {
                        name: "neutral",
                        type: "line",
                        data: dkimDataDate.neutralData,
                      },
                      {
                        name: "fail",
                        type: "line",
                        data: dkimDataDate.failData,
                      },
                      {
                        name: "softfail",
                        type: "line",
                        data: dkimDataDate.softfailData,
                      },
                      {
                        name: "temperror",
                        type: "line",
                        data: dkimDataDate.temperrorData,
                      },
                      {
                        name: "permerror ",
                        type: "line",
                        data: dkimDataDate.permerrorData,
                      },
                    ]}
                    xaxis={{
                      type: "category",
                    }}
                    height={250}
                    type="line"
                  />
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={12} xs={12}>
          <Card>
            <CardHeader color="theme">Fails by Source Ip (SPF)</CardHeader>
            <CardBody>
              {failDataSourceIpSpf?.categories?.length > 0 && (
                <React.Fragment>
                  <Chart
                    type="bar"
                    height={430}
                    series={makeSpfData()}
                    // height={350}
                    options={{
                      chart: {
                        type: "bar",
                        height: 430,
                        stacked: true,
                      },
                      plotOptions: {
                        bar: {
                          horizontal: true,
                          dataLabels: {
                            position: "top",
                          },
                        },
                      },
                      dataLabels: {
                        enabled: true,
                        offsetX: -6,
                        style: {
                          fontSize: "12px",
                          colors: ["#fff"],
                        },
                      },
                      stroke: {
                        show: true,
                        width: 1,
                        colors: ["#fff"],
                      },
                      xaxis: {
                        categories: failDataSourceIpSpf.categories,
                      },
                    }}
                  />
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={12} xs={12}>
          <Card>
            <CardHeader color="theme">Fails by Source Ip (DKIM)</CardHeader>
            <CardBody>
              {failDataSourceIpDkim?.categories?.length > 0 && (
                <React.Fragment>
                  <Chart
                    type="bar"
                    height={430}
                    series={makeDkimData()}
                    // height={350}
                    options={{
                      chart: {
                        type: "bar",
                        height: 430,
                        stacked: true,
                      },
                      plotOptions: {
                        bar: {
                          horizontal: true,
                          dataLabels: {
                            position: "top",
                          },
                        },
                      },
                      dataLabels: {
                        enabled: true,
                        offsetX: -6,
                        style: {
                          fontSize: "12px",
                          colors: ["#fff"],
                        },
                      },
                      stroke: {
                        show: true,
                        width: 1,
                        colors: ["#fff"],
                      },
                      xaxis: {
                        categories: failDataSourceIpDkim.categories,
                      },
                    }}
                  />
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
