import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
// @material-ui/core components
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import "./NavItem.css";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  TextField,
  CircularProgress,
  IconButton,
  Icon,
  Tab,
  Tabs,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Typography,
  Divider,
  Box,
  Hidden,
} from "@material-ui/core";
// @material-ui/styles
import styles from "../../assets/jss/layouts/appStyle";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import bgImg from "../../assets/img/bg.jpg";
import bgImg2 from "../../assets/img/bg1.jpg";
import productCurvyLines from "../../assets/img/productCurvyLines.png";
import MagicHover from "magic-hover";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Footer from "../../common/components/Footer";
import DialogComponent from "../../common/components/DialogComponent";

const useStyles = makeStyles(styles);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function PrivacyPolicyPage(props) {
  const classes = useStyles();
  const [redirectTo, setRedirectTo] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} />}
      <Grid
        container
        style={{
          backgroundImage: "url(" + productCurvyLines + ")",
          backgroundSize: "auto",
          paddingBottom: "5vh",
          textAlign: "initial",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        {" "}
        <Grid item sm={12} xs={12}>
          <IconButton
            onClick={() => setRedirectTo("/")}
            style={{ color: "black", padding: "5vh" }}
          >
            <Icon>arrow_back_ios</Icon>
          </IconButton>
        </Grid>
        <Grid item xs={2} sm={2}></Grid>
        <Grid item xs={12} sm={8} style={{ marginTop: "-50px" }}>
          <br></br>
          <br />
          <br></br>
          <Typography variant="h4">Политика на приватност</Typography>
          <br></br>
          <Typography variant="subtitle1">
            Оваа политика на приватност дава општи информации за начинот на кој
            ги собираме, одржуваме и управуваме со личните податоци, секогаш
            кога ја посетувате нашата интернет страна и/или користите некоја од
            нашите административни услуги. Ги собираме само оние лични податоци
            кои се неопходни за обезбедување на нашите административни услуги
            и/или да ги оствариме активностите за заштита на вашите права
            согласно закон во делот на јавни електронски комуникациски услуги,
            вклучувајќи и надзор.
          </Typography>
          <br></br>
          <Typography variant="h6">
            <strong>Контролор на збирки на лични податоци</strong>
          </Typography>{" "}
          <br></br>
          <Typography variant="subtitle1">
            Контролор на личните податоци е Агенцијата за електронски
            комуникации (во понатамошниот текст: АЕК или Агенцијата), со седиште
            на кеј Димитар Влахов бр 21, Скопје, Република Северна Македонија.
            <br></br>Активностите на обработка поврзани со on-line услугите
            достапни преку интернет страната на Агенцијата
            https://mailcheck.mkd-cirt.mk, се извршуваат од страна на техничкиот
            персонал на Агенцијата за електронските комуникации одговорен за
            овие услуги или лицата задолжени за извршување на предметните
            активности. Активностите за обработка поврзани со нашите
            административни услуги се извршуваат од страна на нашите вработени,
            врз основа на нивните службени должности и принципот ,,треба да
            знае” (“need to know”).<br></br>
            Агенцијата за електронски комуникации како национален регулаторен
            орган на Република Северна Македонија во областа на регулација на
            пазарите на електронски комуникации е посветена на зачувување на
            приватноста на субјектите и сите лични податоци кои се колектираат и
            обработуваат од нјзина страна се обработени во согласност со
            одредбите од Законот за заштита на лични податоци и Законот за
            електронски комуникации.
          </Typography>
          <br></br>
          <Typography variant="h6">
            <strong>Чии податоци можеме да ги собираме?</strong>
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Можеме да собираме податоци поврзани со следниве категории на лица:
            <ul>
              <li style={{ fontSize: "16px" }}>
                Посетители на нашите интернет страни – Вообичаено собираме
                навигациски податоци (види подолу). Доколку преку нашите
                интернет услуги мораме да собереме и податоци кои го откриваат
                идентитетот на лицето (на пример при поднесување на барања), тоа
                јасно ќе го појасниме, а ќе појасниме и кои се нашите намери со
                нив
              </li>
              <li style={{ fontSize: "16px" }}>
                Лицата претставници на македонски органзиации кои се согласни да
                ги користат услугите за безбедна комуникација по е-пошта
                достапни на веб-страницата https://mailcheck.mkd-cirt.mk.
                Дополнително за органзиациите се прибираат информации за
                име/назив и адреса за комуникација по е-ошта на организацијата.
              </li>
            </ul>
          </Typography>
          <br></br>
          <Typography variant="h5">
            <strong> Категории на податоци кои се обработуваат</strong>
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            При посетата на нашата интернет страна серверот, за секој посетител
            креира логови со следниве податоци:
            <ul>
              <li style={{ fontSize: "16px" }}>
                Вашата интернет протокол (IP) адреса{" "}
              </li>
              <li style={{ fontSize: "16px" }}>
                Уредот од кој пристапувате, вид и модел
              </li>
              <li style={{ fontSize: "16px" }}>Вашиот оперативен систем</li>
              <li style={{ fontSize: "16px" }}>
                Видот на пребарувачот, plugins и верзија
              </li>
              <li style={{ fontSize: "16px" }}>
                Страните кои ги посетувате на нашата веб-страна и времето кое го
                минувате на секоја страна
              </li>
              <li style={{ fontSize: "16px" }}>
                URL на страната која ве префрлила на нашата веб-страна и во
                некои случаи, термините кои сте ги користеле во обидот да ја
                најдете страната на ДЗЛП
              </li>
              <li style={{ fontSize: "16px" }}>
                Јазикот на пребарувачот кој го користите
              </li>
              <li style={{ fontSize: "16px" }}>
                Држава (се одредува преку IP адресата)
              </li>
            </ul>
            Самостојно ги одржуваме серверите и сите активности на веб страната
            ги спроведуваме интерно, вклучувајќи ги логовите кои се креираат и
            анализирањето на податоците кои се пренесуваат од посетите на нашата
            веб- страна.
          </Typography>
          <br></br>
          <Typography variant="h5">
            <strong>Податоци кои корисниците доброволно ги доставуваат</strong>
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Единствените лични податоци кои ги прибираме од Вашата посета на
            нашата веб- страна, се оние кои Вие доброволно ги доставувате за
            конкретни цели, како би ни овозможиле да ги оствариме нашите
            овластувања за надзор, корективни санкции, авторизација и
            советодавни овластувања
            <br></br>
            Може да собереме некој од следниве видови на лични податоци: име и
            презиме, поштенска адреса, контакт телефон, факс, email. Во нашите
            Годишни извештаи вообичаено не се наведуваат, но доколку се случи,
            се наведуваат статистички податоци за бројот на корисници или
            барањата и/или иницијативите кои пристигнале, во форма која не
            открива нечиј идентитет.
            <br></br>
            Не споделуваме податоци за Вас и/или за Вашата посета на нашата веб
            страна, на трети лица, освен кога станува збор за пријавување на
            злонамерен напад или во други случаи предвидени со закон.
            <br></br>
            Вашите лични податоци ги собираме директно од Вас. Во времето на
            собирање, ќе бидете информирани за специфичните цели за кои Вашите
            лични податоци се собираат. Личните податоци ги собираме преку
            различни канали, вклучувајќи:
            <ul>
              <li style={{ fontSize: "16px" }}>
                Преку Вашето користење на услугите достапни на нашата веб
                страна, вклучувајќи ги овде и формите за аплицирање
              </li>
              <li style={{ fontSize: "16px" }}>Преку нашиот email</li>
              <li style={{ fontSize: "16px" }}>Преку пошта</li>
              <li style={{ fontSize: "16px" }}>По факс</li>
            </ul>
          </Typography>
          <br></br>
          <Typography variant="h5">
            <strong>Време на чување на собраните податоци</strong>
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Податоците кои ги собираме се чуваат во согласност со применливите
            интерни процедури на Агенцијата за електронски комуникации за
            периодот потребен за постигнување на целта на обработката, односно
            за да ги обезбедиме соодветните административни услуги побарани од
            корисниците.
          </Typography>
          <br></br>
          <Typography variant="h5">
            <strong>
              Што се случува доколку не можеме да ги собереме Вашите лични
              податоци?
            </strong>
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Доколку не ги добиеме сите податоци кои ни се потребни за Вас,
            можеби нема да бидеме во можност да Ви ги обезбедиме услугите и/или
            информациите.
          </Typography>
          <br></br>
          <Typography variant="h5">
            <strong>Кои се Вашите права?</strong>
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Вашите права на увид во збирките на лични податоци, дополнување,
            измена и бришење на личните податоци, спречување на користење на
            личните податоци, можете да ги остварите со поднесување на барање
            кое ќе го доставите до Агенцијата за електронски комуникации (
            адреса: Кеј Димитар Влахов бр. 21 1000 Скопје, тел: 02/3289200,
            е-mail: contact@aec.mk). За дополнителни информации и/или
            остварување на Вашите права за заштита на лични податоци, можете да
            го контактирате нашиот офицер за заштита на лични податоци Елизабета
            Двојакова на следниот email: elizabeta.dvojakova@aec.mk
            <br></br>
            Доколку сметате дека начинот на кој АЕК ги обработува Вашите лични
            податоци е во спротивност на одредбите од Законот за заштита на
            личните податоци, можете да поднесете барање до Агенцијата за
            зачтита на лични податоци како надзорно тело .
          </Typography>
          <br></br>
          <Typography variant="h5">
            <strong>Согласност за собирање на лични податоци</strong>
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Со запознавањето со оваа политика и последователното упатување на
            нашите интернет услуги, корисниците даваат согласност за начинот на
            обезбедување на услугите на ДЗЛП, преку формите за комуникација
            достапни на нашата веб страна.
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2}></Grid>
      </Grid>
      <Footer></Footer>
    </React.Fragment>
  );
}
