import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
// @material-ui/core components
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import "./NavItem.css";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  TextField,
  CircularProgress,
  IconButton,
  Icon,
  Tab,
  Tabs,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Typography,
  Divider,
  Box,
  Hidden,
} from "@material-ui/core";
// @material-ui/styles
import styles from "../../assets/jss/layouts/appStyle";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import bgImg from "../../assets/img/bg.jpg";
import bgImg2 from "../../assets/img/bg1.jpg";
import productCurvyLines from "../../assets/img/productCurvyLines.png";
import MagicHover from "magic-hover";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Footer from "../../common/components/Footer";
import DialogComponent from "../../common/components/DialogComponent";

const useStyles = makeStyles(styles);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function CookiePage(props) {
  const classes = useStyles();
  const [redirectTo, setRedirectTo] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} />}
      <Grid
        container
        style={{
          backgroundImage: "url(" + productCurvyLines + ")",
          backgroundSize: "auto",
          paddingBottom: "5vh",
          textAlign: "initial",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        {" "}
        <Grid item sm={12} xs={12}>
          <IconButton
            onClick={() => setRedirectTo("/")}
            style={{ color: "black", padding: "5vh" }}
          >
            <Icon>arrow_back_ios</Icon>
          </IconButton>
        </Grid>
        <Grid item xs={2} sm={2}></Grid>
        <Grid item xs={12} sm={8} style={{ marginTop: "-50px" }}>
          <br></br>
          <br />
          <br></br>
          <Typography variant="h4">
            Политика за користење на колачиња (cookies)
          </Typography>
          <br></br>
          <Typography variant="h6">
            <strong>Што се колачињата (cookies)?</strong>
          </Typography>{" "}
          <br></br>
          <Typography variant="subtitle1">
            Колачињата (cookies) се мали текстуални датотеки, кои се сместуваат
            во Вашиот компјутер или мобилен телефон од страна на веб страниците
            кои ги посетувате. Овие текстуални датотеки можат да бидат прочитани
            од страна на веб страниците кои ги посетувате и да помогнат во
            Вашата идентификација кога повторно ќе ја отворите веб страницата.
            Овие текстуални датотеки можат да бидат „постојани“ или
            „привремени“. Постојаните колачиња (cookies) остануваат на Вашиот
            компјутер и кога ќе го исклучите пребарувачот (ќе преминете во
            офлајн состојба), додека привремените се бришат штом го затворите
            интернет пребарувачот. Колачињата (cookies) се користат за повеќе
            цели, како на пример за да се запомнат Вашите активности и
            преференции (вклучувања, јазични преференции, големина на фонт или
            како да ви се прикажува страницата), за да се обезбеди
            персонализирана/приспособена содржина на веб страницата и да се
            прикаже навигациската историја на истата.
          </Typography>
          <br></br>
          <Typography variant="h6">
            <strong>Зошто користиме колачиња (cookies)?</strong>
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            На оваа веб страница користиме колачиња (cookies) од следниве
            причини:
            <ul>
              <li style={{ fontSize: "16px" }}>
                За техничко функционирање на веб страницата
              </li>
              <li style={{ fontSize: "16px" }}>
                За собирање на збирни и анонимни статистички податоци
              </li>
              <li style={{ fontSize: "16px" }}>
                За да ги запомниме Вашите преференции како да се прикажува
                страницата (преференции за параметрите на екранот), како на
                пример контрастот на бојата или големината на фонтот
              </li>
              <li style={{ fontSize: "16px" }}>
                За да запомниме/да се потсетиме, дали се согласувате (или не) со
                нашето користење на колачиња (cookies) на оваа веб страница.
              </li>
              <li style={{ fontSize: "16px" }}>
                За администрирање на содржината и други функционалности на
                страницата со цел да го подобриме нејзиниот квалитет и да бидеме
                транспарентни кон посетителите на веб-страницата.
              </li>
            </ul>
            Кога ја посетувате нашата веб страница, можеме да собереме некои
            податоци од Вашето пребарување кои согласно природата на интернетот,
            пасивно и автоматски се собираат, како на пример IP адресата, која
            содржина од веб страницата на АЕК сте ја посетиле, кога сте ја
            посетиле и од која веб страница сте биле пренасочени.
          </Typography>
          <br></br>
          <Typography variant="h5">
            <strong> Каков вид на колачиња (cookies) користиме?</strong>
          </Typography>
          <Typography variant="subtitle1">
            <ul>
              <li>Неопходни колачиња </li>
              Користиме неопходни колачиња (cookies), кои ги помнат вашите
              пребарувања/сесии кога како посетител/корисник ќе го исклучите
              пребарувачот, со цел да не мора повторно да ги поставувате; да им
              помогнеме на нашите сервери да се справат со промените во
              сообраќајот на веб-страницата; за заштита на веб-страницата од
              напади.
              <li>Функционални колачиња (cookies)</li>
              Функционални колачиња се потребни колачиња кои ја обезбедуваат
              основната функционалност на веб-страницата. Овие колачиња се
              неопходни за работа на веб-страницата и не можат да се исклучат.
              Станува збор за одговор на страницата на некои од вашите барања -
              како што се поставки за заштита на лични податоци, пополнување
              обрасци или пораки при одјавување. Овие колачиња не користат
              информации со кои може да бидете идентификувани, а потребни се за
              да можете да ги прегледувате и користите сите делови на
              страницата.
              <li>Колачиња за Анализа</li>
              Колачињата за анализа служат за спроведување анонимна анализа на
              употребата на веб-страницата која служи за понатамошни
              подобрувања. Овие колачиња се користат за следење на посети,
              сообраќај и однесување на посетителите, кои потоа ги користиме за
              прилагодување и подобрување на веб-страницата. Сите овие
              информации се кумулативни, што во крајна линија значи дека се и
              целосно анонимни. Доколку одбиете да ги прифатите овие колачиња,
              нема да знаеме кога и како ја посетувате страницата и нема да
              можеме да ја оптимизираме нејзината работа. Доколку не сакате да
              користите колачиња како што е наведено погоре или ако се
              премислите откако ќе се согласите со нашата употреба на
              колачињата, можете да ги избришете преку Вашиот пребарувач.
            </ul>
          </Typography>
          <Typography variant="h5">
            <strong>
              {" "}
              Можете ли да ги избришете или контролирате колачињата (cookies)?{" "}
            </strong>
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Информациите поврзани со колачињата (cookies), не се користат за
            Ваше лично идентификување. Прифаќањето на овие колачиња (cookies) не
            е неопходно за работа на веб страницата, но истите би Ви овозможиле
            подобро искуство во пребарувањето. Доколку сакате овие колачиња
            (cookies) можете да ги избришете или блокирате, но ако го сторите
            тоа некои карактеристики на оваа веб страна можеби нема да работат
            правилно.<br></br>Можете да ги избришете сите колачиња (cookies) кои
            веќе се наоѓаат на Вашиот компјутер и можете да ги подесите
            поголемиот дел од пребарувачите за нивно спречување. Доколку го
            сторите ова, можеби ќе има потреба рачно да ги прилагодувате
            преференциите секој пат кога посетувате веб страна, а исто така
            можно е да не работат и некои услуги и функционалности.
          </Typography>
          <br></br>
          <Typography variant="h5">
            <strong>
              Како да ги оневозможите колачињата на ниво на Интернет
              прелистувач?
            </strong>
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Доколку не сакате да користите колачиња како што е наведено погоре
            или ако се премислите откако ќе се согласите со нашата употреба на
            колачињата, можете да ги избришете преку Вашиот пребарувач.
            <br></br>
            <br></br>
            Треба да го побарате менито „Помош“ („Help“) во лентата со алатки на
            интернет-пребарувачот на Вашиот компјутер ги дава следниве
            информации за корисниците:
            <ul>
              <li>
                Како да го спречите прифаќањето нови колачиња во пребарувачот.{" "}
              </li>
              <li>
                Како да го поставите пребарувачот да Ве извести кога ќе примите
                ново колаче.
              </li>
              <li>
                Како целосно да ги исклучите колачињата. Доколку не се прифатат
                колачињата, тогаш постои можност дел од содржината на
                веб-страницата да не биде достапна, на пример, одредени
                кориснички релевантни понуди може да не се прикажат правилно. За
                да се спречи ова, Ви препорачуваме да ги прифатите колачињата.
                Постојат многу онлајн информации за колачињата во случај да
                сакате да дознаете повеќе за нив.
                <br></br>
                Посетете ја, на пример, страницата:
                <a href="https://www.allaboutcookies.org/">
                  https://www.allaboutcookies.org/
                </a>
                <br></br>
                <br></br>
                На пример, доколку користите Internet Explorer верзија 11.0,
                морате да го направите следново:
                <ul>
                  <li>Изберете „Settings“, а потоа „Internet Options“</li>
                  <li>Кликнете на табулаторот „Privacy“</li>
                  <li>
                    Со помош на лизгачкото мени изберете ги опциите што ги
                    преферирате.
                    <ul>
                      <li>
                        <strong>Блокирани колачиња:</strong> Колачето за
                        откажување може да не работи ако вашиот прегледувач е
                        конфигуриран да блокира колачиња од трета страна.
                      </li>
                      <li>
                        <strong>Бришење или штитење откажувања:</strong>Ако ги
                        избришете вашите колачиња, ќе треба повторно да се
                        откажете. Има приклучоци на прегледувач кои ви помагаат
                        да ги зачувате вашите колачиња за откажување.
                      </li>
                      <li>
                        <strong>Само овој прегледувач: </strong>Откажувањето
                        важи само за профилот на прегледувач во кој сте го
                        поставиле. На пример, ако поставите откажување додека
                        користите Firefox, но потоа користите Chrome,
                        откажувањето нема да биде активно во Chrome. За да се
                        откажете во Chrome, ќе треба да го повторите процесот на
                        откажување. Така е бидејќи колачињата не може да бидат
                        прочитани помеѓу различни прегледувачи или профили на
                        прегледувачи.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <Typography variant="h5">
            <strong>Напомена:</strong>
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Откажувањето не го спречува користењето на колачиња или други
            технологии за цели поинакви од избирање реклами засновани на ваши
            интереси како што се налага според вашето онлајн однесување. Ако
            изберете да се откажете, сè уште може да се собираат податоците за
            вашите активности на прегледување на интернет и вие сè уште ќе
            гледате податоцо/кампањи/реклами. Податоците/Кампањите/Рекламите
            може да бидат избрани, на пример, врз основа на содржината на
            веб-страницата во која се прикажани. Ако сакате да ги блокирате или
            избришете сите колачиња заедно, може да ги користите поставките на
            веб-прегледувачот за да го направите тоа.
          </Typography>
          <br></br>
          <Typography variant="h5">
            <strong>Информации за прецизна локација.</strong>
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            За да го овозможите или оневозможите собирањето информации за
            прецизна локација од вашиот мобилен уред преку нашите мобилни
            апликации, може да пристапите до поставките на вашиот мобилен уред и
            да изберете да го ограничите тоа собирање. Исто така може да го
            ограничите собирањето на информации за прецизна локација од
            веб-страниците преку поставките на прегледувачот.
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2}></Grid>
      </Grid>
      <Footer></Footer>
    </React.Fragment>
  );
}
