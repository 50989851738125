import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
// @material-ui/core components
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import "./NavItem.css";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  TextField,
  CircularProgress,
  IconButton,
  Icon,
  Tab,
  Tabs,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Typography,
  Divider,
  Box,
  Hidden,
} from "@material-ui/core";
// @material-ui/styles
import styles from "../../assets/jss/layouts/appStyle";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import bgImg from "../../assets/img/bg.jpg";
import bgImg2 from "../../assets/img/bg1.jpg";
import productCurvyLines from "../../assets/img/productCurvyLines.png";
import MagicHover from "magic-hover";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Footer from "../../common/components/Footer";
import DialogComponent from "../../common/components/DialogComponent";

const useStyles = makeStyles(styles);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function SpfPage(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState();
  const [openSpfGeneratorDialog, setSpfGeneratorDialog] = useState();
  const [openSpfValidatorDialog, setSpfValidatorDialog] = useState();

  const dispatch = useDispatch();

  const closeDialog = (type) => {
    if (type === "spfGenerator") {
      setSpfGeneratorDialog(false);
    } else if (type === "spfValidator") {
      setSpfValidatorDialog(false);
    }
  };
  function createData(qualifiers, purpose, implementation) {
    return { qualifiers, purpose, implementation };
  }

  function createData2(domain, spf) {
    return { domain, spf };
  }

  const rows2 = [
    createData2("Gmail", "v=spf1 include:_spf.google.com ~all"),
    createData2("Sendgrid", "v=spf1 include:sendgrid.net ~all"),
    createData2("MailChimp", "v=spf1 include:servers.mcsv.net ?all"),
    createData2("Мандрил", "v=spf1 include:spf.mandrillapp.com ?all"),
    createData2("Mailgun", "v=spf1 include:mailgun.org ~all"),
  ];
  const rows = [
    createData("+", "Прифати На Хостот му е дозволено да испрати порака", "+"),
    createData(
      "-",
      "Одбиј. На Хостот не му е дозволено да испраќа порака",
      "-"
    ),
    createData(
      "~",
      "Прифати, но обележи. Хостот не смее да испраќа порака, но е во транзиција (механизам на тестирање)",
      "~"
    ),
    createData("?", "Прифати Валидноста на Хостот не е наведена", "?"),
    createData("Механизми", "Цел", "Имплементација"),
    createData(
      "a",
      "Дефинира DNS А запис за доменот како авторизиран. Ако не е специфицирано, се користи тековниот домен",
      "a, a/<prefix-length>, a:<domain>, a:<domain>/<prefix-length>"
    ),
    createData("all", "Дефинира полиса за сите други извори", "all"),
    createData(
      "exists",
      "Ја проверува валидноста на А записот за дадениот домен",
      "exists:<domain>"
    ),
    createData(
      "include",
      "Го вклучува наведениот домен како авторииран. Доколку доменот нема валиден запис, ќе добиете „permerror“",
      "include:<domain>"
    ),
    createData(
      "ip4",
      "Го дефинира опсегот на IPv6  мрежата. Може да се користи со префикс, што ја дефинира големината на опсегот. Ако не е наведен префикс, / 32 е стандарден",
      "ip4:<ip4-address>, ip4:<ip4-network>/<prefix-length>"
    ),
    createData(
      "ip6",
      "Го дефинира опсегот на IPv6 мрежата. Може да се користи со префикс, што ја дефинира големината на опсегот. Ако не е наведен префикс, / 128 е стандарден",
      "ip6:<ip6-address>, ip6:<ip6-network>/<prefix-length>"
    ),
    createData(
      "mx",
      "Го дефинира DNS MX записот на доменот како авторизиран односно, пораката мора да е испратена од доменските дојдовни сервери.",
      "mx, mx/<prefix-length>, mx:<domain>, mx:<domain>/<prefix-length>"
    ),
    createData(
      "ptr[застарено]",
      "Ги дефинира обратното име на хостот и поддоменот на IP-адресата од која се испраќа.",
      "ptr, ptr:<domain>"
    ),
    createData("Модификатори", "Цел", "Имплементација"),
    createData(
      "exp",
      "Го прикажува објаснувањето што испраќачот ќе го види ако пораката е одбиена",
      "exp=<domain>"
    ),
    createData(
      "redirect",
      "Го заменува доменот со тековниот запис",
      "redirect=<domain>"
    ),
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} />}
      <Grid
        id="start"
        container
        style={{
          backgroundImage:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)),url(" +
            bgImg +
            ")",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <ScrollLink
          activeClass="active"
          to="start"
          spy={true}
          smooth={true}
          duration={500}
        >
          <IconButton
            style={{
              position: "fixed",
              bottom: "0",
              right: "0",
              margin: "15px",
            }}
          >
            <ArrowUpwardIcon fontSize="large"></ArrowUpwardIcon>
          </IconButton>
        </ScrollLink>
        <Grid item sm={12} xs={12}>
          <IconButton
            onClick={() => setRedirectTo("/")}
            style={{ color: "white", padding: "5vh" }}
          >
            <Icon>arrow_back_ios</Icon>
          </IconButton>
        </Grid>
        <Grid item sm={3} xs={false}></Grid>
        <Grid item sm={6} xs={12} style={{ textAlign: "center" }}>
          <Typography
            variant="h1"
            style={{
              marginTop: "3vh",
              color: "white",
              opacity: "70%",
            }}
          >
            SPF
          </Typography>{" "}
          <Typography
            variant="h4"
            style={{
              marginBottom: "10vh",
              textAlign: "center",
              color: "white",
              opacity: "70%",
            }}
          >
            Sender Policy Framework
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            style={{
              color: "white",
              marginBottom: "12vh",
              marginRight: "5px",
            }}
            onClick={() => setSpfGeneratorDialog(true)}
          >
            SPF Generator
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            style={{
              color: "white",
              marginBottom: "12vh",
              marginLeft: "5px",
            }}
            onClick={() => setSpfValidatorDialog(true)}
          >
            SPF Validator
          </Button>
        </Grid>
        <Grid item sm={3} xs={false}></Grid>
        {openSpfGeneratorDialog && (
          <DialogComponent
            dialogTitle={"SPF Generator"}
            closeDialog={() => closeDialog("spfGenerator")}
            contentText="contentText"
            dialogType="spfGenerator"
          ></DialogComponent>
        )}
        {openSpfValidatorDialog && (
          <DialogComponent
            dialogTitle={"SPF Validator"}
            closeDialog={() => closeDialog("spfValidator")}
            contentText="contentText"
            dialogType="spfValidator"
          ></DialogComponent>
        )}
      </Grid>
      <Grid
        container
        style={{
          backgroundImage: "url(" + productCurvyLines + ")",
          backgroundSize: "auto",
          paddingBottom: "5vh",
          textAlign: "initial",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        <Grid item xs={2} sm={2}></Grid>
        <Grid item xs={12} sm={8}>
          <br></br>
          <br />

          <Typography variant="subtitle1" id="content">
            SPF (Sender Policy Framework) или РПИ (Рамка за политика на
            испраќач) е задолжителен DNS запис за безбедна испорака на е-пошта.
            Тоа е вид на автентикација со кој се штитат меил пораките од
            фалсификување, а преку тоа обезбедува заштита од фишинг и спуфинг
            напади.
          </Typography>
          <br></br>
          <Typography variant="h4">Содржина</Typography>
          <br></br>
          <ol className="nav-items">
            <li className="nav-item">
              <ScrollLink
                activeClass="active"
                to="whatIsSpfRecord"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Typography variant="subtitle1">Што е SPF запис?</Typography>
              </ScrollLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                activeClass="active"
                to="emailAuthenticationStandards"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Typography variant="subtitle1">
                  Стандарди за автентикација на е-пошта - за што служат?
                </Typography>
              </ScrollLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                activeClass="active"
                to="senderPolicyFramework"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Typography variant="subtitle1">
                  Рамка на политика на испаќач - SPF
                </Typography>
              </ScrollLink>
            </li>
            <ol>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  to="whenYouNeedSpf"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <Typography variant="subtitle1">
                    Кога се користи SPF
                  </Typography>
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  to="consOfSpfEmailAuthentication"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <Typography variant="subtitle1">
                    Недостатоци на автентикација преку SPF
                  </Typography>
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  to="commonSpfMisconceptions"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <Typography variant="subtitle1">
                    Чести заблуди за SPF
                  </Typography>
                </ScrollLink>
              </li>
            </ol>
            <li className="nav-item">
              <ScrollLink
                activeClass="active"
                to="howDoesSpfWork"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Typography variant="subtitle1">Како работи SPF?</Typography>
              </ScrollLink>
            </li>
            <ol>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  to="spfRecordSyntax"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <Typography variant="subtitle1">
                    Синтакса на SPF записот
                  </Typography>
                </ScrollLink>
              </li>
              <ol>
                <li className="nav-item">
                  <ScrollLink
                    activeClass="active"
                    to="whatYouShouldKeepInMind"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <Typography variant="subtitle1">
                      Што треба да имате предвид
                    </Typography>
                  </ScrollLink>
                </li>
              </ol>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  to="createSpfRecordForYourDomain"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <Typography variant="subtitle1">
                    Креирање на SPF запис за вашиот домен
                  </Typography>
                </ScrollLink>
              </li>
              <ol>
                <li className="nav-item">
                  <ScrollLink
                    activeClass="active"
                    to="step1-preparation"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <Typography variant="subtitle1">
                      Чекор 1 - Подготовка
                    </Typography>
                  </ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink
                    activeClass="active"
                    to="step2-dnsControlPanel"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <Typography variant="subtitle1">
                      Чекор 2 - Контролна табла за DNS
                    </Typography>
                  </ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink
                    activeClass="active"
                    to="step3-spfRecord"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <Typography variant="subtitle1">
                      Чекор 3 – SPF запис
                    </Typography>
                  </ScrollLink>
                </li>
              </ol>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  to="spfRecordForMultipleDomains"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <Typography variant="subtitle1">
                    SPF запис за повеќе домени
                  </Typography>
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  to="canISplitALargeSpfRecord"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <Typography variant="subtitle1">
                    Поделба на долги SPF записи
                  </Typography>
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  to="validateYourSpfRecord"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <Typography variant="subtitle1">
                    Валидација на SPF запис
                  </Typography>
                </ScrollLink>
              </li>
            </ol>
            <li className="nav-item">
              <ScrollLink
                activeClass="active"
                to="howToCreateSpfRecordUsingMyDnsProvider"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Typography variant="subtitle1">
                  Како да се креира SPF запис преку DNS провајдер?
                </Typography>
              </ScrollLink>
            </li>
          </ol>
          <br></br>
          <br></br>
          <Typography variant="h4" id="whatIsSpfRecord">
            Што е SPF запис?
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            TXT е еден од видовите на DNS записи. Најчесто се користи за
            означување факти за доменот и обезбедување информации на надворешни
            извори. За автентификација на е-пошта е задолжителен. На пример,
            е-пошта доаѓа од некој сервер до вашиот интернет провајдер (ISP).
            Интернет провајдерот може да користи дедициран тип на запис TXT,
            т.н. SPF(РПИ) запис, за да ја автентицира пораката. Овој запис
            содржи податоци за доверливи (trusted) сервери кои се овластени од
            вашиот домен. Вашиот Интернет-провајдер може да го идентификува
            изворот од кој доаѓа пораката и да открие фалсификувана порака.
            SPF(РПИ) е основен, но не и единствени стандард за автентикација на
            електронска пошта.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" id="emailAuthenticationStandards">
            Стандарди за автентикација по е-пошта - за што служат?
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Постојат три усвоени стандарди за автентикација на е-пошта: SPF,
            DKIM и DMARC:
            <ul>
              <li>
                <Typography variant="subtitle1">
                  SPF проверува дали IP-адресата од која доаѓа пораката е
                  овластена
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  DKIM ја проверува пораката користејќи клучеви за потврда на
                  потпис во пораката
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  DMARC ги комбинира SPF и DKIM
                </Typography>
              </li>
            </ul>
            SPF, DKIM и DMARC се повикуваат на DNS записи, но се разликуваат во
            техничката имплементација.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" id="senderPolicyFramework">
            Рамка за политика на испраќач - SPF
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            SPF(РПИ) е протокол според кој серверите за е-пошта одлучуваат дали
            ќе ја примат или одбијат дојдовната порака. Одлуката се базира на
            информациите за SPF(РПИ) во TXT записот, како список на овластени IP
            адреси за еден домен. Ако пораката е испратена од една од адресите
            во листата на дозволени испраќачи запишани во SPF записот, таа не е
            фалсификувана и може да се пропушти.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" id="whenYouNeedSpf">
            Кога се користи SPF
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Дколку имате корпоративна пошта под врвниот домен .mk, и ја
            користите често за комуникација со странки и корисници на ваши
            производи и сулуги, во тој случај имплементацијата на SPF е основа
            за заштита на пораките што ги испраќате. Ако немате валиден SPF
            запис, или ако е неточен, вашиот Интернет провајдер може да направи
            дополнителна проверка на валидноста на пораката пред таа да се
            испрати до примателот. Неуспешна SPF(РПИ) автентикација може да
            значи дека вашите пораки ќе завршат во папката Spam на корисникот,
            или ќе бидат целосно одбиени како потенцијално штетни пораки.{" "}
            <br></br>
            <br></br> Со користење на SPF се филтрираат фишинг и спуфинг
            пораките. Освен SPF, препорачуваме да имплементирате и DKIM и DMARC.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" id="consOfSpfEmailAuthentication">
            Недостатоци на автентикација преку SPF
          </Typography>
          <Typography variant="subtitle1">
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Потребно е навремено и редовно ажурирање на SPF записите со
                  секоја промена на јавните IP адреси кои ги користите за
                  испраќање на пораките по е-пошта. Ова вообичаено се случува
                  при промена на Интернет-провајдерот или при промена/ангажирање
                  на услуга во облак за управување со електронска пошта на
                  органзиацијата.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Ако се користи само SPF, тоа не е гаранција дека испратените
                  опраки ќе се достават до примателите.
                </Typography>
              </li>
            </ul>
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" id="commonSpfMisconceptions">
            Чести заблуди на SPF
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Следуваат насоки за исправно користење на SPF записите.
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Целосна заштита на доменот од измама/спуфинг
                </Typography>
              </li>
            </ul>
            SPF се користи на обвивката на пораката адреса за “from” адресата.
            За разлика од полето “from” во делот на заглавието од пораката, оваа
            информација не е видлива за корисникот/примателот на пораката. За да
            се види, потребно е да се прикаже пораката во т.н. оргинален или
            сиров формат. Затоа SPF записот не може да ја заштити адресата на
            испраќачот што се прикажува кога примателот ќе ја добие пораката.
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Со SPF, целосно се заштитувате од спам пораки
                </Typography>
              </li>
            </ul>
            SPF се користи во комбинација со системите за филтрирање на несакани
            пораки (spam) и проверка на е-пошта. SPF заштитува од фалсификувани
            пораки од одреден домен. SPF сепак не нуди значителни подобрувања во
            однос на борбата против несаканите пораки spam.
            <ul>
              <li>
                <Typography variant="subtitle1">
                  SPF го авторизира испраќачот на е-пошта
                </Typography>
              </li>
            </ul>
            Со користење на SPF се прави авторизациј ана серверот кој ја испраќа
            пораката наспрема запишаните IP адреси во SPF како овластени
            испраќачи.
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Еден запис во SPF на секој авторизиран домен
                </Typography>
              </li>
            </ul>
            Не е дозволено поставување на два или повеќе TXT записи за SPF за
            едне ист домен. ВО ваков случај вообичаено се појавува грешка од тип
            „permerror“.
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Автентификација на е-пошта само со DKIM е доволна
                </Typography>
              </li>
            </ul>
            Дури и ако сите пораки се авторизирани според DKIM, сепак е потребен
            SPF запис за да се идентификува доменот.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" id="howDoesSpfWork">
            Како работи SPF?
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            SPF се состои од следниве чекори:
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Креирање на SPF запис. Воспоставува политика за автентикација
                  и дефинира листа на сервери за испраќање на е-пошта овластени
                  да испраќаат пораки од еден домен.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Проверка по DNS записите. Дојдовната порака се потврдува во
                  DNS. Името на доменот треба да биде наведено во обвивката на
                  пораката во полето „from “. Потоа, влезниот сервер проверува
                  дали IP-адресата од која е испратена пораката е авторизиранa
                  во SPF записот. Ако адресата од која е испратена пораката ја
                  нема во листата на адреси во SPF записот, автентикацијата на
                  SPF се означува како неуспешна.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Резултат од автентикација. Серверот за е-пошта или ќе ја
                  достави, или ќе ја означи, или ќе ја одбие пораката зависно од
                  правилата наведени во SPF записот, како и зависно од други
                  фактори , политики и проверки.
                </Typography>
              </li>
            </ul>
            На следната слика е прикажан процесот на испраќање и прием на пораки
            по е-пошта со користење на SPF.
          </Typography>
          <img
            alt=""
            src={require("../../assets/img/spf.png")}
            width="100%"
            style={{ marginTop: "3vh" }}
          ></img>
          <br></br>
          <br></br>
          <Typography variant="h4" id="spfRecordSyntax">
            Синтакса на SPF записот
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            На следниот пример е прикажан еден вообичаен SPF запис:
            <br></br>
            <span style={{ backgroundColor: "#f9f97275" }}>
              “v=spf1 +a +mx redirect=example.com -all”
            </span>
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Делот „v=spf1“ е број на верзијата, а остатокот се механизми,
            квалификатори и мофикатори преку кои се специфицираат разни правила
            за проверката со SPF.
          </Typography>
          <br></br>
          <Table
            style={{ width: "100%" }}
            className={classes.table}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <Hidden smDown implementation="js">
                  <StyledTableCell>Квалификатори</StyledTableCell>
                </Hidden>
                <StyledTableCell align="left">Цел</StyledTableCell>
                <Hidden smDown implementation="js">
                  <StyledTableCell align="left">Имплементација</StyledTableCell>
                </Hidden>
                <Hidden mdUp implementation="js">
                  <StyledTableCell>
                    Квалификатори/Имплементација
                  </StyledTableCell>
                </Hidden>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.qualifiers}>
                  <Hidden smDown implementation="js">
                    <StyledTableCell component="th" scope="row">
                      {row.qualifiers}
                    </StyledTableCell>
                  </Hidden>
                  <StyledTableCell align="left">{row.purpose}</StyledTableCell>
                  <Hidden smDown implementation="js">
                    <StyledTableCell align="left">
                      {row.implementation}
                    </StyledTableCell>
                  </Hidden>
                  <Hidden mdUp implementation="js">
                    <StyledTableCell>
                      {row.qualifiers}/{row.implementation}
                    </StyledTableCell>
                  </Hidden>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <br></br>
          <br></br>
          <Typography variant="h4" id="whatYouShouldKeepInMind">
            Што треба да имате предвид
          </Typography>
          <Typography variant="subtitle1">
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Должината на еден SPF запис не може да надмине 255 карактери.
                  Ако е потребно, може да се користат повеќе записи
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Записите за поддомени треба да бидат именувани соодветно.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  За да се избегне прекумерно оптеретување на DNS серверот,
                  вкупниот број на механизми, вклучително и модификатори, треба
                  да се ограничи на најмногу 10.
                </Typography>
              </li>
            </ul>
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" id="createSpfRecordForYourDomain">
            Креирајте SPF запис за вашиот домен
          </Typography>
          <br></br>
          <Typography variant="h5" id="step1-preparation">
            Чекор 1 - Подготовка
          </Typography>
          <Typography variant="subtitle1">
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Идентификувајте ги сите сервери за електронска пошта и IP
                  адреси што ќе бидат наведени како овластени во SPF записот
                </Typography>
              </li>
            </ul>
          </Typography>
          <Typography variant="h5" id="step2-dnsControlPanel">
            Чекор 2 - Контролна табла за DNS
          </Typography>
          <Typography variant="subtitle1">
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Пристапете до DNS контролната табла на вашиот интернет
                  провајдер (ISP) и пронајдете го делот за TXT тип на запис.
                </Typography>
              </li>
            </ul>
          </Typography>
          <Typography variant="h5" id="step3-spfRecord">
            Чекор 3 - SPF запис
          </Typography>
          <Typography variant="subtitle1">
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Започнете со ознаката за version: v=spf1. Следни верзии ќе
                  бидат v=spf2, v=spf3, итн
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Додадете ги сите IP адреси што сте ги собрале за да ги
                  наведете како авторизирани:
                </Typography>
                <Typography variant="subtitle1">
                  <span style={{ backgroundColor: "#f9f97275" }}>
                    ip4:35.167.41.421 ip6:2a13:c025:e4:7a01:bc72:dcb5:7a13
                  </span>
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Додадете include ознака за секоја услуга за е-пошта од трети
                  фирми кои сакате да ги означите како доверлив испраќач кој е
                  овластен од вас:
                </Typography>
                <Typography variant="subtitle1">
                  <span style={{ backgroundColor: "#f9f97275" }}>
                    include:outlook.com
                  </span>
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Искористете ги другите механизми, квалификатори или
                  модификатори за да го комплетирате SPF записот.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Ознаката all обично се користи за финализирање на записот.
                </Typography>
                <ul>
                  <li>
                    <Typography variant="subtitle1">
                      <span style={{ backgroundColor: "#f9f97275" }}>-all</span>{" "}
                      - сите ненаведени сервери не се овластени (пораките ќе
                      бидат одбиени).
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle1">
                      <span style={{ backgroundColor: "#f9f97275" }}>~all</span>{" "}
                      – сите ненаведени сервери не се авторизирани, пораките ќе
                      бидат обележани и прифатена (во спам папката од сандачето
                      за е-пошта).
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle1">
                      <span style={{ backgroundColor: "#f9f97275" }}>+all</span>{" "}
                      – секој сервер е овластен (непожелна опција).
                    </Typography>
                  </li>
                </ul>
              </li>
            </ul>
            Вака изгледа наједноставниот SPF запис: <br></br>
            <span style={{ backgroundColor: "#f9f97275" }}>
              "v=spf1 a mx -all"
            </span>
            <br></br> Според овој SPF запис сите A и MX записи за овој домен се
            авторизирани да испраќаат пораки. Пораките од било кој друг сервер
            или IP адреса ќе бидат одбиени.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" id="spfRecordForMultipleDomains">
            SPF запис за повеќе домени
          </Typography>
          <Typography variant="subtitle1">
            <br></br>
            Ако имате примарен домен, на пример – domain.com.mk со запис како
            овој „v=spf1 a mx -all“, и треба да направите SPF запис за повеќе
            домени како vtordomen.mk?
            <br></br>
            Механизмот „include“ ви овозможува да наведете други домени кои се
            независни од вашиот примарен домен. На пример, domain.com.mk може да
            испраќа пораки користејќи vtordomen.mk.
            <br></br>
            <span style={{ backgroundColor: "#f9f97275" }}>
              "v=spf1 include:vtordomen.mk -all"
            </span>
            <br></br>
            Можете да имате само по еден TXT запис за SPF по домен.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" id="canISplitALargeSpfRecord">
            Поделба на долги SPF записи
          </Typography>
          <Typography variant="subtitle1">
            <br></br>
            Ако SPF записот е подолг од 255 карактери, можете да го поделите на
            неколку записи што ќе бидат вклучени во главниот SPF запис.
            <br></br>
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Прво, креирајте одделни записи. Нивните имиња треба да се
                  однесуваат на тековниот домен:
                </Typography>
              </li>
              spf1.domen.mk TXТ
              <br></br>
              <span style={{ backgroundColor: "#f9f97275" }}>
                v=spf1 a mx a:mail.domen.mk a:first.domen.mk a:vtor.domen.mk
                mx:tretdomen.com.mk -all
              </span>
              <br></br>
              spf2.domen.mk TXT
              <br></br>
              <span style={{ backgroundColor: "#f9f97275" }}>
                v=spf1 ip4:32.213.11.127 -all
              </span>
              <br></br>
              spf3.domen.mk TXT <br></br>
              <span style={{ backgroundColor: "#f9f97275" }}>
                v=spf1 include:outlook.com -all
              </span>
              <li>
                <Typography variant="subtitle1">
                  Променете го првичниот SPF запис:
                </Typography>
              </li>
              domen.mk TXT
              <br></br>
              <span style={{ backgroundColor: "#f9f97275" }}>
                v=spf1 include:spf1.domen.mk include:spf2.domen.mk
                include:spf3.domen.mk -all
              </span>
              <br></br>
            </ul>
            По ажурирањето на DNS сите овие записи ќе бидат проверени како еден.{" "}
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" id="validateYourSpfRecord">
            Валидација на SPF запис
          </Typography>
          <Typography variant="subtitle1">
            <br></br>
            Последното нешто што треба да го сторите е да го потврдите
            (валидирате) вашиот SPF запис. Можете да ја искористите алатката за
            валидација на SPF која се наоѓа најгоре на истава страница.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" id="howToCreateSpfRecordUsingMyDnsProvider">
            Како да се креира SPF запис преку DNS провајдер?
          </Typography>
          <Typography variant="subtitle1">
            <br></br>
            Може да креирате и управувате со вашите SPF записи користејќи
            соодветна конзола или контролен панел од вашиот DNS провајдер. Некои
            даваат детални упатства или насоки како да креирате TXT записи.
            Подолу, ќе најдете линкови до водечките провајдери.
            <br></br>
            <ul>
              <li>
                <a href="https://docs.aws.amazon.com/en_us/Route53/latest/DeveloperGuide/resource-record-sets-creating.html">
                  <Typography variant="subtitle1">Amazon Route 53</Typography>
                </a>
              </li>
              <li>
                <a href="https://docs.microsoft.com/en-us/microsoft-365/admin/dns/create-dns-records-for-azure-dns-zones?view=o365-worldwide#add-a-txt-record-for-spf-to-help-prevent-email-spam">
                  <Typography variant="subtitle1">Azure DNS</Typography>
                </a>
              </li>
              <li>
                <a href="https://support.cloudflare.com/hc/en-us/articles/360019093151">
                  <Typography variant="subtitle1">Cloudflare DNS</Typography>
                </a>
              </li>
              <li>
                <a href="https://uk.godaddy.com/help/add-a-txt-record-19232">
                  <Typography variant="subtitle1">
                    GoDaddy Premium DNS
                  </Typography>
                </a>
              </li>
              <li>
                <a href="https://support.google.com/a/answer/33786?hl=en">
                  <Typography variant="subtitle1">Google Cloud DNS</Typography>
                </a>
              </li>
            </ul>
            <br></br>
            Откако ќе го сетирате вашиот SPF запис, може да продолжите со
            <Link to={`/dkim`} style={{ textDecoration: "none" }}>
              {" "}
              <Typography variant="subtitle1" style={{ display: "inline" }}>
                DKIM
              </Typography>
            </Link>{" "}
            и{" "}
            <Link to={`/dmarc`} style={{ textDecoration: "none" }}>
              {" "}
              <Typography variant="subtitle1" style={{ display: "inline" }}>
                DMARC
              </Typography>
            </Link>{" "}
            протоколите.
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Користен извор:{" "}
            <a href="https://blog.mailtrap.io/spf-records-explained/">
              <Typography variant="subtitle1" style={{ display: "inline" }}>
                Mailtrap
              </Typography>
            </a>
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2}></Grid>
      </Grid>
      <Footer></Footer>
    </React.Fragment>
  );
}
