import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";

export const spfGenerator = yup.object().shape({
  name: yup.string().required(LOCALE.name + LOCALE.required_field),
  email: yup
    .string()
    .required(LOCALE.email + LOCALE.required_field)
    .email(LOCALE.invalid_email_address_format),
  username: yup
    .string()
    .matches(/^[A-Za-z_-][A-Za-z0-9_-]{5,}$/, {
      message:
        LOCALE.incorrect_format +
        " (минимум 6 карактери, дозволени се мали и големи букви, цифри и -_)",
    })
    .required(LOCALE.username + LOCALE.required_field),
});

export const spfValidator = yup.object().shape({
  domainSpf: yup.string().required(LOCALE.domain + LOCALE.required_field),
});

export const dkimGenerator = yup.object().shape({
  dkimDomain: yup.string().required(LOCALE.dkim_domain + LOCALE.required_field),
  dkimSelector: yup
    .string()
    .required(LOCALE.dkim_selector + LOCALE.required_field),
});

export const dkimValidator = yup.object().shape({
  domainDkim: yup.string().required(LOCALE.domain + LOCALE.required_field),
  selectorDkim: yup.string().required(LOCALE.selector + LOCALE.required_field),
});

export const dmarcGenerator = yup.object().shape({
  name: yup.string().required(LOCALE.name + LOCALE.required_field),
  email: yup
    .string()
    .required(LOCALE.email + LOCALE.required_field)
    .email(LOCALE.invalid_email_address_format),
  username: yup
    .string()
    .matches(/^[A-Za-z_-][A-Za-z0-9_-]{5,}$/, {
      message:
        LOCALE.incorrect_format +
        " (минимум 6 карактери, дозволени се мали и големи букви, цифри и -_)",
    })
    .required(LOCALE.username + LOCALE.required_field),
});

export const dmarcValidator = yup.object().shape({
  domainDmarc: yup.string().required(LOCALE.domain + LOCALE.required_field),
});
