import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  Divider,
  CircularProgress,
} from "@material-ui/core";
// @material-ui/styles
import styles from "../../../assets/jss/layouts/appStyle";
import { makeStyles } from "@material-ui/core/styles";
// core components
import { LOCALE } from "../../../properties/Locale";
import {
  notifyShowSuccessMessage,
  notifyShowErrorMessage,
  triggerRerender,
} from "../../../common/actions/CommonActions";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";
import { DomainRepository } from "./DomainRepository";

const useStyles = makeStyles(styles);

export default function DeleteDomainComponent(props) {
  const classes = useStyles();
  const [domainId] = useState(props.match.params.domainId);
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState();
  const [btnSubmitLoading, setBtnSubmitLoading] = useState(false);
  const [data, setData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const loadDomen = (id) => {
      showLoadingBar();
      DomainRepository.fetch(id).then((res) => {
        setData(res.data);
        hideLoadingBar();
      });
    };
    loadDomen(domainId);
  }, [domainId]);

  const showLoadingBar = () => {
    setLoading(true);
    setBtnSubmitLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
    setBtnSubmitLoading(false);
  };

  const handleSubmit = () => {
    showLoadingBar();
    DomainRepository.delete(data.id)
      .then((res) => {
        hideLoadingBar();
        dispatch(triggerRerender());
        dispatch(notifyShowSuccessMessage("Доменот е успешно избришан"));
        setRedirectTo("/app/admin/domain_checks");
      })
      .catch((err) => {
        hideLoadingBar();
        notifyShowErrorMessage(err.message);
      });
  };

  const handleClose = () => {};

  return (
    <div>
      {redirectTo && <Redirect to={redirectTo} />}
      <Card>
        <Dialog
          open={true}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              overflowY: "unset",
            },
          }}
        >
          <CardHeader color="theme">
            <h4 className={classes.cardTitleWhite}>{`Бришење на домен`}</h4>
          </CardHeader>
          <CardBody>
            <div>
              {loading ? <LinearProgress color="secondary" /> : ""}
              <DialogContent>
                {LOCALE.entity_delete_confirmation_text}?
              </DialogContent>
              <br />
              <Divider />
              <DialogActions>
                {!btnSubmitLoading && (
                  <Link
                    to={`/app/admin/domain_checks/`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button size="small" variant="outlined" color="secondary">
                      {LOCALE.cancel}
                    </Button>
                  </Link>
                )}
                <Button
                  onClick={handleSubmit}
                  size="small"
                  variant="contained"
                  disabled={btnSubmitLoading}
                  color="secondary"
                >
                  {LOCALE.delete}
                  {btnSubmitLoading && (
                    <CircularProgress
                      color="secondary"
                      size={20}
                      style={{ position: "absolute" }}
                    />
                  )}
                </Button>
              </DialogActions>
            </div>
          </CardBody>
        </Dialog>
      </Card>
    </div>
  );
}
