import {
  Box,
  Button,
  Icon,
  LinearProgress,
  Paper,
  Tab,
  Tabs,
  Typography,
  Tooltip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableBody,
  TablePagination,
  TableFooter,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TableContainer,
  Divider,
  Chip,
  Collapse,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePageTitle,
  notifyShowSuccessMessage,
} from "../../../common/actions/CommonActions";
import { LOCALE } from "../../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/layouts/appStyle";
import PropTypes from "prop-types";
import Card from "../../../common/components/Card/Card";
import CardHeader from "../../../common/components/Card/CardHeader";
import { Link } from "react-router-dom";
import CardBody from "../../../common/components/Card/CardBody";
import UnfoldMore from "@material-ui/icons/UnfoldMore";
import { DomainRepository } from "./DomainRepository";
import moment from "moment";
import NewDomainCheckAdmin from "./NewDomainCheckAdmin";
import {
  CheckBox,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import RowDomainCheckResults from "./RowDomainCheckResults";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function DomainChecksAdminComponent() {
  const useStyles = makeStyles(styles);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [pageResults, setPageResults] = useState(0);
  const [sizeResults, setSizeResults] = useState(10);
  const [orderBy, setOrderBy] = useState("dateCreated");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const [filterBy, setFilterBy] = useState();
  const [filterValue, setFilterValue] = useState();
  const [domainChecks, setDomainChecks] = useState({
    totalElements: 0,
    number: 0,
    content: [],
  });
  const [filterData, setFilterData] = useState({
    domainName: "",
  });
  const globalState = useSelector((state) => state);
  const [data, setData] = useState({
    totalElements: 0,
    number: 0,
    content: [],
  });
  const [domainCheckType, setDomainCheckType] = useState();
  const [date, setDate] = useState(new Date());
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState();
  const [selectedDomains, setSelectedDomains] = useState([]);

  useEffect(() => {
    dispatch(changePageTitle(LOCALE.domain_checks));
    if (tabValue === 0) {
      loadDomains(page, size, orderBy, orderDirection);
    } else if (tabValue === 1) {
      if (filterData?.domainName || domainCheckType || date) {
        loadDomainChecks(pageResults, sizeResults, orderBy, orderDirection);
      }
    }
  }, [
    page,
    size,
    globalState.triggerRerender,
    orderBy,
    orderDirection,
    pageResults,
    sizeResults,
    filterData,
  ]);

  const handleFilterDomainChange = (name) => (event) => {
    setFilterData({ [name]: event.target.value });
    setFilterBy(name);
    setFilterValue(event.target.value);
  };

  const loadDomains = (page, size, orderBy, orderDirection) => {
    DomainRepository.fetchAll({
      page: page,
      size: size,
      orderBy: orderBy,
      orderDirection: orderDirection,
      filterBy: filterBy,
      filterValue: filterValue,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  };

  const loadDomainChecks = (
    pageResults,
    sizeResults,
    orderBy,
    orderDirection
  ) => {
    DomainRepository.fetchDomainChecks({
      page: pageResults,
      size: sizeResults,
      orderBy: orderBy,
      orderDirection: orderDirection,
      filterProperty: filterBy,
      filterValue: filterValue,
      domainCheckType: domainCheckType,
      dateFrom: dateFrom,
      dateTo: dateTo,
    })
      .then((res) => {
        setDomainChecks(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickChangeOrderBy = (event) => {
    let orderValue = event.currentTarget.value;
    if (orderBy === orderValue) {
      if (orderDirection === "ASC") {
        setOrderDirection("DESC");
      } else {
        setOrderDirection("ASC");
      }
    } else {
      setOrderBy(orderValue);
      setOrderDirection("DESC");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
  };

  const handleChangePageResults = (event, newPage) => {
    setPageResults(newPage);
  };

  const handleChangeRowsPerPageResults = (event) => {
    setSizeResults(parseInt(event.target.value, 10));
  };

  const handleSelectAllClick = (event) => {
    console.log(event.target.checked);
    if (event.target.checked) {
      // add all domain ids to selected domains, if not already added to the list
      let selectedDomainsCopy = [...selectedDomains];
      data.content.forEach((domain) => {
        let domainFound = selectedDomainsCopy.find(
          (selectedDomain) => selectedDomain === domain.id
        );
        if (!domainFound) {
          selectedDomainsCopy.push(domain.id);
        }
      });
      setSelectedDomains(selectedDomainsCopy);
      return;
    }
    // remove all data content ids from selected domains
    let selectedDomainsCopy = [...selectedDomains];
    data.content.forEach((domain) => {
      selectedDomainsCopy = selectedDomainsCopy.filter(
        (selectedDomain) => selectedDomain !== domain.id
      );
    });
    setSelectedDomains(selectedDomainsCopy);
  };

  const handleInitiateDomainChecksForSelectedDomains = () => {
    DomainRepository.handleInitiateDomainChecksForSelectedDomains(
      selectedDomains
    ).then(
      (res) => {
        console.log(res.data);
        dispatch(
          notifyShowSuccessMessage(
            "Успешно инициравте проверки за избраните домени!"
          )
        );
      },
      (err) => {}
    );
  };

  return (
    <React.Fragment>
      {loading ? <LinearProgress color="secondary" /> : ""}
      <Paper className={classes.root}>
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => {
            setTabValue(newValue);
            setPage(0);
            setSize(10);
            setPageResults(0);
            setSizeResults(10);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          centered
        >
          <Tab label="Листа на домени" value={0} />
          <Tab label="Домен проверки" value={1} />
          <Tab label="Нова проверка" value={2} />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <Card>
            <CardHeader color="theme">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  {" "}
                  <Tooltip
                    title={"Креирај нов домен"}
                    aria-label={"Креирај нов домен"}
                  >
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/app/admin/domain_checks/create`}
                    >
                      <Button
                        color="primary"
                        size="small"
                        startIcon={<Icon>post_add</Icon>}
                        variant="contained"
                        aria-label="create"
                      >
                        {"Креирај нов домен"}
                      </Button>
                    </Link>
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ textAlign: "right" }}
                >
                  <Tooltip
                    placement="top"
                    arrow
                    title={"Иницирај проверки за селектираните домени"}
                    aria-label={"Иницирај проверки за селектираните домени"}
                  >
                    <Button
                      color="secondary"
                      size="small"
                      startIcon={<Icon>post_add</Icon>}
                      variant="contained"
                      aria-label="create"
                      disabled={selectedDomains.length === 0}
                      onClick={() =>
                        handleInitiateDomainChecksForSelectedDomains()
                      }
                    >
                      {"Иницирај проверки"}
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardHeader>
            <CardBody>
              <TableContainer>
                <TextField
                  style={{ marginTop: 10 }}
                  id="domainName"
                  label="Домен"
                  variant="outlined"
                  size="small"
                  value={filterData.domainName}
                  onChange={handleFilterDomainChange("domainName")}
                ></TextField>
                <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow className={classes.tableHeadRow}>
                      <TableCell>
                        <FormControlLabel
                          label={selectedDomains.length}
                          control={
                            <Checkbox
                              checked={
                                // check if all domain ids are in the list of selected domains
                                data.content.length > 0 &&
                                data.content.every((domain) =>
                                  selectedDomains.includes(domain.id)
                                )
                              }
                              onChange={handleSelectAllClick}
                              inputProps={{
                                "aria-label": "select all domains",
                              }}
                            />
                          }
                        />
                      </TableCell>
                      <TableCell
                        className={
                          classes.tableCell + " " + classes.tableHeadCell
                        }
                      >
                        <Typography
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"Домен"}
                          <IconButton
                            style={{
                              width: "26px",
                              height: "26px",
                              padding: "0px",
                            }}
                            value={"domainName"}
                            onClick={handleClickChangeOrderBy}
                          >
                            {orderBy === "domainName" ? (
                              orderDirection == "DESC" ? (
                                <KeyboardArrowDown />
                              ) : (
                                <KeyboardArrowUp />
                              )
                            ) : (
                              <UnfoldMore />
                            )}
                          </IconButton>{" "}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={
                          classes.tableCell + " " + classes.tableHeadCell
                        }
                      >
                        <Typography
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"Меил сервер"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={
                          classes.tableCell + " " + classes.tableHeadCell
                        }
                      >
                        <Typography
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"DKIM"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={
                          classes.tableCell + " " + classes.tableHeadCell
                        }
                        style={{ borderRight: "1px solid grey" }}
                      >
                        <Typography
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"Сопственик"}
                        </Typography>
                      </TableCell>

                      <TableCell
                        className={
                          classes.tableCell + " " + classes.tableHeadCell
                        }
                      >
                        <Typography
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"DOMAIN WHOIS"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={
                          classes.tableCell + " " + classes.tableHeadCell
                        }
                      >
                        <Typography
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"MAIL SERVER"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={
                          classes.tableCell + " " + classes.tableHeadCell
                        }
                      >
                        <Typography
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"DNS LOOKUP"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={
                          classes.tableCell + " " + classes.tableHeadCell
                        }
                      >
                        <Typography
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"DOMAIN HEADERS"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={
                          classes.tableCell + " " + classes.tableHeadCell
                        }
                      >
                        <Typography
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"DOMAIN SSL"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={
                          classes.tableCell + " " + classes.tableHeadCell
                        }
                      >
                        <Typography
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"TLS/SSL"}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        <Typography
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {LOCALE.actions}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.content?.map((domain, index) => (
                      <TableRow
                        key={domain.domainName}
                        className={classes.tableBodyRow}
                        style={index % 2 ? { background: "#f0f0f0" } : {}}
                      >
                        <TableCell>
                          <Checkbox
                            checked={selectedDomains.includes(domain.id)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedDomains([
                                  ...selectedDomains,
                                  domain.id,
                                ]);
                              } else {
                                setSelectedDomains(
                                  selectedDomains.filter((d) => d !== domain.id)
                                );
                              }
                            }}
                          />
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          {domain.domainName}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {`${domain.host}:${domain.port}`}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {domain.dkimSelector ?? "/"}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          style={{ borderRight: "1px solid grey" }}
                        >
                          {domain.ownerName ?? "/"}
                        </TableCell>

                        <TableCell
                          className={classes.tableCell}
                          style={{ textAlign: "center" }}
                        >
                          {domain.domainWhoIs ? (
                            <Icon color="primary">check</Icon>
                          ) : (
                            <Icon color="disabled">clear</Icon>
                          )}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          style={{ textAlign: "center" }}
                        >
                          {domain.mailServer ? (
                            <Tooltip
                              title={
                                <>
                                  {"Mail server host: " + domain.host} <br />
                                  {"Server port: " + domain.port}
                                </>
                              }
                            >
                              <Icon color="primary">check</Icon>
                            </Tooltip>
                          ) : (
                            <Icon color="disabled">clear</Icon>
                          )}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          style={{ textAlign: "center" }}
                        >
                          {domain.dnsLookup ? (
                            <Icon color="primary">check</Icon>
                          ) : (
                            <Icon color="disabled">clear</Icon>
                          )}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          style={{ textAlign: "center" }}
                        >
                          {domain.domainHeaders ? (
                            <Icon color="primary">check</Icon>
                          ) : (
                            <Icon color="disabled">clear</Icon>
                          )}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          style={{ textAlign: "center" }}
                        >
                          {domain.domainSSL ? (
                            <Icon color="primary">check</Icon>
                          ) : (
                            <Icon color="disabled">clear</Icon>
                          )}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          style={{ textAlign: "center" }}
                        >
                          {domain.tlsSsl ? (
                            <Icon color="primary">check</Icon>
                          ) : (
                            <Icon color="disabled">clear</Icon>
                          )}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          style={{ textAlign: "right", width: "100px" }}
                        >
                          <Tooltip title={LOCALE.edit} aria-label={LOCALE.edit}>
                            <Link
                              style={{ textDecoration: "none" }}
                              to={`/app/admin/domain_checks/${domain.id}/edit`}
                            >
                              <IconButton
                                size="small"
                                style={{ color: "#F1C40F" }}
                                aria-label="edit"
                              >
                                <Icon>edit</Icon>
                              </IconButton>
                            </Link>
                          </Tooltip>
                          <Tooltip
                            title={LOCALE.delete}
                            aria-label={LOCALE.delete}
                          >
                            <Link
                              style={{ textDecoration: "none" }}
                              to={`/app/admin/domain_checks/${domain.id}/delete`}
                            >
                              <IconButton
                                size="small"
                                color="secondary"
                                aria-label="delete"
                              >
                                <Icon>delete</Icon>
                              </IconButton>
                            </Link>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                        colSpan={15}
                        count={data.totalElements}
                        rowsPerPage={size}
                        page={data.number}
                        labelRowsPerPage={LOCALE.rows_per_page}
                        SelectProps={{
                          inputProps: { "aria-label": LOCALE.rows_per_page },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </CardBody>
          </Card>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Card>
            <CardHeader color="theme"></CardHeader>
            <CardBody>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    name="domainName"
                    onChange={handleFilterDomainChange("domainName")}
                    placeholder={`${
                      LOCALE.filter_by
                    } ${LOCALE.domain.toLowerCase()}`}
                    value={filterData?.domainName ? filterData?.domainName : ""}
                    variant="outlined"
                    size="small"
                    autoFocus={true}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="demo-simple-select-outlined-label">
                      {"Тип на проверка"}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={domainCheckType ? domainCheckType : ""}
                      onChange={(e) => setDomainCheckType(e.target.value)}
                      label={"Тип на проверка"}
                    >
                      <MenuItem value={null}>/</MenuItem>
                      <MenuItem value="DOMAIN_WHOIS">DOMAIN WHOIS</MenuItem>
                      <MenuItem value="MAIL_SERVER">MAIL SERVER</MenuItem>
                      <MenuItem value="DNS_LOOKUP">DNS LOOKUP</MenuItem>
                      <MenuItem value="DOMAIN_HEADERS">DOMAIN HEADERS</MenuItem>
                      <MenuItem value="DOMAIN_SSL">DOMAIN SSL</MenuItem>
                      <MenuItem value="TLS_SSL">TLS / SSL</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={2} style={{ display: "flex" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      variant="inline"
                      label="Датум од: "
                      value={dateFrom ? dateFrom : null}
                      onChange={setDateFrom}
                      size="small"
                      format="dd/MM/yyyy"
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                  {dateFrom && (
                    <IconButton
                      onClick={() => {
                        setDateFrom(null);
                      }}
                    >
                      <Icon>clear</Icon>
                    </IconButton>
                  )}
                </Grid>
                <Grid item xs={12} sm={2} style={{ display: "flex" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      variant="inline"
                      label="Датум до: "
                      value={dateTo ? dateTo : null}
                      onChange={setDateTo}
                      size="small"
                      format="dd/MM/yyyy"
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                  {dateTo && (
                    <IconButton
                      onClick={() => {
                        setDateTo(null);
                      }}
                    >
                      <Icon>clear</Icon>
                    </IconButton>
                  )}
                </Grid>
                <Grid item xs={12} sm={2} style={{ textAlign: "right" }}>
                  <Button
                    onClick={() => {
                      loadDomainChecks(
                        pageResults,
                        sizeResults,
                        orderBy,
                        orderDirection
                      );
                    }}
                    disabled={
                      filterData?.domainName || domainCheckType || dateFrom
                        ? false
                        : true
                    }
                    style={{ marginTop: 5 }}
                    variant="text"
                    size="large"
                    color="primary"
                  >
                    <Icon>search</Icon>
                  </Button>
                </Grid>
              </Grid>
              <TableContainer>
                <Table
                  className={classes.table}
                  size="medium"
                  aria-label="collapsible table"
                >
                  <TableHead>
                    <TableRow className={classes.tableHeadRow}>
                      <TableCell
                        className={
                          classes.tableCell + " " + classes.tableHeadCell
                        }
                      >
                        <Typography
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"Домен"}
                          <IconButton
                            style={{
                              width: "26px",
                              height: "26px",
                              padding: "0px",
                            }}
                            value={"domainName"}
                            onClick={handleClickChangeOrderBy}
                          >
                            {orderBy === "domainName" ? (
                              orderDirection == "DESC" ? (
                                <KeyboardArrowDown />
                              ) : (
                                <KeyboardArrowUp />
                              )
                            ) : (
                              <UnfoldMore />
                            )}
                          </IconButton>{" "}
                        </Typography>
                      </TableCell>

                      <TableCell
                        className={
                          classes.tableCell + " " + classes.tableHeadCell
                        }
                      >
                        <Typography
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"Тип на проверка"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={
                          classes.tableCell + " " + classes.tableHeadCell
                        }
                      >
                        <Typography
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {"Датум"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={
                          classes.tableCell + " " + classes.tableHeadCell
                        }
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {domainChecks &&
                      domainChecks?.content?.map((domainCheck, index) => (
                        <RowDomainCheckResults
                          key={domainCheck?.id}
                          expandComponent={domainCheck}
                          index={index}
                        >
                          <TableCell component="th" scope="row">
                            {domainCheck?.domainChecks?.domain?.domainName}
                          </TableCell>
                          <TableCell>
                            {domainCheck?.domainChecks?.domainCheckType.toString()}
                          </TableCell>
                          <TableCell>
                            {moment(domainCheck?.date).format(
                              "YYYY-MM-DD HH:mm:ss"
                            ) +
                              " " +
                              "(" +
                              moment(domainCheck?.date).fromNow() +
                              ")"}
                          </TableCell>
                        </RowDomainCheckResults>
                      ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={7}
                        count={domainChecks?.totalElements}
                        rowsPerPage={sizeResults}
                        page={domainChecks?.number}
                        labelRowsPerPage={LOCALE.rows_per_page}
                        SelectProps={{
                          inputProps: { "aria-label": LOCALE.rows_per_page },
                          native: true,
                        }}
                        onChangePage={handleChangePageResults}
                        onChangeRowsPerPage={handleChangeRowsPerPageResults}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </CardBody>
          </Card>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <NewDomainCheckAdmin />
        </TabPanel>
      </Paper>
    </React.Fragment>
  );
}
