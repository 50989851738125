import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  TextField,
  CircularProgress,
  IconButton,
  Icon,
  Tab,
  Tabs,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
// @material-ui/styles
import styles from "../../assets/jss/layouts/appStyle";
import { makeStyles } from "@material-ui/core/styles";
import bgImg from "../../assets/img/bg.jpg";
import bgImg2 from "../../assets/img/bg1.jpg";
import productCurvyLines from "../../assets/img/productCurvyLines.png";
import MagicHover from "magic-hover";
import Footer from "../../common/components/Footer";
import DialogComponent from "../../common/components/DialogComponent";

const useStyles = makeStyles(styles);

export default function DkimPage(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState();
  const [openDkimGeneratorDialog, setDkimGeneratorDialog] = useState(false);
  const [openDkimValidatorDialog, setDkimValidatorDialog] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const closeDialog = (type) => {
    if (type === "dkimGenerator") {
      setDkimGeneratorDialog(false);
    } else if (type === "dkimValidator") {
      setDkimValidatorDialog(false);
    }
  };

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} />}
      <Grid
        id="start"
        container
        style={{
          backgroundImage:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)),url(" +
            bgImg +
            ")",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <ScrollLink
          activeClass="active"
          to="start"
          spy={true}
          smooth={true}
          duration={500}
        >
          <IconButton
            style={{
              position: "fixed",
              bottom: "0",
              right: "0",
              margin: "15px",
            }}
          >
            <ArrowUpwardIcon fontSize="large"></ArrowUpwardIcon>
          </IconButton>
        </ScrollLink>
        <Grid item sm={12} xs={12}>
          <IconButton
            onClick={() => setRedirectTo("/")}
            style={{ color: "white", padding: "5vh" }}
          >
            <Icon>arrow_back_ios</Icon>
          </IconButton>
        </Grid>
        <Grid item sm={3} xs={false}></Grid>
        <Grid item sm={6} xs={12} style={{ textAlign: "center" }}>
          <Typography
            variant="h1"
            style={{
              marginTop: "3vh",
              color: "white",
              opacity: "70%",
            }}
          >
            DKIM
          </Typography>{" "}
          <Typography
            variant="h4"
            style={{
              marginBottom: "10vh",
              textAlign: "center",
              color: "white",
              opacity: "70%",
            }}
          >
            DomainKeys Identified Mail
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            style={{
              color: "white",
              marginBottom: "12vh",
              marginRight: "5px",
            }}
            onClick={() => setDkimGeneratorDialog(true)}
          >
            DKIM Generator
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            style={{
              color: "white",
              marginBottom: "12vh",
              marginLeft: "5px",
            }}
            onClick={() => setDkimValidatorDialog(true)}
          >
            DKIM Validator
          </Button>
          {openDkimGeneratorDialog && (
            <DialogComponent
              dialogTitle={"DKIM Generator"}
              closeDialog={() => closeDialog("dkimGenerator")}
              contentText="contentText"
              dialogType="dkimGenerator"
            ></DialogComponent>
          )}
          {openDkimValidatorDialog && (
            <DialogComponent
              dialogTitle={"DKIM Validator"}
              closeDialog={() => closeDialog("dkimValidator")}
              contentText="contentText"
              dialogType="dkimValidator"
            ></DialogComponent>
          )}
        </Grid>
        <Grid item sm={3} xs={false}></Grid>
      </Grid>
      <Grid
        container
        style={{
          backgroundImage: "url(" + productCurvyLines + ")",
          backgroundSize: "auto",
          paddingBottom: "5vh",
          textAlign: "initial",
        }}
      >
        <Grid item xs={2} sm={2}></Grid>
        <Grid
          item
          xs={12}
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
          sm={8}
        >
          <br></br>
          <Typography variant="h4">Содржина</Typography>
          <br></br>
          <ol className="nav-items">
            <li className="nav-item">
              <ScrollLink
                activeClass="active"
                to="whatIsRequiredForDkimSignature"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Typography variant="subtitle1">
                  Што е потребно за ДКИМ потпис?
                </Typography>
              </ScrollLink>
            </li>
            <ol>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  to="chooseDomain"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <Typography variant="subtitle1">Изберете домен</Typography>
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  to="pickDkimSelector"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <Typography variant="subtitle1">
                    Изберете DKIM selector
                  </Typography>
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  to="choosePublicAndPrivateKeyGenerator"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <Typography variant="subtitle1">
                    Изберете генератор за јавни и приватни клучеви
                  </Typography>
                </ScrollLink>
              </li>
            </ol>
            <li className="nav-item">
              <ScrollLink
                activeClass="active"
                to="howDoICreateDkimRecordForMyDomain"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Typography variant="subtitle1">
                  Како да креирам DKIM запис за својот домен?
                </Typography>
              </ScrollLink>
            </li>
            <ol>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  to="generatePublicAndPrivateKeys"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <Typography variant="subtitle1">
                    Генерирајте јавни и приватни клучеви
                  </Typography>
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  to="configureTheDnsServerWithThePublicKey"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <Typography variant="subtitle1">
                    Конфигурирајте го DNS серверот со јавниот клуч
                  </Typography>
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  to="saveThePrivateKeyToYourMta"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <Typography variant="subtitle1">
                    Зачувајте го приватниот клуч
                  </Typography>
                </ScrollLink>
              </li>
            </ol>
            <li className="nav-item">
              <ScrollLink
                activeClass="active"
                to="settingUpDkimRecorsAtPopularEmailServiceProviders"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Typography variant="subtitle1">
                  Поставување записи на DKIM кај популарни даватели на услуги за
                  е-пошта
                </Typography>
              </ScrollLink>
            </li>
            <ol>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  to="gmail"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <Typography variant="subtitle1">Gmail</Typography>
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  activeClass="active"
                  to="office365"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <Typography variant="subtitle1">Office 365</Typography>
                </ScrollLink>
              </li>
            </ol>
          </ol>
          <br></br>
          <Typography variant="h4" id="whatIsRequiredForDkimSignature">
            Што е потребно за ДКИМ потпис?
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Domain Keys Identified Mail (DKIM) е дигитален потпис додаден на
            излезните пораки што се испраќаат по е-пошта. Изгледа како случајна
            низа на карактери кои главно се нечитливи за корисникот. Корисниците
            не го гледаат овој потпис, освен ако не го отворат изворниот код на
            е-поштата. DKIM е наменет за серверот за е-пошта на примателот, кој
            го автентицира испраќачот врз основа на него и, ако е сè во ред, ја
            пренесува е-поштата до поштенското сандаче. Еве пример за DKIM
            запис:<br></br>
            <br></br>
            <span
              style={{ backgroundColor: "#f9f97275", wordWrap: "break-word" }}
            >
              DKIM-Signature: v=1; a=rsa-sha256; c=relaxed/relaxed;
              d=mojdomen.mk; s=default; t=1572282571;
              bh=NFzBvJ/pEmf+yUHDd/Y7dYNH9pE+Bx6o95KcxhwFL78=;
              h=From:To:Subject:From;
              b=QwgINKqwcBu3GbeWm2Be81qXks6Pq9yMmDZl9C6mT8moXVBeokpEmDN+JHjsjyhui
              kjhuihsj jklji
              kljkljkldjkljkldjkljdjj/IUePV+goQdEJ2+sDQ1fHlVjyyJCRwCiFiZpBIjhTBNN0vrgNJZ/gSLLOvq6k3s=
            </span>
            <br></br>
            <br></br>
            DKIM записот се состои од следниве ознаки:
            <ul>
              <li>
                <Typography variant="subtitle1">
                  v=1 - верзија (секогаш е еднаква на 1)
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  a= - алгоритам за потпишување што се користи за создавање на
                  DKIM запис
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  c= - алгоритам за за заглавието и содржината на пораката
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  d= - домен за потпишување со DKIM
                </Typography>
              </li>

              <li>
                <Typography variant="subtitle1">s= - DKIM selector</Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  t= - временски печат кога е потпишана пораката
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  bh= - хаширана содржина на маилот
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  h= - список на заглавија
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  b= - дигитален потпис
                </Typography>
              </li>
            </ul>
            <br></br>
            За да го креирате DKIM потписот, доволно е да се наведат само две од
            овие ознаки: овластен домен (d=) и selector (s=).
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" id="chooseDomain">
            Изберете домен
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            При валидирање на DKIM потписите, серверот за е-пошта на примателот
            проверува дали доменот вклучен во потписот ( d=) одговара на доменот
            вклучен во полето „ From:“ на пораката. <br></br>
            <br></br> Забелешка: Ако користите различни домени за испраќање
            е-пошта, треба да имате одделни DKIM потписи за секој домен.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" id="pickDkimSelector">
            Изберете DKIM селектор
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Селектор или префикс на селектор е име што треба да го наведете за
            да го креирате DKIM клучот. За време на процесот на валидација,
            серверот извршува DNS пребарување според комбинацијата на
            авторизираниот домен ( d=) и селектор ( s=). Ова е потребно за да се
            преземе јавниот клуч кој е објавен на DNS серверот.<br></br>
            <br></br>Секој селектор е поврзан со посебен приватен клуч. Ако
            испраќате различни типови на е-пошта (маркетинг, плаќања и сл.) од
            ист домен, подобро е да користите одделни селектори. Треба да
            користите различни селектори за да ги генерирате клучевите. Изберете
            кое било име за вашиот селектор.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" id="choosePublicAndPrivateKeyGenerator">
            Изберете генератор на јавни и приватни клучеви
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Доменот и селекторот се влезни податоци што се користат за
            генерирање на пар од клучеви, јавен и приватен клуч. Јавниот клуч се
            користи во TXT записот на DNS серверот, а приватниот клуч се користи
            за испраќање на пораките на серверот кој има улога на т.н. Mail
            Transport Agent - MTA.
            <br></br>
            <br></br>
            МТА го користи приватниот клуч за генерирање на hash headers ( h=) и
            body ( bh=) на излезната порака. Приватниот клуч се чува на серверот
            и никогаш не се споделува или брише.
            <br></br>
            <br></br>
            Кога пристигнува е-пошта со DKIM потпис, серверот за примање пошта
            (доколку има улога за верификација на DKIM Потписи) прави DNS
            пребарување за да го добие јавниот клуч. Серверот-примател го
            користи клучот за креирање на сопствени хашови и потоа ги споредува
            со примените. Ако се совпаднат, пораката се пропушта.
            <br></br>
            <br></br>
            <strong>
              <div style={{ textAlign: "center", fontSize: "1.9em" }}>
                Овие клучеви можете да ги генерирате со нашата алатка DKIM
                generator достапна на оваа страница
              </div>
            </strong>
            <Grid xs={12} container style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                style={{
                  marginTop: "10px",
                  marginBottom: "50px",
                  padding: "30px 50px",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
                onClick={() => setDkimGeneratorDialog(true)}
              >
                {" "}
                DKIM Generator
              </Button>
            </Grid>
          </Typography>
          <img
            alt=""
            src={require("../../assets/img/dkim.png")}
            width="100%"
            style={{ marginTop: "3vh" }}
          ></img>
          <br></br>
          <br></br>
          <Typography variant="h4" id="howDoICreateDkimRecordForMyDomain">
            Како да креирам DKIM запис за мојот домен?
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Како пример, ќе ги користиме доменот ' example.com' и селекторот '
            test-mail'.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" id="generatePublicAndPrivateKeys">
            Генерирајте јавни и приватни клучеви
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Генерирајте го вашиот пар јавни и приватни клучеви користејќи ја
            алатката достапна на оваа страница
            <br></br>
            <img
              alt=""
              src={require("../../assets/img/tutorial1.png")}
              width="100%"
              style={{ marginTop: "3vh" }}
            ></img>
            <br></br>
            <br></br>
            По кликнувањето на Генерирај клучени, се добиваат два клуча: јавен и
            приватен.
            <br></br>
            <img
              alt=""
              src={require("../../assets/img/tutorial2.png")}
              width="100%"
              style={{ marginTop: "3vh" }}
            ></img>
          </Typography>
          <br></br>
          <Typography variant="h4" id="configureTheDnsServerWithThePublicKey">
            Конфигурирајте го DNS серверот со јавниот клуч
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Креирајте TXT запис за DKIM користејќи го доменот, селекторот и
            јавниот клуч. Записот ќе го носи името на авторизираниот домен во
            прилог со префиксот на селекторот, како што следува:
            <br></br>
            <br></br>
            <span style={{ backgroundColor: "#f9f97275" }}>
              test-mail._domainkey.example.com
            </span>
            <br></br>
            <br></br>
            Внесувањето на DKIM започнува со ознаката „k= “. Се дефинира за „Тип
            на клуч“. Серверите за испраќање и примање е-пошта мора да го
            поддржуваат RSA типот на клуч:
            <br></br>
            <br></br>
            <span
              style={{ backgroundColor: "#f9f97275", wordWrap: "break-word" }}
            >
              test-mail._domainkey.example.com IN TXT “k=rsa\;
              p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCehqKMB6znGXo/pC83mGObm8OWo4daBYBb9wqqDaflz7Mf9KW1oaUm9j7hQq7af7jh+DSw0tXWr4HbJrI50DW/QVHqYKlPX3hvYUohBxg//T0u0rK3OSJss3OrpkoRqd150ynYxwwLymsjIwODT7Gf9WZPcL86rdboSRm/ost4mwIDAQAB”
            </span>
            <br></br>
            <br></br>
            Додадете го овој DKIM запис во вашиот (доменски) DNS. Освежувањето и
            пропагацијата на оваа информација може да потрае до 24-48 часа.
          </Typography>
          <br></br>
          {/* <br></br>
          <Typography variant="h4">
            Како да знам кој обезбедувач на DNS хостинг го имам?
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Услугата за DNS хостирање најчесто се обезбедува од регистраторот на
            името на доменот, но тоа не е облигаторно. Можете да дознаете кој
            DNS провајдер го имате со помош на наменска алатка преку Интернет.
            На пример,{" "}
            <a target="_blank" href="https://mxtoolbox.com/NetworkTools.aspx">
              <Typography variant="subtitle1" style={{ display: "inline" }}>
                MXToolbox
              </Typography>
            </a>{" "}
            и неговиот SuperTool. Ајде да провериме DNS хостинг провајдер за{" "}
            <a target="_blank" href="https://mailtrap.io/">
              <Typography variant="subtitle1" style={{ display: "inline" }}>
                Mailtrap.io
              </Typography>
            </a>
            .<br></br>
            <img
              alt=""
              src={require("../../assets/img/tutorial3.png")}
              width="100%"
              style={{ marginTop: "3vh" }}
            ></img>
            <br></br>
            <br></br>И добивме како одговор:
            <br></br>
            <img
              alt=""
              src={require("../../assets/img/tutorial4.png")}
              width="100%"
              style={{ marginTop: "3vh" }}
            ></img>
          </Typography>
          <br></br> */}
          <br></br>
          <Typography variant="h4" id="saveThePrivateKeyToYourMta">
            Зачувајте го приватниот клуч на МТА
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Секој агент за пренос на е-пошта (Mail Transport Agent) е различен,
            а исто така се разликуваат и начините за поставување на DKIM на нив.
            На пример, на Exim, треба да креирате нова датотека (фајл) што го
            содржи приватниот клуч и да го ажурирате главниот конфигурациски
            фајл (датотека за конфигурација). За Exchange, потребно е да
            инсталирате додаток и целата активност за генерирање и инсталација
            на клучот се реалзира преку тој софтвер. Сега можете да испратите
            тест е-пошта од вашиот домен.
          </Typography>
          <br></br>
          <br></br>
          <Typography
            variant="h4"
            id="settingUpDkimRecorsAtPopularEmailServiceProviders"
          >
            Поставување DKIM записи кај популарнитe даватели на услуги за
            испраќање и прием на електронска пошта
          </Typography>
          <br></br>
          <Typography variant="h5" id="gmail">
            Gmail
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Корисниците на Gmail чии домени се обезбедени од G Suite (хостирани
            домени) треба да вклучат само потпишување со DKIM. Сите останати
            активности (генерирање клучеви за доменот и додавање DNS доменски
            запис) ги прави{" "}
            <a
              target="_blank"
              href="https://support.google.com/a/answer/173535"
            >
              <Typography variant="subtitle1" style={{ display: "inline" }}>
                Gmail
              </Typography>
            </a>
            .
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h5" id="office365">
            Office 365
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Во овој{" "}
            <a
              target="_blank"
              href="https://docs.microsoft.com/en-us/microsoft-365/security/office-365-security/use-dkim-to-validate-outbound-email?view=o365-worldwide"
            >
              <Typography variant="subtitle1" style={{ display: "inline" }}>
                официјален водич на Microsoft
              </Typography>
            </a>
            , можете да видите како се креира DKIM запис, како да го
            конфигурирате DKIM за повеќе од еден домен, како да ги надградите
            1024-bit клучеви во 2048-bit DKIM клучеви за шифрирање и други
            корисни совети.
          </Typography>
          <br></br>
          <br></br>
        </Grid>
        <Grid item xs={2} sm={2}></Grid>
      </Grid>

      <Footer></Footer>
    </React.Fragment>
  );
}
