import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  IconButton,
  Icon,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  LinearProgress,
  Grid,
  TextField,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
// @material-ui/styles
import styles from "../../../assets/jss/layouts/appStyle";
import { makeStyles } from "@material-ui/core/styles";
// core components
import { UsersRepository } from "./UsersRepository";
import { LOCALE } from "../../../properties/Locale";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
  triggerRerender,
} from "../../../common/actions/CommonActions";
import { useDispatch } from "react-redux";
import {
  validateEditUserForm,
  validateEditUserFormClient,
  validatePassword,
} from "./UserValidations";

const useStyles = makeStyles(styles);

export default function UserEditComponent(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [userTypes, setUserTypes] = useState();
  const [formErrors, setFormErrors] = useState();
  const dispatch = useDispatch();
  const [redirectTo, setRedirectTo] = useState();

  useEffect(() => {
    loadUser(props.match.params.userId);
  }, []);

  useEffect(() => {
    UsersRepository.getUserTypes().then(
      (res) => {
        setUserTypes(res.data);
      },
      (err) => {}
    );
  }, []);

  const loadUser = (id) => {
    showLoadingBar();
    UsersRepository.fetchUser(id).then((res) => {
      let tmpUser = res.data;
      tmpUser.password = "";
      tmpUser.imapPassword = "";
      setUser(tmpUser);
      hideLoadingBar();
    });
  };

  const showLoadingBar = () => {
    setLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
  };
  const handleChange = (name, value) => {
    let tmpUser = { ...user };
    tmpUser[name] = value;
    setUser(tmpUser);
  };

  const handleClose = () => {};

  const updateUser = () => {
    showLoadingBar();
    var validFormData = true;
    if (user.type === "ADMINISTRATOR") {
      if (!validateEditUserForm.isValidSync(user)) {
        validFormData = false;
      }
    } else {
      if (!validateEditUserFormClient.isValidSync(user)) {
        validFormData = false;
      }
    }
    if (validFormData) {
      UsersRepository.updateUser(user).then(
        (res) => {
          hideLoadingBar();
          dispatch(triggerRerender());
          dispatch(
            notifyShowSuccessMessage(LOCALE.user_update_success_message)
          );
          setRedirectTo("/app/admin/users");
        },
        (err) => {
          hideLoadingBar();
          if (err.response?.data) {
            dispatch(notifyShowErrorMessage(err.response?.data));
          } else {
            dispatch(notifyShowErrorMessage(err.message));
          }
        }
      );
    } else {
      if (user.type === "ADMINISTRATOR") {
        var validationErrors = {};
        (async () => {
          await validateEditUserForm
            .validate(user, { abortEarly: false })
            .catch((err) => {
              err.inner.forEach((validationError) => {
                validationErrors[validationError.path] = {};
                validationErrors[validationError.path] =
                  validationError.message;
              });
            });
          hideLoadingBar();
          setFormErrors(validationErrors);
        })();
      } else {
        var validationErrors = {};
        (async () => {
          await validateEditUserFormClient
            .validate(user, { abortEarly: false })
            .catch((err) => {
              err.inner.forEach((validationError) => {
                validationErrors[validationError.path] = {};
                validationErrors[validationError.path] =
                  validationError.message;
              });
            });
          hideLoadingBar();
          setFormErrors(validationErrors);
        })();
      }
    }
  };

  const resetPassword = () => {
    var validFormData = true;
    if (!validatePassword.isValidSync({ password: user?.password })) {
      validFormData = false;
    }
    if (validFormData) {
      UsersRepository.resetPassword(user)
        .then((res) => {
          hideLoadingBar();
          dispatch(triggerRerender());
          setFormErrors({});
          dispatch(
            notifyShowSuccessMessage(LOCALE.user_update_success_message)
          );
          setRedirectTo("/app/admin/users");
        })
        .catch((err) => {
          hideLoadingBar();
          setFormErrors({});
          dispatch(notifyShowErrorMessage(err.message));
        });
    } else {
      var validationErrors = {};
      (async () => {
        await validatePassword
          .validate({ password: user?.password }, { abortEarly: false })
          .catch((err) => {
            err.inner.forEach((validationError) => {
              validationErrors[validationError.path] = {};
              validationErrors[validationError.path] = validationError.message;
            });
          });
        hideLoadingBar();
        setFormErrors(validationErrors);
      })();
    }
  };

  return (
    <div>
      {redirectTo && <Redirect to={redirectTo} />}
      <Card>
        <Dialog
          open={true}
          onClose={handleClose}
          maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              overflowY: "unset",
            },
          }}
        >
          <CardHeader color="theme">
            <h4 className={classes.cardTitleWhite}>
              {`${LOCALE.user_edit}${
                user?.username ? ": " + user?.username : ""
              }`}

              <Link to={`/app/admin/users/`} style={{ textDecoration: "none" }}>
                <IconButton
                  style={{ float: "right", color: "white" }}
                  aria-label="close"
                >
                  <Icon>close</Icon>
                </IconButton>
              </Link>
            </h4>
          </CardHeader>
          <CardBody>
            <DialogContent style={{ minHeight: "500px" }}>
              {loading ? <LinearProgress color="secondary" /> : ""}
              <div>
                <Grid container spacing={1}>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      id="type"
                      label={LOCALE.role}
                      name="type"
                      fullWidth={true}
                      value={user?.type}
                      size="small"
                      margin="dense"
                      variant="outlined"
                      disabled
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="name"
                      label={LOCALE.name}
                      name="name"
                      fullWidth={true}
                      value={user?.name}
                      onChange={(e) => handleChange("name", e.target.value)}
                      size="small"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      error={formErrors?.name ? true : false}
                      helperText={formErrors?.name}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="email"
                      label={LOCALE.email}
                      name="email"
                      fullWidth={true}
                      value={user?.email}
                      onChange={(e) => handleChange("email", e.target.value)}
                      size="small"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      error={formErrors?.email ? true : false}
                      helperText={formErrors?.email}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="username"
                      label={LOCALE.username}
                      name="username"
                      fullWidth={true}
                      value={user?.username}
                      onChange={(e) => handleChange("username", e.target.value)}
                      size="small"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      error={formErrors?.username ? true : false}
                      helperText={formErrors?.username}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormControlLabel
                      style={{ marginTop: "5px" }}
                      control={
                        <Checkbox
                          checked={user?.isEnabled ? true : false}
                          onChange={(e) =>
                            handleChange("isEnabled", e.target.checked)
                          }
                        />
                      }
                      label="Активен"
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormControlLabel
                      style={{ marginTop: "5px" }}
                      control={
                        <Checkbox
                          checked={user?.mfaEnabled ? true : false}
                          onChange={(e) =>
                            handleChange("mfaEnabled", e.target.checked)
                          }
                        />
                      }
                      label="Дво-факторска автентикација"
                    />
                  </Grid>
                  <Grid item sm={8} xs={12}>
                    <TextField
                      id="password"
                      type="password"
                      label={LOCALE.new_password}
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      name="password"
                      fullWidth={true}
                      value={user?.password}
                      onChange={(e) => handleChange("password", e.target.value)}
                      size="small"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      error={formErrors?.password ? true : false}
                      helperText={formErrors?.password}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      onClick={() => resetPassword()}
                      style={{ marginTop: "10px" }}
                    >
                      {LOCALE.change_password}
                    </Button>
                  </Grid>
                  {user?.type === "CLIENT" && (
                    <Grid item sm={12} xs={12}>
                      <TextField
                        id="domain"
                        label={LOCALE.domain}
                        fullWidth={true}
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="domain"
                        fullWidth={true}
                        value={user?.domain}
                        onChange={(e) => handleChange("domain", e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        error={formErrors?.domain ? true : false}
                        helperText={formErrors?.domain}
                      />
                    </Grid>
                  )}
                  {/* {user?.type === "CLIENT" && (
                    <React.Fragment>
                      {" "}
                      <Grid item sm={12} xs={12}>
                        <Typography
                          style={{ textAlign: "center" }}
                          variant="subtitle1"
                        >
                          Податоци за IMAP серверот на клиентот
                        </Typography>
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <TextField
                          id="imapHost"
                          label={LOCALE.login_imapHost}
                          name="imapHost"
                          fullWidth={true}
                          value={user?.imapHost}
                          onChange={(e) =>
                            handleChange("imapHost", e.target.value)
                          }
                          size="small"
                          margin="dense"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          error={formErrors?.imapHost ? true : false}
                          helperText={formErrors?.imapHost}
                        />
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <TextField
                          id="imapEmail"
                          label={LOCALE.email}
                          name="imapEmail"
                          fullWidth={true}
                          value={user?.imapEmail}
                          onChange={(e) =>
                            handleChange("imapEmail", e.target.value)
                          }
                          size="small"
                          margin="dense"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          error={formErrors?.imapEmail ? true : false}
                          helperText={formErrors?.imapEmail}
                        />
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <TextField
                          id="imapPassword"
                          type="password"
                          label={LOCALE.password}
                          name="imapPassword"
                          fullWidth={true}
                          value={user?.imapPassword}
                          onChange={(e) =>
                            handleChange("imapPassword", e.target.value)
                          }
                          size="small"
                          margin="dense"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          error={formErrors?.imapPassword ? true : false}
                          helperText={formErrors?.imapPassword}
                        />
                      </Grid>
                    </React.Fragment>
                  )} */}
                  <Grid item sm={12} xs={12}>
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => updateUser()}
                      fullWidth
                    >
                      {LOCALE.save}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
          </CardBody>
        </Dialog>
      </Card>
    </div>
  );
}
