import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";

export const validateUserProfile = yup.object().shape({
  name: yup.string().required(LOCALE.login_firstname + LOCALE.required_field),
  surname: yup.string().required(LOCALE.login_lastname + LOCALE.required_field),
  username: yup
    .string()
    .matches(/^[A-Za-z_-][A-Za-z0-9_-]{5,}$/, {
      message:
        LOCALE.incorrect_format +
        " (минимум 6 карактери, дозволени се мали и големи букви, цифри и -_)",
    })
    .required(LOCALE.username + LOCALE.required_field),
  email: yup
    .string()
    .required(LOCALE.login_email + LOCALE.required_field)
    .email(LOCALE.login_email + LOCALE.required_field),
});

export const validateChangePasswordUserProfile = yup.object().shape({
  oldPassword: yup
    .string()
    .required(LOCALE.login_password + LOCALE.required_field)
    .matches(
      /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      "Мора да содржи минимум 8 карактери, барем една голема буква, барем една мала буква, една цифра и еден специјален знак"
    ),
  password: yup
    .string()
    .required(LOCALE.login_password + LOCALE.required_field)
    .matches(
      /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      "Мора да содржи минимум 8 карактери, барем една голема буква, барем една мала буква, една цифра и еден специјален знак"
    ),
  confirmPassword: yup
    .string()
    .required(LOCALE.login_password + LOCALE.required_field)
    .matches(
      /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      "Мора да содржи минимум 8 карактери, барем една голема буква, барем една мала буква, една цифра и еден специјален знак"
    )
    .oneOf([yup.ref("password"), null], LOCALE.password_doesnt_match),
});

export const validateOldPassword = yup.object().shape({
  oldPassword: yup
    .string()
    .required(LOCALE.login_password + LOCALE.required_field)
    .matches(
      /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      "Мора да содржи минимум 8 карактери, барем една голема буква, барем една мала буква, една цифра и еден специјален знак"
    ),
});
