import React, { useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import {
  Button,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  DialogContentText,
  Tab,
  Typography,
  TextField,
  Select,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  Grid,
  Tooltip,
  InputAdornment,
  Switch,
  CircularProgress,
  LinearProgress,
  Hidden,
  Divider,
} from "@material-ui/core";
import { LOCALE } from "../../properties/Locale";
import { SETTINGS } from "../../properties/ApiProps";
import { MainRepositroy } from "../../modules/public/MainRepositroy";
import { Alert, AlertTitle } from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";
import DnsIcon from "@material-ui/icons/Dns";
import PublicIcon from "@material-ui/icons/Public";
import EmailIcon from "@material-ui/icons/Email";
import { Facebook } from "@material-ui/icons";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
  triggerRerender,
} from "../actions/CommonActions";
import { useDispatch } from "react-redux";
import {
  spfGenerator,
  spfValidator,
  dkimGenerator,
  dkimValidator,
  dmarcGenerator,
  dmarcValidator,
} from "./DialogComponentValidations";
import keypair from "keypair";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3";
import { CopyToClipboard } from "react-copy-to-clipboard";

function getSteps() {
  return [
    "Enter the domain",
    "Choose your Policy",
    "Provide your Aggregate reports address",
    "(Optional) Provide your Failure Reporting address",
    "Choose Identifier Alignment",
    "(Optional) Choose Subdomain Policy",
    "(Optional) Choose DMARC Policy percentage",
  ];
}

export default function DialogComponent(props) {
  const handleClose = () => {
    props.closeDialog();
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleOpenAlert = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  const handleOpenAlert2 = () => {
    setOpen2(true);
    setTimeout(() => {
      setOpen2(false);
    }, 2000);
  };

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState("");
  const [domain, setDomain] = useState("");
  const [param1, setParam1] = useState(0);
  const [param2, setParam2] = useState(0);
  const [param3, setParam3] = useState("");
  const [param4, setParam4] = useState("");
  const [param5, setParam5] = useState("");
  const [param6, setParam6] = useState("");
  const [param7, setParam7] = useState("");

  const [dmarcParam1, setDmarcParam1] = useState(0);
  const [dmarcParam1Policy, setDmarcParam1Policy] = useState(100);
  const [dmarcParam2, setDmarcParam2] = useState(false);
  const [dmarcParam3, setDmarcParam3] = useState(0);
  const [dmarcParam4, setDmarcParam4] = useState("");
  const [dmarcParam5, setDmarcParam5] = useState("");
  const [dmarcParam6, setDmarcParam6] = useState(0);
  const [dmarcParam7, setDmarcParam7] = useState(0);
  const [dmarcParam8, setDmarcParam8] = useState({
    checkedA: false,
    checkedB: false,
    checkedF: false,
    checkedG: false,
  });
  const [loading, setLoading] = useState(false);

  const [dkimDomain, setDkimDomain] = useState("");
  const [dkimSelector, setDkimSelector] = useState("");
  const [dkimCreate, setDkimCreate] = useState(false);
  const [dkimPrivateKey, setDkimPrivateKey] = useState("");
  const [dkimRecord, setDkimRecord] = useState("");

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const [spfCheckResult, setSpfCheckResult] = React.useState();
  const [spfCheckResultType, setSpfCheckResultType] = React.useState("");

  const [dkimCheckResult, setDkimCheckResult] = React.useState();
  const [dkimCheckResultType, setDkimCheckResultType] = React.useState("");
  const [selector, setSelector] = React.useState("");

  const [dmarcCheckResult, setDmarcCheckResult] = React.useState();
  const [dmarcCheckResultType, setDmarcCheckResultType] = React.useState("");

  const [btnLoading, setBtnLoading] = React.useState(false);

  const [recaptcha, setRecaptcha] = React.useState();
  const [recaptchaToken, setRecaptchaToken] = React.useState();

  useEffect(() => {
    loadReCaptcha("6LdkJeoZAAAAAIleGu7k2cJYmWQ45dRFI3YJ7gPz", verifyCallback);
  }, []);

  const verifyCallback = (recaptchaToken) => {
    setRecaptchaToken(recaptchaToken);
  };

  const updateToken = () => {
    recaptcha.execute();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const param1Change = (event) => {
    setParam1(parseInt(event.target.value));
  };

  const param2Change = (event) => {
    setParam2(parseInt(event.target.value));
  };

  const param3Change = (event) => {
    if (event.target.value === 0) {
      setParam3("");
    } else {
      setParam3(" ptr");
    }
  };

  const param4Change = (event) => {
    let val = event.target.value.split(" ");
    if (event.target.value.length === 0) {
      setParam4("");
    } else {
      setParam4(val);
    }
  };

  const param5Change = (event) => {
    let val = event.target.value.split(" ");
    if (event.target.value.length === 0) {
      setParam5("");
    } else {
      setParam5(val);
    }
  };

  const param6Change = (event) => {
    let val = event.target.value.split(" ");
    if (event.target.value.length === 0) {
      setParam6("");
    } else {
      setParam6(val);
    }
  };

  const param7Change = (event) => {
    if (event.target.value === 0) {
      setParam7("");
    } else if (event.target.value === 1) {
      setParam7(" -all");
    } else if (event.target.value === 2) {
      setParam7(" ~all");
    } else if (event.target.value === 3) {
      setParam7(" ?all");
    }
  };

  const dmarcParam1Change = (event) => {
    setDmarcParam1(parseInt(event.target.value));
    if (parseInt(event.target.value) === 0) {
      setDmarcParam1Policy(100);
    }
  };

  const dmarcParam2Change = (event) => {
    setDmarcParam2(event.target.checked);
  };

  const dmarcParam3Change = (event) => {
    setDmarcParam3(event.target.value);
  };

  const dmarcParam4Change = (event) => {
    setDmarcParam4(event.target.value);
  };

  const dmarcParam5Change = (event) => {
    setDmarcParam5(event.target.value);
  };

  const dmarcParam6Change = (event) => {
    setDmarcParam6(parseInt(event.target.value));
  };

  const dmarcParam7Change = (event) => {
    setDmarcParam7(parseInt(event.target.value));
  };

  const dmarcParam8Change = (event) => {
    setDmarcParam8({
      ...dmarcParam8,
      [event.target.name]: event.target.checked,
    });
  };

  const showLoadingBar = () => {
    setLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
  };

  const [formErrors, setFormErrors] = useState();

  const startGenerateClick = () => {
    setBtnLoading(true);
    showLoadingBar();
    var validFormData = true;
    if (
      !dkimGenerator.isValidSync({
        dkimDomain: dkimDomain,
        dkimSelector: dkimSelector,
      })
    ) {
      validFormData = false;
    }
    if (validFormData) {
      // var keypair = require("keypair");
      // var pair = keypair();

      // var privateLines = pair.private.split("\n");
      // privateLines.splice(0, 1);
      // privateLines.splice(privateLines.length - 2, privateLines.length);
      // var newPrivateKey = privateLines.join("\n");

      // var publicLines = pair.public.split("\n");
      // publicLines.splice(0, 1);
      // publicLines.splice(publicLines.length - 2, publicLines.length);
      // var newPublicKey = publicLines.join("\n");

      // setDkimPrivateKey(newPrivateKey);
      // setDkimRecord(`v=DKIM1;p=${newPublicKey}`);

      // setDkimCreate(true);

      keyPairGenerate();
      hideLoadingBar();
      setBtnLoading(false);
      setFormErrors({});
    } else {
      var validationErrors = {};
      (async () => {
        await dkimGenerator
          .validate(
            {
              dkimDomain: dkimDomain,
              dkimSelector: dkimSelector,
            },
            { abortEarly: false }
          )
          .catch((err) => {
            err.inner.forEach((validationError) => {
              validationErrors[validationError.path] = {};
              validationErrors[validationError.path] = validationError.message;
            });
          });
        hideLoadingBar();
        setBtnLoading(false);
        setFormErrors(validationErrors);
      })();
    }
  };

  const keyPairGenerate = async () => {
    var pair = keypair();

    var privateLines = pair.private.split("\n");
    privateLines.splice(0, 1);
    privateLines.splice(privateLines.length - 2, privateLines.length);
    var newPrivateKey = privateLines.join("\n");

    var publicLines = pair.public.split("\n");
    publicLines.splice(0, 1);
    publicLines.splice(publicLines.length - 2, publicLines.length);
    var newPublicKey = publicLines.join("\n");

    setDkimPrivateKey(newPrivateKey);
    setDkimRecord(`v=DKIM1;p=${newPublicKey}`);

    setDkimCreate(true);
  };

  const checkSpf = () => {
    updateToken();
    setBtnLoading(true);
    showLoadingBar();
    var validFormData = true;
    if (
      !spfValidator.isValidSync({
        domainSpf: domain,
      })
    ) {
      validFormData = false;
    }
    if (validFormData) {
      MainRepositroy.checkSpfRecord(domain, recaptchaToken).then(
        (res) => {
          if (res.data[0]) {
            setSpfCheckResult(res.data[0]);
            setSpfCheckResultType("success");
          } else {
            setSpfCheckResult(
              "Не е пронајден SPF запис за дадениот домен: " + domain
            );
            setSpfCheckResultType("error");
          }
          setBtnLoading(false);
          hideLoadingBar();
          setFormErrors({});
        },
        (err) => {
          setSpfCheckResult(
            "Не е пронајден SPF запис за дадениот домен: " + domain
          );
          setSpfCheckResultType("error");
          setBtnLoading(false);
          hideLoadingBar();
          setFormErrors({});
        }
      );
    } else {
      var validationErrors = {};
      (async () => {
        await spfValidator
          .validate(
            {
              domainSpf: domain,
            },
            { abortEarly: false }
          )
          .catch((err) => {
            err.inner.forEach((validationError) => {
              validationErrors[validationError.path] = {};
              validationErrors[validationError.path] = validationError.message;
            });
          });
        setBtnLoading(false);
        hideLoadingBar();
        setFormErrors(validationErrors);
      })();
    }
  };

  const checkDkim = () => {
    updateToken();
    setBtnLoading(true);
    showLoadingBar();
    var validFormData = true;
    if (
      !dkimValidator.isValidSync({
        domainDkim: domain,
        selectorDkim: selector,
      })
    ) {
      validFormData = false;
    }
    if (validFormData) {
      MainRepositroy.checkDkimRecord(domain, selector, recaptchaToken).then(
        (res) => {
          if (res.data[0]) {
            setDkimCheckResult(res.data[0]);
            setDkimCheckResultType("success");
          } else {
            setDkimCheckResult(
              "Не е пронајден DKIM запис за дадениот домен: " + domain
            );
            setDkimCheckResultType("error");
          }
          setBtnLoading(false);
          hideLoadingBar();
          setFormErrors({});
        },
        (err) => {
          setDkimCheckResult(
            "Не е пронајден DKIM запис за дадениот домен: " + domain
          );
          setDkimCheckResultType("error");
          setBtnLoading(false);
          hideLoadingBar();
          setFormErrors({});
        }
      );
    } else {
      var validationErrors = {};
      (async () => {
        await dkimValidator
          .validate(
            {
              domainDkim: domain,
              selectorDkim: selector,
            },
            { abortEarly: false }
          )
          .catch((err) => {
            err.inner.forEach((validationError) => {
              validationErrors[validationError.path] = {};
              validationErrors[validationError.path] = validationError.message;
            });
          });
        setBtnLoading(false);
        hideLoadingBar();
        setFormErrors(validationErrors);
      })();
    }
  };

  const checkDmarc = () => {
    updateToken();
    setBtnLoading(true);
    showLoadingBar();
    var validFormData = true;
    if (
      !dmarcValidator.isValidSync({
        domainDmarc: domain,
      })
    ) {
      validFormData = false;
    }
    if (validFormData) {
      MainRepositroy.checkDmarcRecord(domain, recaptchaToken).then(
        (res) => {
          if (res.data[0]) {
            setDmarcCheckResult(res.data[0]);
            setDmarcCheckResultType("success");
          } else {
            setDmarcCheckResult(
              "Не е пронајден DMARC запис за дадениот домен: " + domain
            );
            setDmarcCheckResultType("error");
          }
          setBtnLoading(false);
          hideLoadingBar();
          setFormErrors({});
        },
        (err) => {
          setDmarcCheckResult(
            "Не е пронајден DMARC запис за дадениот домен: " + domain
          );
          setDmarcCheckResultType("error");
          setBtnLoading(false);
          hideLoadingBar();
          setFormErrors({});
        }
      );
    } else {
      var validationErrors = {};
      (async () => {
        await dmarcValidator
          .validate(
            {
              domainDmarc: domain,
            },
            { abortEarly: false }
          )
          .catch((err) => {
            err.inner.forEach((validationError) => {
              validationErrors[validationError.path] = {};
              validationErrors[validationError.path] = validationError.message;
            });
          });
        setBtnLoading(false);
        hideLoadingBar();
        setFormErrors(validationErrors);
      })();
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={true}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={"md"}
      >
        <DialogTitle id="scroll-dialog-title">{props.dialogTitle}</DialogTitle>
        {loading ? <LinearProgress color="secondary" /> : ""}

        <DialogContent dividers={true}>
          {props.dialogType === "spfValidator" && (
            <React.Fragment>
              <ReCaptcha
                ref={(ref) => setRecaptcha(ref)}
                sitekey="6LdkJeoZAAAAAIleGu7k2cJYmWQ45dRFI3YJ7gPz"
                action="request"
                verifyCallback={verifyCallback}
              />
              <Grid container spacing={1}>
                <Grid item sm={12} xs={12}>
                  <Alert fullwidth="true" severity="info">
                    <AlertTitle>24 до 48 часа</AlertTitle>
                    Што е пропагација и колку време трае? Промените на именски
                    сервери обично траат од 24 до 48 часа за целосно
                    синхронизирање. Овој период, наречен пропагација, е
                    предвидено време што е потребно за да се ажурираат
                    root-серверите и кешираните записи низ Интернет, со
                    DNS-информациите за вашата веб-страница.
                    <br></br>
                    <p style={{ fontSize: "11px" }}>
                      What is Propagation and How Long Does it Take? Name server
                      changes usually take 24 to 48 hours to fully start
                      working. This period, called propagation, is the projected
                      length of time it takes for root name servers and cache
                      records across the entire web to be updated with your
                      website's DNS information.
                    </p>
                  </Alert>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    value={domain}
                    onChange={(e) => setDomain(e.target.value.trim())}
                    fullWidth
                    required
                    label="Домен"
                    error={formErrors?.domainSpf ? true : false}
                    helperText={formErrors?.domainSpf}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Button
                    onClick={() => checkSpf()}
                    fullWidth
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ marginTop: "20px" }}
                  >
                    {btnLoading ? (
                      <CircularProgress style={{ color: "white" }} size={20} />
                    ) : (
                      "Пребарај SPF"
                    )}
                  </Button>
                </Grid>
                {spfCheckResultType && (
                  <Grid item sm={12} xs={12}>
                    <Alert fullwidth="true" severity={spfCheckResultType}>
                      <AlertTitle>Резултати од пребарување:</AlertTitle>
                      <span
                        style={{ wordWrap: "break-word", maxWidth: "50vw" }}
                      >
                        {spfCheckResult}
                      </span>
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          )}
          {props.dialogType === "dkimValidator" && (
            <React.Fragment>
              <ReCaptcha
                ref={(ref) => setRecaptcha(ref)}
                sitekey="6LdkJeoZAAAAAIleGu7k2cJYmWQ45dRFI3YJ7gPz"
                action="request"
                verifyCallback={verifyCallback}
              />
              <Grid container spacing={1}>
                <Grid item sm={12} xs={12}>
                  <Alert fullwidth="true" severity="info">
                    <AlertTitle>24 до 48 часа</AlertTitle>
                    Што е пропагација и колку време трае? Промените на именски
                    сервери обично траат од 24 до 48 часа за целосно
                    синхронизирање. Овој период, наречен пропагација, е
                    предвидено време што е потребно за да се ажурираат
                    root-серверите и кешираните записи низ Интернет, со
                    DNS-информациите за вашата веб-страница.
                    <br></br>
                    <p style={{ fontSize: "11px" }}>
                      What is Propagation and How Long Does it Take? Name server
                      changes usually take 24 to 48 hours to fully start
                      working. This period, called propagation, is the projected
                      length of time it takes for root name servers and cache
                      records across the entire web to be updated with your
                      website's DNS information.
                    </p>
                  </Alert>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    value={domain}
                    onChange={(e) => setDomain(e.target.value.trim())}
                    fullWidth
                    label="Domain"
                    required
                    error={formErrors?.domainDkim ? true : false}
                    helperText={formErrors?.domainDkim}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    value={selector}
                    onChange={(e) => setSelector(e.target.value.trim())}
                    fullWidth
                    label="Selector"
                    required
                    error={formErrors?.selectorDkim ? true : false}
                    helperText={formErrors?.selectorDkim}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Button
                    onClick={() => checkDkim()}
                    fullWidth
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ marginTop: "20px" }}
                  >
                    {btnLoading ? (
                      <CircularProgress style={{ color: "white" }} size={20} />
                    ) : (
                      "Пребарај DKIM"
                    )}
                  </Button>
                </Grid>
                {dkimCheckResultType && (
                  <Grid item sm={12} xs={12}>
                    <Alert fullwidth="true" severity={dkimCheckResultType}>
                      <AlertTitle>Резултати од пребарување:</AlertTitle>
                      <span
                        style={{ wordWrap: "break-word", maxWidth: "50vw" }}
                      >
                        {dkimCheckResult}
                      </span>
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          )}
          {props.dialogType === "dmarcValidator" && (
            <React.Fragment>
              <ReCaptcha
                ref={(ref) => setRecaptcha(ref)}
                sitekey="6LdkJeoZAAAAAIleGu7k2cJYmWQ45dRFI3YJ7gPz"
                action="request"
                verifyCallback={verifyCallback}
              />
              <Grid container spacing={1}>
                <Grid item sm={12} xs={12}>
                  <Alert fullwidth="true" severity="info">
                    <AlertTitle>24 до 48 часа</AlertTitle>
                    Што е пропагација и колку време трае? Промените на именски
                    сервери обично траат од 24 до 48 часа за целосно
                    синхронизирање. Овој период, наречен пропагација, е
                    предвидено време што е потребно за да се ажурираат
                    root-серверите и кешираните записи низ Интернет, со
                    DNS-информациите за вашата веб-страница.
                    <br></br>
                    <p style={{ fontSize: "11px" }}>
                      What is Propagation and How Long Does it Take? Name server
                      changes usually take 24 to 48 hours to fully start
                      working. This period, called propagation, is the projected
                      length of time it takes for root name servers and cache
                      records across the entire web to be updated with your
                      website's DNS information.
                    </p>
                  </Alert>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    value={domain}
                    onChange={(e) => setDomain(e.target.value.trim())}
                    fullWidth
                    label="Domain"
                    required
                    error={formErrors?.domainDmarc ? true : false}
                    helperText={formErrors?.domainDmarc}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Button
                    onClick={() => checkDmarc()}
                    fullWidth
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ marginTop: "20px" }}
                  >
                    {btnLoading ? (
                      <CircularProgress style={{ color: "white" }} size={20} />
                    ) : (
                      "Пребарај DMARC"
                    )}
                  </Button>
                </Grid>
                {dmarcCheckResultType && (
                  <Grid item sm={12} xs={12}>
                    <Alert fullwidth="true" severity={dmarcCheckResultType}>
                      <AlertTitle>Резултати од пребарување:</AlertTitle>
                      <span
                        style={{ wordWrap: "break-word", maxWidth: "50vw" }}
                      >
                        {dmarcCheckResult}
                      </span>
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          )}
          {props.dialogType === "spfGenerator" && (
            <React.Fragment>
              <Grid container spacing={1}>
                <Grid item sm={12} xs={12}>
                  <Typography variant="subtitle2">
                    <b>
                      Користете ја оваа алатка да генерирате SPF (РПИ) запис.
                    </b>
                    <p style={{ margin: 0, fontSize: "11px" }}>
                      (Use this tool to generate your SPF record.)
                    </p>
                  </Typography>
                </Grid>
                <Grid item md={7} sm={12} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography variant="subtitle2">
                        Дозволете им на серверите наведени како MX да испраќаат
                        е-пошта во име на вашиот домен: Да, Не
                        <p style={{ margin: 0, fontSize: "11px" }}>
                          (Allow servers listed as MX to send emails for your
                          domain: Yes, No)
                        </p>
                      </Typography>
                    </Grid>
                    <Grid item md={7} sm={12} xs={12}>
                      <RadioGroup
                        aria-label="anonymous"
                        name="anonymous"
                        value={param1}
                        onChange={param1Change}
                        row
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label={
                            <Typography variant="subtitle2">
                              Да(препорачливо)
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio />}
                          label={
                            <Typography variant="subtitle2">Не</Typography>
                          }
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>

                  <Hidden mdUp implementation="css">
                    <br />
                    <Divider />
                    <br />
                  </Hidden>

                  <Grid container spacing={3}>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography variant="subtitle2">
                        Дозволете тековната IP адреса на доменот да испраќа
                        е-пошта за овој домен: Да, Не
                        <p style={{ margin: 0, fontSize: "11px" }}>
                          (Allow current IP address of the domain to send email
                          for this domain: Yes , No)
                        </p>
                      </Typography>
                    </Grid>
                    <Grid item md={7} sm={12} xs={12}>
                      <RadioGroup
                        aria-label="anonymous"
                        name="anonymous"
                        value={param2}
                        onChange={param2Change}
                        row
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label={
                            <Typography variant="subtitle2">
                              Да(препорачливо)
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio />}
                          label={
                            <Typography variant="subtitle2">Не</Typography>
                          }
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <Hidden mdUp implementation="css">
                    <br />
                    <Divider />
                    <br />
                  </Hidden>
                  <Grid container spacing={3}>
                    <Grid item md={5} sm={12} xs={12}>
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        alignItems="center"
                        style={{ paddingTop: "9px" }}
                      >
                        <Grid item>
                          <Typography variant="subtitle2">
                            {" "}
                            IPv4 Адреси
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Tooltip
                            title="Space-separated IP4 addresses in CIDR format that
                        deliver or relay mail for this domain"
                            placement="right"
                          >
                            <InfoIcon />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={7} sm={12} xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        onChange={param4Change}
                        placeholder="10.0.0.1/20 192.168.0.10"
                        helperText={"Внесете валидни IP адреси"}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DnsIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Hidden mdUp implementation="css">
                    <br />
                    <Divider />
                    <br />
                  </Hidden>
                  <Grid container spacing={3}>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography variant="subtitle2">
                        Додадете други имиња на домаќини на серверот што може да
                        доставува или пренесува пошта за вашиот домен:
                        <p style={{ margin: 0, fontSize: "11px" }}>
                          (Add any other server hostnames that may deliver or
                          relay mail for your domain:)
                        </p>
                      </Typography>
                    </Grid>
                    <Grid item md={7} sm={12} xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        onChange={param5Change}
                        placeholder="mydomain.com"
                        helperText={
                          "Внесете важечко име на домен, без префикс http://"
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PublicIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Hidden mdUp implementation="css">
                    <br />
                    <Divider />
                    <br />
                  </Hidden>
                  <Grid container spacing={3}>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography variant="subtitle2">
                        Додадете домени што можат да доставуваат или пренесуваат
                        пошта за вашиот домен:
                        <p style={{ margin: 0, fontSize: "11px" }}>
                          (Add any domains that may deliver or relay mail for
                          your domain:)
                        </p>
                      </Typography>
                    </Grid>
                    <Grid item md={7} sm={12} xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        onChange={param6Change}
                        placeholder="google.com yandex.com"
                        helperText={
                          "Внесете важечко име на домен, без префикс http://. Ако имате повеќе имиња на домени, одделете ги со празно место"
                        }
                      />
                    </Grid>
                  </Grid>
                  <Hidden mdUp implementation="css">
                    <br />
                    <Divider />
                    <br />
                  </Hidden>
                  <Grid container spacing={3}>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography variant="subtitle2">
                        Колку строги треба да бидат серверите што ги третираат
                        е-поштите? Fail (е-поштите што не се усогласени ќе бидат
                        одбиени), Soft-fail ( Не-усогласените е-пошти ќе бидат
                        прифатен, но обележани), Neutral (Не-усогласените
                        е-пошти веројатно ќе бидат прифатени)
                        <p style={{ margin: 0, fontSize: "11px" }}>
                          How strict should the servers treating the emails be?
                          Fail (non-compliant emails will be rejected),
                          Soft-fail (Non-compliant emails will be accepted but
                          marked), Neutral (Mails will probably be accepted)
                        </p>
                      </Typography>
                    </Grid>
                    <Grid item md={7} sm={12} xs={12}>
                      <Select
                        fullWidth
                        defaultValue={0}
                        onChange={param7Change}
                      >
                        <MenuItem value={0}>-</MenuItem>
                        <MenuItem value={1}>
                          <Typography variant="subtitle2">
                            Fail (Not compliant will be rejected)
                          </Typography>
                        </MenuItem>
                        <MenuItem value={2}>
                          <Typography variant="subtitle2">
                            SoftFail (Not compliant will be accepted but marked)
                          </Typography>
                        </MenuItem>
                        <MenuItem value={3}>
                          <Typography variant="subtitle2">
                            Neutral (Mails will be probably accepted)
                          </Typography>
                        </MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Hidden mdUp implementation="css">
                  <br />
                  <Divider />
                  <br />
                </Hidden>
                <Grid item md={5} sm={12} xs={12}>
                  <Grid item sm={12} xs={12}>
                    <Table>
                      <TableBody style={{ border: "4px solid #f0f0f0" }}>
                        <TableRow>
                          <TableCell>
                            <Typography variant="subtitle2">
                              <b>Генериран SPF (РПИ) запис</b>
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{ backgroundColor: "#f0f0f0" }}>
                          <TableCell>
                            <TextField
                              variant="outlined"
                              fullWidth
                              size="small"
                              multiline
                              rowsMax={4}
                              disabled
                              value={`v=spf1${param1 === 1 ? " mx" : ""}${
                                param2 === 1 ? " a" : ""
                              }${param3}${
                                param4 === ""
                                  ? ""
                                  : param4
                                      .map((item) => ` ip4:${item}`)
                                      .join("")
                              }${
                                param5 === ""
                                  ? ""
                                  : param5.map((item) => ` a:${item}`).join("")
                              }${
                                param6 === ""
                                  ? ""
                                  : param6
                                      .map((item) => ` include:${item}`)
                                      .join("")
                              }${param7}`}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Hidden mdUp implementation="css">
                    <br />
                    <Divider />
                    <br />
                  </Hidden>
                  <Grid container>
                    <Grid item sm={6} xs={6} style={{ paddingTop: "8px" }}>
                      <CopyToClipboard
                        text={`v=spf1${param1 === 1 ? " mx" : ""}${
                          param2 === 1 ? " a" : ""
                        }${param3}${
                          param4 === ""
                            ? ""
                            : param4.map((item) => ` ip4:${item}`).join("")
                        }${
                          param5 === ""
                            ? ""
                            : param5.map((item) => ` a:${item}`).join("")
                        }${
                          param6 === ""
                            ? ""
                            : param6.map((item) => ` include:${item}`).join("")
                        }${param7}`}
                        onCopy={() => handleOpenAlert()}
                      >
                        <Button
                          size="small"
                          fullWidth
                          variant="contained"
                          color="primary"
                        >
                          Копирај
                        </Button>
                      </CopyToClipboard>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                      {open && (
                        <Alert style={{ marginLeft: "4px" }} severity="success">
                          Копирано!
                        </Alert>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          {props.dialogType === "dmarcGenerator" && (
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item md={7} sm={12} xs={12}>
                  <Grid item sm={12} xs={12}>
                    <Typography variant="subtitle2">
                      <b>ГЕНЕРИРАЈ DMARC DNS ЗАПИС</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={12} xs={12} style={{ paddingTop: "10px" }}>
                    <Grid container spacing={3}>
                      <Grid item sm={4} xs={4}>
                        <Typography
                          variant="subtitle2"
                          style={{ paddingTop: "7px" }}
                        >
                          Правила
                          <p style={{ fontSize: "11px", margin: 0 }}>
                            (Policy)
                          </p>
                        </Typography>
                      </Grid>
                      <Grid item sm={1} xs={1}>
                        <Tooltip
                          style={{ paddingTop: "7px" }}
                          title="The DMARC policy allows senders to dictate how emails failing the DMARC check will be handled by email receivers.The three available policies are p=none, p=quarantine or p=reject"
                          placement="right"
                        >
                          <InfoIcon />
                        </Tooltip>
                      </Grid>
                      <Grid item md={7} sm={12} xs={12}>
                        <Select
                          fullWidth
                          defaultValue={dmarcParam1}
                          onChange={dmarcParam1Change}
                        >
                          <MenuItem value={0}>
                            <Typography variant="subtitle2">
                              Monitor(none)
                            </Typography>
                          </MenuItem>
                          <MenuItem value={1}>
                            <Typography variant="subtitle2">
                              Quarantine
                            </Typography>
                          </MenuItem>
                          <MenuItem value={2}>
                            <Typography variant="subtitle2">Reject</Typography>
                          </MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                    {dmarcParam1 != 0 && (
                      <Grid container spacing={3}>
                        <Grid item sm={4} xs={4}>
                          <Typography
                            variant="subtitle2"
                            style={{ paddingTop: "7px" }}
                          >
                            Процент на правила
                            <p style={{ fontSize: "11px", margin: 0 }}>
                              (Policy percentage)
                            </p>
                          </Typography>
                        </Grid>
                        <Grid item sm={1} xs={1}>
                          <Tooltip
                            style={{ paddingTop: "7px" }}
                            title="The percentage tag instructs ISPs to only apply the DMARC policy to a percentage of failing emails. 'pct=50' will instruct receivers to only apply the policy for 50% of the emails that fail the DMARC check.NOTE: this will not work for the p = none policy, but only for the p = quarantine or p = reject policies."
                            placement="right"
                          >
                            <InfoIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item md={7} sm={12} xs={12}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            onChange={(e) => {
                              setDmarcParam1Policy(e.target.value);
                            }}
                            value={dmarcParam1Policy}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Hidden mdUp implementation="css">
                      <br />
                      <Divider />
                      <br />
                    </Hidden>
                    <Grid container spacing={3}>
                      <Grid item sm={4} xs={4}>
                        <Typography
                          variant="subtitle2"
                          style={{ paddingTop: "7px" }}
                        >
                          Политика за поддомен/и
                          <p style={{ fontSize: "11px", margin: 0 }}>
                            (Subdomains policy)
                          </p>
                        </Typography>
                      </Grid>

                      <Grid item sm={1} xs={1}>
                        {dmarcParam2 && (
                          <Tooltip
                            title="Select the policy you want to apply to emails sent from your subdomains. If unspecified, the policy applied to the main domain will be applied to the subdomain.If you do not have any subdomains, set a subdomain reject policy."
                            placement="right"
                            style={{ paddingTop: "6px" }}
                          >
                            <InfoIcon />
                          </Tooltip>
                        )}
                      </Grid>

                      <Grid item md={7} sm={12} xs={12}>
                        <Grid container spacing={1}>
                          <Grid item sm={3} xs={3}>
                            <Switch
                              checked={dmarcParam2}
                              onChange={dmarcParam2Change}
                              name="checkedA"
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                            />
                          </Grid>
                          <Grid item sm={9} xs={9}>
                            {dmarcParam2 && (
                              <Select
                                fullWidth
                                defaultValue={dmarcParam3}
                                onChange={dmarcParam3Change}
                              >
                                <MenuItem value={0}>
                                  <Typography variant="subtitle2">
                                    Monitor(none)
                                  </Typography>
                                </MenuItem>
                                <MenuItem value={1}>
                                  <Typography variant="subtitle2">
                                    Quarantine
                                  </Typography>
                                </MenuItem>
                                <MenuItem value={2}>
                                  <Typography variant="subtitle2">
                                    Reject
                                  </Typography>
                                </MenuItem>
                              </Select>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Hidden mdUp implementation="css">
                      <br />
                      <Divider />
                      <br />
                    </Hidden>
                    <Grid container spacing={3}>
                      <Grid item sm={4} xs={4}>
                        <Typography variant="subtitle2">
                          E-пошта за збирни извештаи
                          <p style={{ margin: 0, fontSize: "11px" }}>
                            (Aggregate report email)
                          </p>
                        </Typography>
                      </Grid>

                      <Grid item sm={1} xs={1}>
                        <Tooltip
                          title="Reporting is a key aspect of DMARC that provides domain owners a wealth of information on their email activity and helps them identify all parties sending email on their behalf. DMARC generates two types of reports:Aggregate reports and Forensic reports.The email addresses to which those reports will be sent have been generated and are displayed below."
                          placement="right"
                        >
                          <InfoIcon />
                        </Tooltip>
                      </Grid>
                      <Grid item md={7} sm={12} xs={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          size="small"
                          onChange={dmarcParam4Change}
                          placeholder="example@mail.com"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Hidden mdUp implementation="css">
                      <br />
                      <Divider />
                      <br />
                    </Hidden>
                    <Grid container spacing={3}>
                      <Grid item sm={4} xs={4}>
                        <Grid
                          container
                          spacing={1}
                          direction="row"
                          alignItems="center"
                          style={{ paddingTop: "9px" }}
                        >
                          <Typography variant="subtitle2">
                            Е-Пошта за форензички извештаи
                            <p style={{ margin: 0, fontSize: "11px" }}>
                              (Forensic feedback email)
                            </p>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item sm={1} xs={1}>
                        <Tooltip
                          title="Reporting is a key aspect of DMARC that provides domain owners a wealth of information on their email activity and helps them identify all parties sending email on their behalf. DMARC generates two types of reports:Aggregate reports and Forensic reports.The email addresses to which those reports will be sent have been generated and are displayed below."
                          placement="right"
                        >
                          <InfoIcon />
                        </Tooltip>
                      </Grid>
                      <Grid item md={7} sm={12} xs={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          size="small"
                          onChange={dmarcParam5Change}
                          placeholder="example@mail.com"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Hidden mdUp implementation="css">
                      <br />
                      <Divider />
                      <br />
                    </Hidden>
                    <Grid container spacing={3}>
                      <Grid item md={5} sm={12} xs={12}>
                        <Typography variant="subtitle2">
                          DKIM Усогалсување
                          <p style={{ margin: 0, fontSize: "11px" }}>
                            (DKIM alignment)
                          </p>
                        </Typography>
                      </Grid>
                      <Grid item md={7} sm={12} xs={12}>
                        <RadioGroup
                          value={dmarcParam6}
                          row
                          onChange={dmarcParam6Change}
                        >
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label={
                              <Typography variant="subtitle2">
                                Relaxed(default)
                              </Typography>
                            }
                            size="small"
                          />
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label={
                              <Typography variant="subtitle2">
                                Strict
                              </Typography>
                            }
                            size="small"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Hidden mdUp implementation="css">
                      <br />
                      <Divider />
                      <br />
                    </Hidden>
                    <Grid container spacing={3}>
                      <Grid item md={5} sm={12} xs={12}>
                        <Typography variant="subtitle2">
                          SPF Усогласување
                          <p style={{ margin: 0, fontSize: "11px" }}>
                            (SPF alignment)
                          </p>
                        </Typography>
                      </Grid>
                      <Grid item md={7} sm={12} xs={12}>
                        <RadioGroup
                          value={dmarcParam7}
                          row
                          onChange={dmarcParam7Change}
                        >
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label={
                              <Typography variant="subtitle2">
                                Relaxed(default)
                              </Typography>
                            }
                            size="small"
                          />
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label={
                              <Typography variant="subtitle2">
                                Strict
                              </Typography>
                            }
                            size="small"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Hidden mdUp implementation="css">
                      <br />
                      <Divider />
                      <br />
                    </Hidden>
                    <Grid container spacing={3}>
                      <Grid item md={5} sm={12} xs={12}>
                        <Typography variant="subtitle2">
                          Опции за форензични извештаи
                          <p style={{ margin: 0, fontSize: "11px" }}>
                            (Forensic options)
                          </p>
                        </Typography>
                      </Grid>
                      <Grid item md={7} sm={12} xs={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="checkedA"
                                checked={dmarcParam8.checkedA}
                                onChange={dmarcParam8Change}
                              />
                            }
                            label={
                              <Typography variant="subtitle2">
                                When both SPF and DKIM fail.
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="checkedB"
                                checked={dmarcParam8.checkedB}
                                onChange={dmarcParam8Change}
                              />
                            }
                            label={
                              <Typography variant="subtitle2">
                                When either SPF or DKIM fails.
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="checkedF"
                                checked={dmarcParam8.checkedF}
                                onChange={dmarcParam8Change}
                              />
                            }
                            label={
                              <Typography variant="subtitle2">
                                When DKIM fails.
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="checkedG"
                                checked={dmarcParam8.checkedG}
                                onChange={dmarcParam8Change}
                              />
                            }
                            label={
                              <Typography variant="subtitle2">
                                When SPF fails.
                              </Typography>
                            }
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Hidden mdUp implementation="css">
                  <br />
                  <Divider />
                  <br />
                </Hidden>
                <Grid item md={5} sm={12} xs={12}>
                  <Grid item sm={12} xs={12}>
                    <Typography variant="subtitle2">
                      <b>DMARC DNS RECORD</b>
                    </Typography>
                    <Typography variant="subtitle2">
                      Оваа алатка генерира DMARC запис што можете да го објавите
                      на вашиот DNS. Откако ќе се објави овој запис, дневниот
                      извештај ќе биде испратен до e-поштата за збирни извештаи.
                      Податоците содржани во овој извештај потоа се обработуваат
                      и се прикажуваат на вашата табла. Може да имате и
                      форензички извештаи проследени на проследената е-пошта за
                      форензички извештаи.
                      <p style={{ margin: 0, fontSize: "11px" }}>
                        (This tool generates a DMARC record you can publish to
                        your DNS. Once this record is published, a daily report
                        will be sent to a unique email address that matches your
                        account. The data contained within this report is then
                        processed and displayed on your dashboard. You can also
                        have forensic emails forwarded to another unique email
                        address.)
                      </p>
                    </Typography>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <Table>
                      <TableBody style={{ border: "4px solid #f0f0f0" }}>
                        <TableRow style={{ backgroundColor: "#f0f0f0" }}>
                          <TableCell>
                            <TextField
                              variant="outlined"
                              fullWidth
                              size="small"
                              multiline
                              rowsMax={4}
                              disabled
                              value={`v=DMARC1;${
                                dmarcParam1 === 0
                                  ? " p=none;"
                                  : dmarcParam1 === 1
                                  ? " p=quarantine;"
                                  : " p=reject;"
                              }${
                                dmarcParam1 !== 0
                                  ? ` pct=${dmarcParam1Policy};`
                                  : ""
                              }${
                                dmarcParam2 && dmarcParam3 === 0
                                  ? " sp=none;"
                                  : dmarcParam3 === 1
                                  ? " sp=quarantine;"
                                  : dmarcParam3 === 2
                                  ? " sp=reject;"
                                  : ""
                              }${
                                dmarcParam4 === ""
                                  ? ""
                                  : ` rua=${dmarcParam4
                                      .split(" ")
                                      .map(
                                        (item, index) =>
                                          `mailto:${item}${
                                            index <
                                            dmarcParam4.split(" ").length - 1
                                              ? ","
                                              : ";"
                                          }`
                                      )
                                      .join("")}`
                              }${
                                dmarcParam5 === ""
                                  ? ""
                                  : ` ruf=${dmarcParam5
                                      .split(" ")
                                      .map(
                                        (item, index) =>
                                          `mailto:${item}${
                                            index <
                                            dmarcParam5.split(" ").length - 1
                                              ? ","
                                              : ";"
                                          }`
                                      )
                                      .join("")}`
                              }${dmarcParam6 === 0 ? "" : ` adkim=s;`}${
                                dmarcParam7 === 0 ? "" : ` aspf=s;`
                              }${
                                !dmarcParam8.checkedA &&
                                !dmarcParam8.checkedB &&
                                !dmarcParam8.checkedF &&
                                !dmarcParam8.checkedG
                                  ? ""
                                  : ` fo=${dmarcParam8.checkedA ? "0" : ""}${
                                      (dmarcParam8.checkedA &&
                                        dmarcParam8.checkedB) ||
                                      (dmarcParam8.checkedA &&
                                        dmarcParam8.checkedF) ||
                                      (dmarcParam8.checkedA &&
                                        dmarcParam8.checkedG)
                                        ? ":"
                                        : ""
                                    }${dmarcParam8.checkedB ? "1" : ""}${
                                      (dmarcParam8.checkedB &&
                                        dmarcParam8.checkedF) ||
                                      (dmarcParam8.checkedB &&
                                        dmarcParam8.checkedG)
                                        ? ":"
                                        : ""
                                    }${dmarcParam8.checkedF ? "d" : ""}${
                                      dmarcParam8.checkedF &&
                                      dmarcParam8.checkedG
                                        ? ":"
                                        : ""
                                    }${dmarcParam8.checkedG ? "s" : ""};`
                              }`}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>

                  <Grid container>
                    <Grid item sm={6} xs={6} style={{ paddingTop: "8px" }}>
                      <CopyToClipboard
                        text={`v=DMARC1;${
                          dmarcParam1 === 0
                            ? " p=none;"
                            : dmarcParam1 === 1
                            ? " p=quarantine;"
                            : " p=reject;"
                        }${
                          dmarcParam2 && dmarcParam3 === 0
                            ? " sp=none;"
                            : dmarcParam3 === 1
                            ? " sp=quarantine;"
                            : dmarcParam3 === 2
                            ? " sp=reject;"
                            : ""
                        }${
                          dmarcParam4 === ""
                            ? ""
                            : ` rua=${dmarcParam4
                                .split(" ")
                                .map(
                                  (item, index) =>
                                    `mailto:${item}${
                                      index < dmarcParam4.split(" ").length - 1
                                        ? ","
                                        : ";"
                                    }`
                                )
                                .join("")}`
                        }${
                          dmarcParam5 === ""
                            ? ""
                            : ` ruf=${dmarcParam5
                                .split(" ")
                                .map(
                                  (item, index) =>
                                    `mailto:${item}${
                                      index < dmarcParam5.split(" ").length - 1
                                        ? ","
                                        : ";"
                                    }`
                                )
                                .join("")}`
                        }${dmarcParam6 === 0 ? "" : ` adkim=s;`}${
                          dmarcParam7 === 0 ? "" : ` aspf=s;`
                        }${
                          !dmarcParam8.checkedA &&
                          !dmarcParam8.checkedB &&
                          !dmarcParam8.checkedF &&
                          !dmarcParam8.checkedG
                            ? ""
                            : ` fo=${dmarcParam8.checkedA ? "0" : ""}${
                                (dmarcParam8.checkedA &&
                                  dmarcParam8.checkedB) ||
                                (dmarcParam8.checkedA &&
                                  dmarcParam8.checkedF) ||
                                (dmarcParam8.checkedA && dmarcParam8.checkedG)
                                  ? ":"
                                  : ""
                              }${dmarcParam8.checkedB ? "1" : ""}${
                                (dmarcParam8.checkedB &&
                                  dmarcParam8.checkedF) ||
                                (dmarcParam8.checkedB && dmarcParam8.checkedG)
                                  ? ":"
                                  : ""
                              }${dmarcParam8.checkedF ? "d" : ""}${
                                dmarcParam8.checkedF && dmarcParam8.checkedG
                                  ? ":"
                                  : ""
                              }${dmarcParam8.checkedG ? "s" : ""};`
                        }`}
                        onCopy={() => handleOpenAlert()}
                      >
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                        >
                          Копирај
                        </Button>
                      </CopyToClipboard>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                      {open && (
                        <Alert style={{ marginLeft: "4px" }} severity="success">
                          Копирано!
                        </Alert>
                      )}
                    </Grid>
                    <br />
                    <Grid item sm={12} xs={12}>
                      <Typography variant="subtitle2">
                        Please publish the following DNS TXT record on the
                        subdomain: _dmarc.YOURDOMAIN.com
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
          {props.dialogType === "dkimGenerator" && (
            <div>
              <Grid container spacing={1}>
                <Grid item sm={12} xs={12} style={{ margin: "5px" }}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder="mydomain.com"
                    onChange={(e) => setDkimDomain(e.target.value)}
                    error={formErrors?.dkimDomain ? true : false}
                    helperText={formErrors?.dkimDomain}
                  />
                  <Typography variant="subtitle2">
                    Внесете валидно име на домен, без префикс http: //
                    <p style={{ margin: 0, fontSize: "11px" }}>
                      (Please enter a valid domain name, without http:// prefix)
                    </p>
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12} style={{ margin: "5px" }}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder="e.g., key1"
                    onChange={(e) => setDkimSelector(e.target.value)}
                    error={formErrors?.dkimSelector ? true : false}
                    helperText={formErrors?.dkimSelector}
                  />
                  <Typography variant="subtitle2">
                    Избор на клуч за домен(п.р., key1)
                    <p style={{ margin: 0, fontSize: "11px" }}>
                      ( DomainKey Selector (e.g., key1))
                    </p>
                  </Typography>
                </Grid>

                <Grid item sm={12} xs={12} style={{ margin: "5px" }}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={startGenerateClick}
                    disabled={btnLoading}
                  >
                    {btnLoading ? (
                      <CircularProgress style={{ color: "white" }} size={20} />
                    ) : (
                      "Генерирај клучеви"
                    )}
                  </Button>
                </Grid>

                {dkimCreate && (
                  <Grid item sm={12} xs={12} style={{ margin: "5px" }}>
                    <Grid container spacing={1}>
                      <Grid item sm={12} xs={12} style={{ margin: "5px" }}>
                        <Typography variant="h5">
                          DKIM Име на DNS рекорд
                          <p style={{ margin: 0, fontSize: "11px" }}>
                            ( DKIM Dns Record name)
                          </p>
                        </Typography>
                        <Table>
                          <TableBody style={{ border: "2px solid #404040" }}>
                            <TableRow style={{ backgroundColor: "#f0f0f0" }}>
                              <TableCell>
                                {dkimSelector}._domainkey.{dkimDomain}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                      <Grid item sm={12} xs={12} style={{ margin: "5px" }}>
                        <Typography variant="h5">Приватен клуч</Typography>
                        <Typography variant="subtitle2">
                          Овој клуч мора да го внесете во вашиот потписник за
                          DKIM. Мора да се чува во тајност, бидејќи секој што
                          има пристап до него може да печат белези кои се
                          преправаат дека сте вие.
                          <p style={{ margin: 0, fontSize: "11px" }}>
                            You must enter this key in your DKIM signer. It must
                            be kept secret, as anyone with access to it can
                            stamp tokens pretending to be you.
                          </p>
                        </Typography>
                        <Table>
                          <TableBody style={{ border: "2px solid #404040" }}>
                            <TableRow style={{ backgroundColor: "#f0f0f0" }}>
                              <TableCell>{dkimPrivateKey}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                      <Grid container>
                        <Grid item sm={6} xs={6} style={{ paddingTop: "5px" }}>
                          <CopyToClipboard
                            text={dkimPrivateKey}
                            onCopy={() => handleOpenAlert()}
                          >
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                            >
                              Копирај
                            </Button>
                          </CopyToClipboard>
                        </Grid>
                        <Grid item sm={6} xs={6}>
                          {open && (
                            <Alert
                              style={{ marginLeft: "4px" }}
                              severity="success"
                            >
                              Копирано!
                            </Alert>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item sm={12} xs={12} style={{ margin: "5px" }}>
                        <Typography variant="h5">DKIM DNS рекорд</Typography>
                        <Table>
                          <TableBody style={{ border: "2px solid #404040" }}>
                            <TableRow style={{ backgroundColor: "#f0f0f0" }}>
                              <TableCell>{dkimRecord}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                      <Grid container>
                        <Grid item sm={6} xs={6} style={{ paddingTop: "5px" }}>
                          <CopyToClipboard
                            text={dkimRecord}
                            onCopy={() => handleOpenAlert2()}
                          >
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                            >
                              Копирај
                            </Button>
                          </CopyToClipboard>
                        </Grid>
                        <Grid item sm={6} xs={6}>
                          {open2 && (
                            <Alert
                              style={{ marginLeft: "4px" }}
                              severity="success"
                            >
                              Копирано!
                            </Alert>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {LOCALE.close}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
