import Axios from "axios";
import { SETTINGS } from "../../../properties/ApiProps";

export const DomainRepository = {
  fetch: (id) => {
    return Axios.get(`${SETTINGS.API_URL}domainCheck/${id}`);
  },
  fetchAll: (payload) => {
    console.log(payload);
    return Axios({
      url: SETTINGS.API_URL + "domainCheck",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        page: payload.page,
        size: payload.size,
        orderBy: payload.orderBy,
        orderDirection: payload.orderDirection,
        filterBy: payload.filterBy,
        filterValue: payload.filterValue,
      },
    });
  },
  create: (data) => {
    return Axios({
      url: SETTINGS.API_URL + "domainCheck",
      method: "POST",
      data: data,
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },
  update: (data) => {
    return Axios({
      url: SETTINGS.API_URL + "domainCheck",
      method: "PUT",
      data: data,
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },
  delete: (id) => {
    return Axios.delete(`${SETTINGS.API_URL}domainCheck/${id}`);
  },
  fetchDomainChecks: (payload) => {
    return Axios({
      url: SETTINGS.API_URL + "domainCheck/all",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        page: payload.page,
        size: payload.size,
        orderBy: payload.orderBy,
        orderDirection: payload.orderDirection,
        filterProperty: payload.filterProperty,
        filterValue: payload.filterValue,
        domainCheckType: payload.domainCheckType,
        dateFrom: payload.dateFrom,
        dateTo: payload.dateTo,
      },
    });
  },
  handleInitiateDomainChecksForSelectedDomains: (payload) => {
    return Axios({
      url: SETTINGS.API_URL + "domain/admin/initiateDomainChecks",
      method: "POST",
      data: payload,
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },
};
