import React, { useEffect, useState } from "react";
// @material-ui/core components
import { Link, Redirect } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  IconButton,
  Icon,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  LinearProgress,
  Grid,
  TextField,
  Button,
  Typography,
  FormControlLabel,
  Switch,
  FormGroup,
  FormLabel,
  Box,
  Divider,
} from "@material-ui/core";
// @material-ui/styles
import styles from "../../../assets/jss/layouts/appStyle";
import { makeStyles } from "@material-ui/core/styles";
// core components
import { LOCALE } from "../../../properties/Locale";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
  triggerRerender,
} from "../../../common/actions/CommonActions";
import { useDispatch } from "react-redux";
import { DomainRepository } from "./DomainRepository";
import { validateCreateDomainForm } from "./DomainValidator";

const useStyles = makeStyles(styles);

export default function CreateDomainComponent() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState();
  const [domain, setDomain] = useState({
    domainName: "",
    domainWhoIs: false,
    mailServer: false,
    dnsLookup: false,
    domainHeaders: false,
    domainSSL: false,
    tlsSsl: false,
  });
  const [formErrors, setFormErrors] = useState();
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const handleChangeChecked = (event) => {
    setDomain({
      ...domain,
      [event.target.name]: event.target.checked,
    });
  };

  const showLoadingBar = () => {
    setLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
  };

  const handleClose = () => {};

  const handleChange = (name, value) => {
    let tmpDomain = { ...domain };
    tmpDomain[name] = value;
    setDomain(tmpDomain);
  };

  const createDomain = () => {
    showLoadingBar();
    var validFormData = true;
    if (!validateCreateDomainForm.isValidSync(domain)) {
      validFormData = false;
    }
    if (validFormData) {
      DomainRepository.create(domain)
        .then((res) => {
          hideLoadingBar(false);
          dispatch(triggerRerender());
          dispatch(notifyShowSuccessMessage("Доменот е успешно додаден"));
          setRedirectTo(`/app/admin/domain_checks/`);
        })
        .catch((err) => {
          hideLoadingBar(false);
          if (err.response?.data) {
            dispatch(notifyShowErrorMessage(err.response?.data));
          } else {
            dispatch(notifyShowErrorMessage(err.message));
          }
        });
    } else {
      var validationErrors = {};
      (async () => {
        await validateCreateDomainForm
          .validate(domain, { abortEarly: false })
          .catch((err) => {
            err.inner.forEach((validationError) => {
              validationErrors[validationError.path] = {};
              validationErrors[validationError.path] = validationError.message;
            });
          });
        hideLoadingBar(false);
        setFormErrors(validationErrors);
      })();
    }
  };

  return (
    <div>
      {redirectTo && <Redirect to={redirectTo} />}
      <Card>
        <Dialog
          open={true}
          onClose={handleClose}
          maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              overflowY: "unset",
            },
          }}
        >
          <CardHeader color="theme">
            <h4 className={classes.cardTitleWhite}>
              {"Креирај нов домен"}

              <Link
                to={`/app/admin/domain_checks/`}
                style={{ textDecoration: "none" }}
              >
                <IconButton
                  style={{ float: "right", color: "white" }}
                  aria-label="close"
                >
                  <Icon>close</Icon>
                </IconButton>
              </Link>
            </h4>
          </CardHeader>
          <CardBody>
            <DialogContent style={{ minHeight: "500px" }}>
              {loading ? <LinearProgress color="secondary" /> : ""}
              <div>
                <Grid container spacing={1}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="name"
                      label={LOCALE.domain}
                      name="name"
                      fullWidth={true}
                      value={domain.domainName}
                      onChange={(e) =>
                        handleChange("domainName", e.target.value)
                      }
                      size="small"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      error={formErrors?.domainName ? true : false}
                      helperText={formErrors?.domainName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="host"
                      label={"Mail Server Host"}
                      name="host"
                      fullWidth={true}
                      value={domain.host ? domain.host : ""}
                      onChange={(e) => handleChange("host", e.target.value)}
                      size="small"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      error={formErrors?.host ? true : false}
                      helperText={formErrors?.host}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="port"
                      label={"Mail Server Port"}
                      name="port"
                      fullWidth={true}
                      value={domain.port ? domain.port : ""}
                      onChange={(e) => handleChange("port", e.target.value)}
                      size="small"
                      margin="dense"
                      variant="outlined"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      error={formErrors?.port ? true : false}
                      helperText={formErrors?.port}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="dkimSelector"
                      label={"Dkim selector"}
                      name="dkimSelector"
                      fullWidth={true}
                      value={domain.dkimSelector ? domain.dkimSelector : ""}
                      onChange={(e) =>
                        handleChange("dkimSelector", e.target.value)
                      }
                      size="small"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      error={formErrors?.dkimSelector ? true : false}
                      helperText={formErrors?.dkimSelector}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="ownerName"
                      label={"Сопственик"}
                      name="ownerName"
                      fullWidth={true}
                      value={domain.ownerName}
                      onChange={(e) =>
                        handleChange("ownerName", e.target.value)
                      }
                      size="small"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      error={formErrors?.ownerName ? true : false}
                      helperText={formErrors?.ownerName}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="phone"
                      label={"Контакт"}
                      name="phone"
                      fullWidth={true}
                      value={domain.phone}
                      onChange={(e) => handleChange("phone", e.target.value)}
                      size="small"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      error={formErrors?.phone ? true : false}
                      helperText={formErrors?.phone}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="email"
                      label={"Емаил"}
                      name="email"
                      fullWidth={true}
                      value={domain.emails}
                      onChange={(e) => handleChange("emails", e.target.value)}
                      size="small"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      error={formErrors?.emails ? true : false}
                      helperText={formErrors?.emails}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="address"
                      label={"Адреса"}
                      name="address"
                      fullWidth={true}
                      value={domain.address}
                      onChange={(e) => handleChange("address", e.target.value)}
                      size="small"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      error={formErrors?.address ? true : false}
                      helperText={formErrors?.address}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <FormControl component="fieldset" fullWidth={true}>
                      <FormLabel component="legend">
                        Селекција на домен проверки
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={domain.domainWhoIs}
                              onChange={handleChangeChecked}
                              name="domainWhoIs"
                              color="primary"
                            />
                          }
                          label="DOMAIN WHOIS"
                        />
                        <Divider />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={domain.mailServer}
                              onChange={handleChangeChecked}
                              name="mailServer"
                              color="primary"
                            />
                          }
                          label="MAIL SERVER"
                        />
                        <Divider />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={domain.dnsLookup}
                              onChange={handleChangeChecked}
                              name="dnsLookup"
                              color="primary"
                            />
                          }
                          label="DNS LOOKUP"
                        />
                        <Divider />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={domain.domainHeaders}
                              onChange={handleChangeChecked}
                              name="domainHeaders"
                              color="primary"
                            />
                          }
                          label="DOMAIN HEADERS"
                        />
                        <Divider />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={domain.domainSSL}
                              onChange={handleChangeChecked}
                              name="domainSSL"
                              color="primary"
                            />
                          }
                          label="DOMAIN SSL"
                        />
                        <Divider />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={domain.tlsSsl}
                              onChange={handleChangeChecked}
                              name="tlsSsl"
                              color="primary"
                            />
                          }
                          label="TLS/SSL"
                        />
                        <Divider />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => createDomain()}
                      fullWidth
                    >
                      {LOCALE.save}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
          </CardBody>
        </Dialog>
      </Card>
    </div>
  );
}
