import Axios from "axios";
import { SETTINGS } from "../../properties/ApiProps";

export const LoginRepository = {
  createUser: (user) => {
    return Axios({
      url: SETTINGS.API_URL + "users/register",
      method: "POST",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      data: user,
    });
  },

  confirmMfaRegistration: (code, username) => {
    return Axios({
      url: SETTINGS.API_URL + "mfa/confirm",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        code: code,
        username: username,
      },
    });
  },

  mfaTokenLogin: (token, code) => {
    return Axios({
      url: SETTINGS.API_URL + "oauth/token",
      method: "POST",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        grant_type: "mfa",
        mfa_token: token,
        mfa_code: code,
      },
    });
  },

  checkMfaToken: (token) => {
    return Axios({
      url: SETTINGS.API_URL + "oauth/check_token",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        token: token,
      },
    });
  },

  testImapDetails: (data) => {
    return Axios({
      url: SETTINGS.API_URL + "mail/connect",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        imapHost: data.imapHost,
        imapUsername: data.imapEmail,
        imapPassword: data.imapPassword,
      },
    });
  },

  getResetPasswordLink: (email) => {
    return Axios({
      url: `${SETTINGS.API_URL}users/resetPassword`,
      params: { email: email },
      method: "POST",
    });
  },

  resetPassword: (token, newPassword, recaptcha) => {
    return Axios({
      url: `${SETTINGS.API_URL}users/resetPassword/${token}`,
      params: { newPassword: newPassword, "g-recaptcha-response": recaptcha },
      method: "PUT",
    });
  },
};
