import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createStore } from "redux";
import { Provider } from "react-redux";
import reducer from "./common/reducers/MainReducer";
import httpService from "./api/HttpClient";
import { Route, Redirect, BrowserRouter } from "react-router-dom";
import LoginComponent from "./modules/login/LoginComponent";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ForbidenComponent from "./modules/error/ForbidenComponent";
import MainPage from "./modules/public/MainPage";
import SpfPage from "./modules/public/SpfPage";
import DkimPage from "./modules/public/DkimPage";
import DmarcPage from "./modules/public/DmarcPage";
import ResetPasswordComponent from "./modules/login/ResetPasswordComponent";
import CookieDisclaimer from "react-cookie-disclaimer";
import CookiePage from "./modules/public/CookiePage";
import PrivacyPolicyPage from "./modules/public/PrivacyPolicyPage";

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#2b84c0",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#C0392B",
      // dark: will be calculated from palette.secondary.main,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontSize: 11,
    fontWeightLight: 300,
  },
  props: {
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: "small",
    },
    MuiTooltip: {
      arrow: true,
    },
  },
});

const store = createStore(reducer);
httpService.setupInterceptors(store);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* PUBLICK ROUTES */}
      <CookieDisclaimer
        background="#0079c1"
        bottomPosition={true}
        closeIconSize={30}
        closeIconPositionTop={true}
        color="#fff"
        cookiePolicyName="Прочитај повеќе"
        cookiePolicyLink="/cookie-policy"
        cookiePolicyText="Со понатамошно користење на оваа веб страна, вие се согласувате со нашата политика за приватност и политика за колачиња (cookies)."
        text="Оваа веб страна користи колачиња (cookies)."
      />
      <Route path="/" exact component={MainPage} />
      <Route path="/spf" exact component={SpfPage}></Route>
      <Route path="/dkim" exact component={DkimPage}></Route>
      <Route path="/dmarc" exact component={DmarcPage}></Route>
      <Route path="/cookie-policy" exact component={CookiePage}></Route>
      <Route path="/privacy-policy" exact component={PrivacyPolicyPage}></Route>

      {/* ======================= LOGIN/SECURITY ROUTES ======================= */}
      <Route path="/login" component={LoginComponent} />
      <Route
        exact={true}
        path="/resetPassword/:token"
        component={ResetPasswordComponent}
      />

      <Route
        path="/app"
        render={() =>
          !localStorage.getItem("accessToken") &&
          !localStorage.getItem("currentUser") ? (
            <Redirect to="/login" />
          ) : (
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          )
        }
      />
      {/* ======================= ERRORS ROUTE ======================= */}
      <Route exact path="/error/403" component={ForbidenComponent} />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
