import * as yup from "yup";
import { LOCALE } from "../../../properties/Locale";

export const validateCreateDomainForm = yup.object().shape({
  domainName: yup
    .string()
    .required(LOCALE.name + " на домен " + LOCALE.required_field),
});

export const validateUpdateDomainForm = yup.object().shape({
  domainName: yup
    .string()
    .required(LOCALE.name + " на домен " + LOCALE.required_field),
  emails: yup
    .string()
    //check if provided emails are valid, emails are separated by ;, allow empty string
    .matches(
      /^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+;)*([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)*$/,
      LOCALE.invalid_email_address_format
    ),
});
