import React, { useEffect, useState } from "react";

// @material-ui/core components
import { Grid, Typography, Divider, Button, Link } from "@material-ui/core";
import { Redirect } from "react-router";
// @material-ui/styles

export default function Footer(props) {
  useEffect(() => {}, []);
  const [redirectTo, setRedirectTo] = useState();
  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} />}

      <Divider></Divider>
      <Grid container>
        <Grid item md={2}></Grid>
        <Grid
          item
          md={8}
          style={{
            bottom: "0",
            left: "0",
            width: "100%",
            textAlign: "center",
          }}
        >
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            <br />
            Национален центар за одговор на компјутерски инциденти MKD-CIRT
            <br />
            Агенција за електронски комуникации
            <br />
            Кеј Димитар Влахов 21, 1000 Скопје
            <br />
            Телефон: 02 3091232
            <br />
            Факс: 02 3224611
            <br />
            Е-пошта: info@mkd-cirt.mk; contact@mkd-cirt.mk
            <br />
            <Link
              style={{ fontSize: "16px", cursor: "help" }}
              onClick={() => setRedirectTo("/privacy-policy")}
            >
              Политика на приватност
            </Link>
            <br />
            <Link
              style={{ fontSize: "16px", cursor: "help" }}
              onClick={() => setRedirectTo("/cookie-policy")}
            >
              Политика за користење на колачиња
            </Link>
          </Typography>
        </Grid>
        <Grid item md={2}></Grid>
      </Grid>
      <Grid
        item
        md={12}
        style={{
          textAlign: "center",
          bottom: "0",
          left: "0",
          width: "100%",
        }}
      >
        <Typography variant="caption" color="textSecondary" gutterBottom>
          © 2020 - Агенција за електронски комуникации. Сите права задржани
          <br />
          <br />
        </Typography>
      </Grid>
    </React.Fragment>
  );
}
