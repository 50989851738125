import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  TextField,
  CircularProgress,
  IconButton,
  Icon,
  Tab,
  Tabs,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
// @material-ui/styles
import styles from "../../assets/jss/layouts/appStyle";
import { makeStyles } from "@material-ui/core/styles";
import bgImg from "../../assets/img/bg.jpg";
import bgImg2 from "../../assets/img/bg1.jpg";
import productCurvyLines from "../../assets/img/productCurvyLines.png";
import MagicHover from "magic-hover";
import Footer from "../../common/components/Footer";

const useStyles = makeStyles(styles);

export default function MainPage(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState();

  const dispatch = useDispatch();

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} />}
      <Grid
        container
        style={{
          backgroundImage:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)),url(" +
            bgImg +
            ")",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <Grid item sm={12} xs={12}>
          <IconButton
            onClick={() => setRedirectTo("/app/")}
            style={{
              color: "white",
              float: "right",
              right: "5vh",
              top: "2vh",
              fontSize: "5vw",
            }}
            size="medium"
          >
            <Icon>login</Icon>
          </IconButton>
        </Grid>
        <Grid item sm={3} xs={false}></Grid>
        <Grid item sm={6} xs={12} style={{ textAlign: "center" }}>
          <img
            alt=""
            src={require("../../assets/img/aeklogo-1.png")}
            width="100%"
            style={{ marginTop: "10vh", opacity: "70%" }}
          ></img>
          <Typography
            variant="h3"
            style={{
              marginTop: "5vh",
              marginBottom: "10vh",
              color: "white",
              opacity: "70%",
            }}
          >
            Безбедна комуникација по <br></br>е-пошта
          </Typography>{" "}
        </Grid>
        <Grid item sm={3} xs={false}></Grid>
        <Grid item sm={2} xs={false}></Grid>
        <Grid item sm={8} xs={12}>
          <Typography
            variant="subtitle2"
            style={{
              color: "white",
              opacity: "70%",
              marginBottom: "10vh",
              textAlign: "center",
            }}
          >
            Портал за воведување на напредни технологии за безбедност на
            комуникација по електронска пошта, наменет за сите компании од јавен
            и приватен сектор. Доколку имате свој домен и користите адреси за
            е-пошта во облик ime@firma.mk, овде ќе најдете насоки за
            имплементација и користење на SPF, DKIM и DMARC. Во заштитениот дел
            од порталот фирмите можат да ги анализираат своите DMARC извештаи и
            да преземат мерки за спречување на спуфинг и лажно преставување на
            криминалците како фирмата во комуникација по е-пошта со клиентите и
            корисниците.{" "}
          </Typography>
        </Grid>
        <Grid item sm={2} xs={false}></Grid>
      </Grid>
      <Grid
        container
        style={{
          backgroundImage: "url(" + productCurvyLines + ")",
          backgroundSize: "cover",
          paddingBottom: "5vh",
          backgroundAttachment: "fixed",
        }}
      >
        <Grid item sm={4} xs={12} style={{ padding: "50px" }}>
          <Typography style={{ textAlign: "center" }}>
            <Icon style={{ fontSize: "6em", color: "#444444" }}>security</Icon>
          </Typography>
          <Typography variant="h2" style={{ textAlign: "center" }}>
            SPF
          </Typography>
          <Typography variant="subtitle2" style={{ textAlign: "center" }}>
            Sender Policy Framework
          </Typography>
          <br></br>
          <Typography variant="subtitle1" style={{ textAlign: "justify" }}>
            SPF (Sender Policy Framework) или РПИ (Рамка на политика на
            испраќање) записот е DNS запис поврзан со сигурна испорака на
            е-пошта. SPF се користи за да се идентификуваат овластените сервери
            за испраќање на пораки по е-пошта од вашиот домен. Дознајте повеќе
            за SPF на следниот линк.
          </Typography>
          <div style={{ textAlign: "center", marginTop: "2vh" }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setRedirectTo("/spf")}
            >
              Дознај повеќе
            </Button>
          </div>
        </Grid>
        <Grid item sm={4} xs={12} style={{ padding: "30px" }}>
          <Typography style={{ textAlign: "center" }}>
            <Icon style={{ fontSize: "6em", color: "#444444" }}>vpn_key</Icon>
          </Typography>
          <br></br>
          <Typography variant="h2" style={{ textAlign: "center" }}>
            DKIM
          </Typography>
          <Typography variant="subtitle2" style={{ textAlign: "center" }}>
            DomainKeys Identified Mail
          </Typography>
          <br></br>
          <Typography variant="subtitle1" style={{ textAlign: "justify" }}>
            DomainKeys Identified Mail (DKIM) е дигитален потпис додаден на
            пораките што се испраќаат од вашиот сервер за е-пошта. DKIM е
            наменет за серверот за е-пошта на примателот, кој го автентицира
            испраќачот врз основа на него и, ако е сè во ред, ја пренесува
            е-поштата до поштенското сандаче. Дознајте повеќе на следниот линк.
          </Typography>
          <div style={{ textAlign: "center", marginTop: "2vh" }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setRedirectTo("/dkim")}
            >
              Дознај повеќе
            </Button>
          </div>
        </Grid>
        <Grid
          item
          sm={4}
          xs={12}
          style={{ padding: "30px", minHeight: "300px" }}
        >
          <Typography style={{ textAlign: "center" }}>
            <Icon style={{ fontSize: "6em", color: "#444444" }}>report</Icon>
          </Typography>
          <Typography variant="h2" style={{ textAlign: "center" }}>
            DMARC
          </Typography>
          <Typography variant="subtitle2" style={{ textAlign: "center" }}>
            Domain-based Message Authentication <br></br>Reporting and
            Conformance
          </Typography>
          <br></br>
          <Typography variant="subtitle1" style={{ textAlign: "justify" }}>
            DMARC ги комбинира SPF и DKIM. Сопственикот на домен може да
            определи сопствена полиса за автентикација (позната како политика на
            DMARC). Доколку серверот за е-пошта што ги прима пораките испратени
            од вашиот меил сервер има вклучено валидација на DMARC, ја проверува
            вашата објавена полиса за DMARC и соодветно може да ги проследи,
            означи како сомнителни или одбие лажните пораки кои се маскирани
            како да се испратени од вашиот домен. Преку користење на DMARC, ќе
            имате пристап до извештаи кои ќе ви помогнат да ја подобрите
            безбедноста на вашата е-пошта и ќе имате информација во случај на
            обиди за измами.
          </Typography>
          <div style={{ textAlign: "center", marginTop: "2vh" }}>
            <Button
              variant="outlined"
              color="secondary"
              style={{ verticalAlign: "bottom" }}
              onClick={() => setRedirectTo("/dmarc")}
            >
              Дознај повеќе
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          backgroundImage:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(" +
            bgImg2 +
            ")",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundAttachment: "fixed",
        }}
      >
        <Grid item sm={3} xs={false}></Grid>
        <Grid item sm={6} xs={12} style={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            style={{
              marginTop: "12vh",
              color: "white",
              opacity: "90%",
            }}
          >
            Регистрирајте се на системот за детални извештаи за вашата е-пошта
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            style={{
              color: "white",
              marginTop: "3vh",
              marginBottom: "12vh",
            }}
            onClick={() => setRedirectTo("/login?register=true")}
          >
            Регистрирај се
          </Button>
        </Grid>
        <Grid item sm={3} xs={false}></Grid>
      </Grid>
      {/* <Grid
        container
        style={{
          backgroundImage: "url(" + productCurvyLines + ")",
          backgroundSize: "cover",
          paddingBottom: "5vh",
          backgroundAttachment: "fixed",
        }}
      >
        <Grid item sm={3} xs={false}></Grid>
        <Grid item sm={6} xs={12} style={{ textAlign: "justify" }}>
          <Typography
            variant="h4"
            style={{
              marginTop: "5vh",
              textAlign: "center",
            }}
          >
            Што е DMARC збирен извештај?
          </Typography>
          <Typography variant="subtitle1">
            Безбедноста на е-пораките никогаш не е за потценување. Колку сте
            поголем, толку повеќе ќе изгубите ако некој ги измами вашите акаунти
            и ги измами вашите клиенти, нешто што веројатно не би го одобриле.
            Со оглед на тоа колку е лесно да се додаде секој метод и колку
            добивате со тоа што сите методи ќе ги поставите правилно, има малку
            причини да не ги пробате.<br></br>
            <br></br> Она што е апсолутно добро во врска со DMARC е тоа што
            можете да започнете со полиса „none “и да набудувате што се случува.
            Ова во основа значи дека вашите е-пораки ќе поминат низ соодветните
            проверки од страната што ги прима, но ако проверките не успеат, тоа
            нема да влијае на испорака на пораката. Преку извештаите на DMARC ќе
            добиете многу информации и ќе можете брзо да идентификувате дали
            некој се обидува да го измами вашиот домен или има некој проблем кај
            вас. Користете ги овие податоци за да ги подобрите вашите процеси и
            ќе ги видите резултатите за кратко време.
          </Typography>
        </Grid>
        <Grid item sm={3} xs={false}></Grid>
      </Grid> */}
      <Footer></Footer>
    </React.Fragment>
  );
}
