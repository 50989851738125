import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Paper,
  Tab,
  Table,
  TableCell,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import styles from "../../../assets/jss/layouts/appStyle";
import { changePageTitle } from "../../../common/actions/CommonActions";
import { LOCALE } from "../../../properties/Locale";
import PropTypes from "prop-types";
import { AuthService } from "../../../auth/AuthService";
import { DomainChecksRepository } from "../../client/domain/DomainChecksRepository";
import moment from "moment";
import GaugeChart from "react-advanced-gauge-chart";
import { Alert } from "@material-ui/lab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function NewDomainCheckAdmin() {
  const useStyles = makeStyles(styles);

  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [fileDetails, setFileDetails] = useState();
  const [redirectTo, setRedirectTo] = useState();
  const dispatch = useDispatch();
  const [domain, setDomain] = useState(
    AuthService.getCurrentUser() ? AuthService.getCurrentUser().domain : ""
  );
  const [mailServerHost, setMailServerHost] = useState(
    AuthService.getCurrentUser() ? AuthService.getCurrentUser().domain : ""
  );
  const [mailServerPort, setMailServerPort] = useState("587");
  const [dkimSelector, setDkimSelector] = useState();
  const [whoIsResult, setWhoIsResult] = useState();
  const [dnsLookupResult, setDnsLookupResult] = useState();
  const [headersCheckResult, setHeadersCheckResult] = useState();
  const [headersLowerCaseHttp, setHeadersLowerCaseHttp] = useState();
  const [headersLowerCaseHttps, setHeadersLowerCaseHttps] = useState();
  const [sslResults, setSSLResults] = useState({});
  const [mailSettingsResults, setMailSettingsResults] = useState({});
  const [sslTlsResults, setSslTlsResults] = useState();

  useEffect(() => {
    console.log(AuthService.getCurrentUser());
    dispatch(changePageTitle(LOCALE.domain_checks));
  }, []);

  const handleWhois = () => {
    setLoading(true);
    DomainChecksRepository.whoisAdmin(domain)
      .then((response) => {
        console.log(response);
        setWhoIsResult(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleDnsLookup = () => {
    setLoading(true);
    DomainChecksRepository.dnsLookupAdmin(domain)
      .then((response) => {
        console.log(response);
        setDnsLookupResult(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleHeadersCheck = () => {
    setLoading(true);
    DomainChecksRepository.headersCheckAdmin(domain)
      .then((response) => {
        console.log(response);
        setHeadersCheckResult(response.data);
        convertToLowerCaseHttp(response.data.httpHeaders);
        convertToLowerCaseHttps(response.data.httpsHeaders);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const convertToLowerCaseHttp = (object) => {
    var json = JSON.stringify(object);
    var newJson = json.toLowerCase();
    setHeadersLowerCaseHttp(JSON.parse(newJson));
  };
  const convertToLowerCaseHttps = (object) => {
    var json = JSON.stringify(object);
    var newJson = json.toLowerCase();
    setHeadersLowerCaseHttps(JSON.parse(newJson));
  };

  const handleCheckSSL = () => {
    setLoading(true);
    DomainChecksRepository.sslCheckAdmin(domain)
      .then((response) => {
        console.log(response);
        setSSLResults(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleCheckMailSettings = () => {
    setLoading(true);
    DomainChecksRepository.mailSettingsCheckAdmin(
      domain,
      mailServerHost,
      mailServerPort,
      dkimSelector
    )
      .then((response) => {
        console.log(response);
        setMailSettingsResults(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleSslTlsCheck = () => {
    setLoading(true);
    DomainChecksRepository.tlsSslCheckerAdmin(domain)
      .then((response) => {
        console.log(response);
        setSslTlsResults(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const resolvePercentage = () => {
    let percentage = 0;
    Object.keys(sslTlsResults).forEach((key) => {
      if (key == "TLSv1.3" && sslTlsResults[key] == "enabled") {
        percentage += 20;
      } else if (key == "TLSv1.2" && sslTlsResults[key] == "enabled") {
        percentage += 20;
      } else if (key == "TLSv1.1" && sslTlsResults[key] == "disabled") {
        percentage += 15;
      } else if (key == "TLSv1" && sslTlsResults[key] == "disabled") {
        percentage += 15;
      } else if (key == "SSLv3" && sslTlsResults[key] == "disabled") {
        percentage += 15;
      } else if (key == "SSLv2Hello" && sslTlsResults[key] == "disabled") {
        percentage += 15;
      }
    });
    return percentage / 100;
  };
  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} />}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => {
              setTabValue(newValue);
            }}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
            centered
          >
            <Tab label="Domain whois" value={0} />
            <Tab label="Mail server" value={1} />
            <Tab label="DNS lookup" value={2} />
            <Tab label="Domain headers" value={3} />
            <Tab label="Domain Certificate" value={4} />
            <Tab label="TLS/SSL" value={5} />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  id="domain"
                  label="Domain"
                  variant="outlined"
                  fullWidth
                  // margin="normal"
                  value={domain ?? domain}
                  onChange={(event) => {
                    setDomain(event.target.value);
                  }}
                  size="small"
                  onClick={() => {}}
                />
              </Grid>
              <Grid item md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  margin="normal"
                  size="small"
                  fullWidth
                  disabled={loading ? true : false}
                  onClick={() => handleWhois()}
                >
                  Search
                </Button>
              </Grid>
              {whoIsResult && (
                <Grid item md={12}>
                  <TextField
                    id="whois-result"
                    variant="outlined"
                    fullWidth
                    // margin="normal"
                    value={whoIsResult.whois ?? whoIsResult.whois}
                    multiline
                    rows={30}
                  />
                </Grid>
              )}
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Grid container spacing={2}>
              <Grid item md={3}>
                <TextField
                  id="domain"
                  label="Domain"
                  variant="outlined"
                  fullWidth
                  // margin="normal"
                  value={domain ?? domain}
                  onChange={(event) => {
                    setDomain(event.target.value);
                  }}
                  size="small"
                  onClick={() => {}}
                />
              </Grid>
              <Grid item md={2}>
                <TextField
                  id="domain"
                  label="Dkim selector"
                  variant="outlined"
                  fullWidth
                  // margin="normal"
                  value={dkimSelector ?? dkimSelector}
                  onChange={(event) => {
                    setDkimSelector(event.target.value);
                  }}
                  size="small"
                  onClick={() => {}}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  id="domain"
                  label="Mail server host"
                  variant="outlined"
                  fullWidth
                  // margin="normal"
                  value={mailServerHost ?? mailServerHost}
                  onChange={(event) => {
                    setMailServerHost(event.target.value);
                  }}
                  size="small"
                  onClick={() => {}}
                />
              </Grid>
              <Grid item md={2}>
                <TextField
                  id="domain"
                  label="Mail server port"
                  variant="outlined"
                  fullWidth
                  // margin="normal"
                  value={mailServerPort ?? mailServerPort}
                  onChange={(event) => {
                    setMailServerPort(event.target.value);
                  }}
                  size="small"
                  onClick={() => {}}
                />
              </Grid>

              <Grid item md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  margin="normal"
                  size="small"
                  fullWidth
                  disabled={loading ? true : false}
                  onClick={() => handleCheckMailSettings()}
                >
                  Search
                </Button>
              </Grid>
              <Grid item md={12}>
                <Paper elevation={1}>
                  <Table>
                    {mailSettingsResults &&
                      Object.keys(mailSettingsResults).map((item, index) => (
                        <>
                          <TableRow
                            style={
                              index % 2 === 0
                                ? { backgroundColor: "#f5f5f5" }
                                : {
                                    backgroundColor: "#ffffff",
                                  }
                            }
                          >
                            <TableCell
                              style={{
                                backgroundColor: mailSettingsResults[
                                  item
                                ].includes("p=none")
                                  ? "#FBEEE6"
                                  : mailSettingsResults[item].includes(
                                      "p=quarantine"
                                    )
                                  ? "#FBEEE6"
                                  : mailSettingsResults[item].includes(
                                      "p=reject"
                                    )
                                  ? "#EAFAF1"
                                  : mailSettingsResults[item].includes("-all")
                                  ? "#EAFAF1"
                                  : mailSettingsResults[item].includes("+all")
                                  ? "#FBEEE6"
                                  : mailSettingsResults[item].includes("~all")
                                  ? "#EAFAF1"
                                  : "",
                              }}
                            >
                              <b>{item}</b>
                            </TableCell>
                            <TableCell
                              style={{
                                wordWrap: "break-word",
                                wordBreak: "break-all",
                                backgroundColor: mailSettingsResults[
                                  item
                                ].includes("p=none")
                                  ? "#FBEEE6"
                                  : mailSettingsResults[item].includes(
                                      "p=quarantine"
                                    )
                                  ? "#FBEEE6"
                                  : mailSettingsResults[item].includes(
                                      "p=reject"
                                    )
                                  ? "#EAFAF1"
                                  : mailSettingsResults[item].includes("-all")
                                  ? "#EAFAF1"
                                  : mailSettingsResults[item].includes("+all")
                                  ? "#FBEEE6"
                                  : mailSettingsResults[item].includes("~all")
                                  ? "#EAFAF1"
                                  : "",
                              }}
                            >
                              {mailSettingsResults[item].includes("p=none") ? (
                                <div style={{ display: "flex" }}>
                                  {mailSettingsResults[item]}
                                  <Tooltip
                                    title={
                                      "DMARC policy: p=none. Оди кон повеќе информации"
                                    }
                                  >
                                    <Link
                                      to={`/dmarc`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <Icon
                                        style={{
                                          color: "#e65100",
                                          marginLeft: 5,
                                        }}
                                      >
                                        privacy_tip
                                      </Icon>
                                    </Link>
                                  </Tooltip>
                                </div>
                              ) : mailSettingsResults[item].includes(
                                  "p=quarantine"
                                ) ? (
                                <div style={{ display: "flex" }}>
                                  {mailSettingsResults[item]}
                                  <Tooltip
                                    title={
                                      "DMARC policy: p=quarantine. Оди кон повеќе информации"
                                    }
                                  >
                                    <Link
                                      to={`/dmarc`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <Icon
                                        style={{
                                          color: "#ed6c02",
                                          marginLeft: 5,
                                        }}
                                      >
                                        privacy_tip
                                      </Icon>
                                    </Link>
                                  </Tooltip>
                                </div>
                              ) : mailSettingsResults[item].includes(
                                  "p=reject"
                                ) ? (
                                <div style={{ display: "flex" }}>
                                  {mailSettingsResults[item]}

                                  <Tooltip
                                    title={
                                      "DMARC policy: p=reject. Оди кон повеќе информации"
                                    }
                                  >
                                    <Link
                                      to={`/dmarc`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <Icon
                                        style={{
                                          color: "#2e7d32",
                                          marginLeft: 5,
                                        }}
                                      >
                                        privacy_tip
                                      </Icon>
                                    </Link>
                                  </Tooltip>
                                </div>
                              ) : mailSettingsResults[item].includes("-all") ? (
                                <div style={{ display: "flex" }}>
                                  {mailSettingsResults[item]}

                                  <Tooltip
                                    title={
                                      "Сите ненаведени сервери не се овластени (пораките ќе бидат одбиени). Оди кон повеќе информации"
                                    }
                                  >
                                    <Link
                                      to={`/spf`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <Icon
                                        style={{
                                          color: "#2e7d32",
                                          marginLeft: 5,
                                        }}
                                      >
                                        privacy_tip
                                      </Icon>
                                    </Link>
                                  </Tooltip>
                                </div>
                              ) : mailSettingsResults[item].includes("+all") ? (
                                <div style={{ display: "flex" }}>
                                  {mailSettingsResults[item]}
                                  <Tooltip
                                    title={
                                      "Секој сервер е овластен (непожелна опција) Оди кон повеќе информации"
                                    }
                                  >
                                    <Link
                                      to={`/spf`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <Icon
                                        style={{
                                          color: "#e65100",
                                          marginLeft: 5,
                                        }}
                                      >
                                        privacy_tip
                                      </Icon>
                                    </Link>
                                  </Tooltip>
                                </div>
                              ) : mailSettingsResults[item].includes("~all") ? (
                                <div style={{ display: "flex" }}>
                                  {mailSettingsResults[item]}

                                  <Tooltip
                                    title={
                                      "Сите ненаведени сервери не се авторизирани, пораките ќе бидат обележани и прифатена (во спам папката од сандачето за е-пошта). Оди кон повеќе информации"
                                    }
                                  >
                                    <Link
                                      to={`/spf`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <Icon
                                        style={{
                                          color: "#2e7d32",
                                          marginLeft: 5,
                                        }}
                                      >
                                        privacy_tip
                                      </Icon>
                                    </Link>
                                  </Tooltip>
                                </div>
                              ) : item.includes("DKIM") ? (
                                <div style={{ display: "flex" }}>
                                  {mailSettingsResults[item]}

                                  <Tooltip title={"Оди кон повеќе информации"}>
                                    <Link
                                      to={`/dkim`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {!mailSettingsResults[item].includes(
                                        "Not found, error message:"
                                      ) && (
                                        <Icon
                                          style={{
                                            color: "#9e9e9e",
                                            marginLeft: 5,
                                          }}
                                        >
                                          privacy_tip
                                        </Icon>
                                      )}
                                    </Link>
                                  </Tooltip>
                                </div>
                              ) : (
                                mailSettingsResults[item]
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                  </Table>
                </Paper>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  id="domain"
                  label="Domain"
                  variant="outlined"
                  fullWidth
                  // margin="normal"
                  value={domain ?? domain}
                  onChange={(event) => {
                    setDomain(event.target.value);
                  }}
                  size="small"
                  onClick={() => {}}
                />
              </Grid>
              <Grid item md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  margin="normal"
                  size="small"
                  fullWidth
                  onClick={() => handleDnsLookup()}
                  disabled={loading ? true : false}
                >
                  Search
                </Button>
              </Grid>
              {dnsLookupResult && (
                <Grid item md={12}>
                  <Paper elevation={2}>
                    <Table>
                      {Object.keys(dnsLookupResult).includes("Error")
                        ? Object.keys(dnsLookupResult).map((item, index) => (
                            <Table>
                              <TableRow>
                                <TableCell
                                  style={{
                                    color: "#F44336",
                                    fontSize: 16,
                                  }}
                                >
                                  <b>{item}</b>
                                </TableCell>
                                <TableCell style={{ display: "flex" }}>
                                  {dnsLookupResult[item]}
                                  <Icon
                                    color="error"
                                    style={{ marginLeft: 5, marginTop: -2 }}
                                  >
                                    error
                                  </Icon>
                                </TableCell>
                              </TableRow>
                            </Table>
                          ))
                        : Object.keys(dnsLookupResult).map((item, index) => {
                            return (
                              <TableRow
                                style={
                                  index % 2 === 0
                                    ? { backgroundColor: "#f5f5f5" }
                                    : {
                                        backgroundColor: "#ffffff",
                                      }
                                }
                              >
                                <TableCell style={{ width: "10%" }}>
                                  <b>{item.slice(0, item.length - 2)}</b>
                                </TableCell>
                                <TableCell>{dnsLookupResult[item]}</TableCell>
                              </TableRow>
                            );
                          })}
                    </Table>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  id="domain"
                  label="Domain"
                  variant="outlined"
                  fullWidth
                  // margin="normal"
                  value={domain ?? domain}
                  onChange={(event) => {
                    setDomain(event.target.value);
                  }}
                  size="small"
                  onClick={() => {}}
                />
              </Grid>
              <Grid item md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  margin="normal"
                  size="small"
                  fullWidth
                  disabled={loading ? true : false}
                  onClick={() => handleHeadersCheck()}
                >
                  Search
                </Button>
              </Grid>{" "}
              {headersCheckResult && (
                <>
                  <Grid item md={12}>
                    <Paper elevation={3}>
                      <Table>
                        {headersCheckResult?.httpsHeaders &&
                          Object.keys(headersCheckResult?.httpsHeaders).map(
                            (item, index) => (
                              <>
                                {index == 0 && (
                                  <>
                                    <TableRow
                                      style={{
                                        backgroundColor: "#e0e0e0",
                                      }}
                                    >
                                      <TableCell colSpan={2}>
                                        <b> HTTPS headers </b>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )}
                                <TableRow
                                  style={
                                    index % 2 === 0
                                      ? { backgroundColor: "#f5f5f5" }
                                      : {
                                          backgroundColor: "#ffffff",
                                        }
                                  }
                                >
                                  <TableCell
                                    style={{
                                      color:
                                        item
                                          .toLowerCase()
                                          .includes(
                                            "Content-Security-Policy".toLowerCase()
                                          ) ||
                                        item
                                          .toLowerCase()
                                          .includes(
                                            "X-Content-Type-Options".toLowerCase()
                                          ) ||
                                        item
                                          .toLowerCase()
                                          .includes(
                                            "X-FRAME-OPTIONS".toLowerCase()
                                          ) ||
                                        item
                                          .toLowerCase()
                                          .includes(
                                            "Strict-Transport-Security".toLowerCase()
                                          ) ||
                                        item
                                          .toLowerCase()
                                          .includes(
                                            "Referrer-Policy".toLowerCase()
                                          ) ||
                                        item
                                          .toLowerCase()
                                          .includes(
                                            "x-xss-protection".toLowerCase()
                                          ) ||
                                        item
                                          .toLowerCase()
                                          .includes(
                                            "Permissions-Policy".toLowerCase()
                                          )
                                          ? "#28B463"
                                          : "",
                                      fontSize: 15,
                                      width: "20%",
                                    }}
                                  >
                                    <b>{item}</b>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      wordWrap: "break-word",
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    {headersCheckResult?.httpsHeaders[item]}
                                  </TableCell>
                                </TableRow>
                              </>
                            )
                          )}
                      </Table>
                      {!Object.keys(headersCheckResult?.httpsHeaders).includes(
                        "Error getting HTTPS headers"
                      ) ? (
                        <Table>
                          <TableRow
                            style={{
                              backgroundColor: "#e0e0e0",
                            }}
                          >
                            <TableCell colSpan={2}>
                              <b>Missing Headers</b>
                            </TableCell>
                          </TableRow>

                          {headersLowerCaseHttps &&
                            !Object.keys(headersLowerCaseHttps).includes(
                              "Referrer-Policy".toLowerCase()
                            ) && (
                              <TableRow>
                                <TableCell
                                  colSpan={1}
                                  style={{ color: "#D50000", border: "none" }}
                                >
                                  <b>{"Referrer-Policy"}</b>
                                </TableCell>
                                <TableCell
                                  colSpan={1}
                                  style={{
                                    wordWrap: "break-word",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {
                                    "Referrer Policy is a new header that allows a site to control how much information the browser includes with navigations away from a document and should be set by all sites."
                                  }
                                </TableCell>
                              </TableRow>
                            )}
                          {headersLowerCaseHttps &&
                            !Object.keys(headersLowerCaseHttps).includes(
                              "Permissions-Policy".toLowerCase()
                            ) && (
                              <TableRow>
                                <TableCell
                                  colSpan={1}
                                  style={{ color: "#D50000", border: "none" }}
                                >
                                  <b>{"Permissions-Policy"}</b>
                                </TableCell>
                                <TableCell
                                  colSpan={1}
                                  style={{
                                    wordWrap: "break-word",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {
                                    "Permissions Policy is a new header that allows a site to control which features and APIs can be used in the browser."
                                  }
                                </TableCell>
                              </TableRow>
                            )}
                          {headersLowerCaseHttps &&
                            !Object.keys(headersLowerCaseHttps).includes(
                              "Strict-Transport-Security".toLowerCase()
                            ) && (
                              <TableRow>
                                <TableCell
                                  colSpan={1}
                                  style={{ color: "#D50000", border: "none" }}
                                >
                                  <b>{"Strict-Transport-Security"}</b>
                                </TableCell>
                                <TableCell
                                  colSpan={1}
                                  style={{
                                    wordWrap: "break-word",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {
                                    "HTTP Strict Transport Security is an excellent feature to support on your site and strengthens your implementation of TLS by getting the User Agent to enforce the use of HTTPS. Recommended value 'Strict-Transport-Security: max-age=31536000; includeSubDomains'"
                                  }
                                </TableCell>
                              </TableRow>
                            )}
                          {headersLowerCaseHttps &&
                            !Object.keys(headersLowerCaseHttps).includes(
                              "X-Frame-Options".toLowerCase()
                            ) && (
                              <TableRow>
                                <TableCell
                                  colSpan={1}
                                  style={{ color: "#D50000", border: "none" }}
                                >
                                  <b>{"X-Frame-Options"}</b>
                                </TableCell>
                                <TableCell
                                  colSpan={1}
                                  style={{
                                    wordWrap: "break-word",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {
                                    "X-Frame-Options tells the browser whether you want to allow your site to be framed or not. By preventing a browser from framing your site you can defend against attacks like clickjacking. Recommended value 'X-Frame-Options: SAMEORIGIN'."
                                  }
                                </TableCell>
                              </TableRow>
                            )}
                          {headersLowerCaseHttps &&
                            !Object.keys(headersLowerCaseHttps).includes(
                              "X-Content-Type-Options".toLowerCase()
                            ) && (
                              <TableRow>
                                <TableCell
                                  colSpan={1}
                                  style={{ color: "#D50000", border: "none" }}
                                >
                                  <b>{"X-Content-Type-Options"}</b>
                                </TableCell>
                                <TableCell
                                  colSpan={1}
                                  style={{
                                    wordWrap: "break-word",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {
                                    "	X-Content-Type-Options stops a browser from trying to MIME-sniff the content type and forces it to stick with the declared content-type. The only valid value for this header is 'X-Content-Type-Options: nosniff'."
                                  }
                                </TableCell>
                              </TableRow>
                            )}
                          {headersLowerCaseHttps &&
                            !Object.keys(headersLowerCaseHttps).includes(
                              "Content-Security-Policy".toLowerCase()
                            ) && (
                              <TableRow>
                                <TableCell
                                  colSpan={1}
                                  style={{ color: "#D50000", border: "none" }}
                                >
                                  <b>{"Content-Security-Policy"}</b>
                                </TableCell>
                                <TableCell
                                  colSpan={1}
                                  style={{
                                    wordWrap: "break-word",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {
                                    "Content Security Policy is an effective measure to protect your site from XSS attacks. By whitelisting sources of approved content, you can prevent the browser from loading malicious assets."
                                  }
                                </TableCell>
                              </TableRow>
                            )}
                          {headersLowerCaseHttps &&
                            !Object.keys(headersLowerCaseHttps).includes(
                              "x-xss-protection".toLowerCase()
                            ) && (
                              <TableRow>
                                <TableCell
                                  colSpan={1}
                                  style={{ color: "#D50000", border: "none" }}
                                >
                                  <b>{"x-xss-protection"}</b>
                                </TableCell>
                                <TableCell
                                  colSpan={1}
                                  style={{
                                    wordWrap: "break-word",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {
                                    "X-XSS-Protection sets the configuration for the XSS Auditor built into older browsers. The recommended value was 'X-XSS-Protection: 1; mode=block'"
                                  }
                                </TableCell>
                              </TableRow>
                            )}
                          <TableRow>
                            <TableCell colSpan={2}></TableCell>
                          </TableRow>
                        </Table>
                      ) : (
                        ""
                      )}
                    </Paper>
                  </Grid>
                  <Grid item md={12}>
                    <Paper elevation={3}>
                      <Table>
                        {headersCheckResult?.httpHeaders &&
                          Object.keys(headersCheckResult?.httpHeaders).map(
                            (item, index) => (
                              <>
                                {index == 0 && (
                                  <>
                                    <TableRow
                                      style={{
                                        backgroundColor: "#e0e0e0",
                                      }}
                                    >
                                      <TableCell colSpan={2}>
                                        <b>HTTP headers</b>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )}
                                <TableRow
                                  style={
                                    index % 2 === 0
                                      ? { backgroundColor: "#f5f5f5" }
                                      : {
                                          backgroundColor: "#ffffff",
                                        }
                                  }
                                >
                                  <TableCell
                                    style={{
                                      color:
                                        item
                                          .toLowerCase()
                                          .includes(
                                            "Content-Security-Policy".toLowerCase()
                                          ) ||
                                        item
                                          .toLowerCase()
                                          .includes(
                                            "X-Content-Type-Options".toLowerCase()
                                          ) ||
                                        item
                                          .toLowerCase()
                                          .includes(
                                            "X-FRAME-OPTIONS".toLowerCase()
                                          ) ||
                                        item
                                          .toLowerCase()
                                          .includes(
                                            "Strict-Transport-Security".toLowerCase()
                                          ) ||
                                        item
                                          .toLowerCase()
                                          .includes(
                                            "x-xss-protection".toLowerCase()
                                          ) ||
                                        item
                                          .toLowerCase()
                                          .includes(
                                            "Referrer-Policy".toLowerCase()
                                          ) ||
                                        item
                                          .toLowerCase()
                                          .includes(
                                            "Permissions-Policy".toLowerCase()
                                          )
                                          ? "#28B463"
                                          : "",
                                      fontSize: 15,
                                      width: "20%",
                                    }}
                                  >
                                    <b> {item}</b>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      wordWrap: "break-word",
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    {headersCheckResult?.httpHeaders[item]}
                                  </TableCell>
                                </TableRow>
                              </>
                            )
                          )}
                      </Table>
                      {!Object.keys(headersCheckResult?.httpHeaders).includes(
                        "Error getting HTTP headers"
                      ) ? (
                        <Table>
                          <TableRow
                            style={{
                              backgroundColor: "#e0e0e0",
                            }}
                          >
                            <TableCell colSpan={2}>
                              <b>Missing Headers</b>
                            </TableCell>
                          </TableRow>
                          {headersLowerCaseHttp &&
                            !Object.keys(headersLowerCaseHttp).includes(
                              "Referrer-Policy".toLowerCase()
                            ) && (
                              <TableRow>
                                <TableCell
                                  colSpan={1}
                                  style={{ color: "#D50000", border: "none" }}
                                >
                                  <b>{"Referrer-Policy"}</b>
                                </TableCell>
                                <TableCell
                                  colSpan={1}
                                  style={{
                                    wordWrap: "break-word",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {
                                    "Referrer Policy is a new header that allows a site to control how much information the browser includes with navigations away from a document and should be set by all sites."
                                  }
                                </TableCell>
                              </TableRow>
                            )}
                          {headersLowerCaseHttp &&
                            !Object.keys(headersLowerCaseHttp).includes(
                              "Permissions-Policy".toLowerCase()
                            ) && (
                              <TableRow>
                                <TableCell
                                  colSpan={1}
                                  style={{ color: "#D50000", border: "none" }}
                                >
                                  <b>{"Permissions-Policy"}</b>
                                </TableCell>
                                <TableCell
                                  colSpan={1}
                                  style={{
                                    wordWrap: "break-word",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {
                                    "Permissions Policy is a new header that allows a site to control which features and APIs can be used in the browser."
                                  }
                                </TableCell>
                              </TableRow>
                            )}

                          {headersLowerCaseHttp &&
                            !Object.keys(headersLowerCaseHttp).includes(
                              "X-Frame-Options".toLowerCase()
                            ) && (
                              <TableRow>
                                <TableCell
                                  colSpan={1}
                                  style={{ color: "#D50000", border: "none" }}
                                >
                                  <b>{"X-Frame-Options"}</b>
                                </TableCell>
                                <TableCell
                                  colSpan={1}
                                  style={{
                                    wordWrap: "break-word",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {
                                    "X-Frame-Options tells the browser whether you want to allow your site to be framed or not. By preventing a browser from framing your site you can defend against attacks like clickjacking. Recommended value 'X-Frame-Options: SAMEORIGIN'."
                                  }
                                </TableCell>
                              </TableRow>
                            )}
                          {headersLowerCaseHttp &&
                            !Object.keys(headersLowerCaseHttp).includes(
                              "X-Content-Type-Options".toLowerCase()
                            ) && (
                              <TableRow>
                                <TableCell
                                  colSpan={1}
                                  style={{ color: "#D50000", border: "none" }}
                                >
                                  <b>{"X-Content-Type-Options"}</b>
                                </TableCell>
                                <TableCell
                                  colSpan={1}
                                  style={{
                                    wordWrap: "break-word",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {
                                    "	X-Content-Type-Options stops a browser from trying to MIME-sniff the content type and forces it to stick with the declared content-type. The only valid value for this header is 'X-Content-Type-Options: nosniff'."
                                  }
                                </TableCell>
                              </TableRow>
                            )}
                          {headersLowerCaseHttp &&
                            !Object.keys(headersLowerCaseHttp).includes(
                              "Strict-Transport-Security".toLowerCase()
                            ) && (
                              <TableRow>
                                <TableCell
                                  colSpan={1}
                                  style={{ color: "#D50000", border: "none" }}
                                >
                                  <b>{"Strict-Transport-Security"}</b>
                                </TableCell>
                                <TableCell
                                  colSpan={1}
                                  style={{
                                    wordWrap: "break-word",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {
                                    "HTTP Strict Transport Security is an excellent feature to support on your site and strengthens your implementation of TLS by getting the User Agent to enforce the use of HTTPS. Recommended value 'Strict-Transport-Security: max-age=31536000; includeSubDomains'"
                                  }
                                </TableCell>
                              </TableRow>
                            )}
                          {headersLowerCaseHttp &&
                            !Object.keys(headersLowerCaseHttp).includes(
                              "Content-Security-Policy".toLowerCase()
                            ) && (
                              <TableRow>
                                <TableCell
                                  colSpan={1}
                                  style={{ color: "#D50000", border: "none" }}
                                >
                                  <b>{"Content-Security-Policy"}</b>
                                </TableCell>
                                <TableCell
                                  colSpan={1}
                                  style={{
                                    wordWrap: "break-word",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {
                                    "Content Security Policy is an effective measure to protect your site from XSS attacks. By whitelisting sources of approved content, you can prevent the browser from loading malicious assets."
                                  }
                                </TableCell>
                              </TableRow>
                            )}
                          {headersLowerCaseHttp &&
                            !Object.keys(headersLowerCaseHttp).includes(
                              "x-xss-protection".toLowerCase()
                            ) && (
                              <TableRow>
                                <TableCell
                                  colSpan={1}
                                  style={{ color: "#D50000", border: "none" }}
                                >
                                  <b>{"x-xss-protection"}</b>
                                </TableCell>
                                <TableCell
                                  colSpan={1}
                                  style={{
                                    wordWrap: "break-word",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {
                                    "X-XSS-Protection sets the configuration for the XSS Auditor built into older browsers. The recommended value was 'X-XSS-Protection: 1; mode=block'"
                                  }
                                </TableCell>
                              </TableRow>
                            )}
                          <TableRow>
                            <TableCell colSpan={2}></TableCell>
                          </TableRow>
                        </Table>
                      ) : (
                        ""
                      )}
                    </Paper>
                  </Grid>
                </>
              )}
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  id="domain"
                  label="Domain"
                  variant="outlined"
                  fullWidth
                  // margin="normal"
                  value={domain ?? domain}
                  onChange={(event) => {
                    setDomain(event.target.value);
                  }}
                  size="small"
                  onClick={() => {}}
                />
              </Grid>
              <Grid item md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  margin="normal"
                  size="small"
                  fullWidth
                  onClick={() => handleCheckSSL()}
                  disabled={loading ? true : false}
                >
                  Search
                </Button>
              </Grid>
              <Grid item md={12}>
                <Paper elevation={3}>
                  <Table>
                    {Object.keys(sslResults).includes("Error")
                      ? Object.keys(sslResults).map((item, index) => (
                          <Table>
                            <TableRow>
                              <TableCell
                                style={{ color: "#F44336", fontSize: 16 }}
                              >
                                <b>{item}</b>
                              </TableCell>
                              <TableCell style={{ display: "flex" }}>
                                {sslResults[item]}
                                <Icon
                                  color="error"
                                  style={{ marginLeft: 5, marginTop: -2 }}
                                >
                                  error
                                </Icon>
                              </TableCell>
                            </TableRow>
                          </Table>
                        ))
                      : Object.keys(sslResults).map((item, index) =>
                          Object.keys(sslResults[item]).map((item2, index2) => {
                            let isExpiringSoon = false;
                            let isExpired = false;

                            if (item2.includes("Valid until")) {
                              //check if certificate is expiring soon (40 days)
                              const validUntil = moment(
                                sslResults[item][item2],
                                "DD/MM/YYYY HH:mm:ss"
                              );
                              const date = moment();
                              const diff = validUntil.diff(date, "days");
                              if (diff <= 40) {
                                isExpiringSoon = true;
                              }
                              if (diff <= 0) {
                                isExpired = true;
                              }
                            }
                            return (
                              <>
                                {index2 === 0 && (
                                  <TableRow
                                    style={{
                                      backgroundColor: "#e0e0e0",
                                    }}
                                  >
                                    <TableCell colSpan={2}>
                                      <b>{item}</b>
                                    </TableCell>
                                  </TableRow>
                                )}
                                <TableRow
                                  style={
                                    index2 % 2 === 0
                                      ? { backgroundColor: "#f5f5f5" }
                                      : {
                                          backgroundColor: "#ffffff",
                                        }
                                  }
                                >
                                  <TableCell
                                    style={{
                                      minWidth: "200px",
                                      backgroundColor: isExpiringSoon
                                        ? "#FBEEE6"
                                        : isExpired
                                        ? "#FADBD8"
                                        : "",
                                    }}
                                  >
                                    <b>{item2}</b>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      wordWrap: "break-word",
                                      wordBreak: "break-all",
                                      backgroundColor: isExpiringSoon
                                        ? "#FBEEE6"
                                        : isExpired
                                        ? "#FADBD8"
                                        : "",
                                    }}
                                  >
                                    {item2.includes("Valid until") ? (
                                      isExpiringSoon === true ? (
                                        <div style={{ display: "inline" }}>
                                          {sslResults[item][item2]}
                                          <Tooltip title="Сертификатот истекува наскоро">
                                            <Icon
                                              style={{
                                                color: "#ed6c02",
                                                marginLeft: 15,
                                                marginBottom: -5,
                                              }}
                                            >
                                              error
                                            </Icon>
                                          </Tooltip>
                                        </div>
                                      ) : isExpired === true ? (
                                        <div style={{ display: "inline" }}>
                                          {sslResults[item][item2]}
                                          <Tooltip title="Истечен сертификат">
                                            <Icon
                                              style={{
                                                color: "#c62828",
                                                marginLeft: 15,
                                                marginBottom: -5,
                                              }}
                                            >
                                              error
                                            </Icon>
                                          </Tooltip>
                                        </div>
                                      ) : (
                                        sslResults[item][item2]
                                      )
                                    ) : (
                                      sslResults[item][item2]
                                    )}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                        )}
                  </Table>
                </Paper>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  id="domain"
                  label="Domain"
                  variant="outlined"
                  fullWidth
                  // margin="normal"
                  value={domain ?? domain}
                  onChange={(event) => {
                    setDomain(event.target.value);
                  }}
                  size="small"
                  onClick={() => {}}
                />
              </Grid>
              <Grid item md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  margin="normal"
                  size="small"
                  fullWidth
                  onClick={() => handleSslTlsCheck()}
                  disabled={loading ? true : false}
                >
                  Search
                </Button>
              </Grid>
              {sslTlsResults && (
                <>
                  <Grid item md={12}>
                    <Grid container>
                      {Object.keys(sslTlsResults).includes("Error") ? (
                        <>
                          <Grid item md={6}>
                            <GaugeChart
                              id="gauge-chart3"
                              percent={resolvePercentage()}
                              nrOfLevels={5}
                              colors={[
                                "#EA4228",
                                "rgb(234 91 40)",
                                "rgb(242 122 35)",
                                "#F5CD19",
                                "#5BE12C",
                              ]}
                              hideText={true}
                              textColor="rgb(19 19 19)"
                              animDelay={0}
                            />
                          </Grid>
                          <Grid item md={6}>
                            {Object.keys(sslTlsResults).map((item, index) => (
                              <Table>
                                <TableRow>
                                  <TableCell
                                    style={{ color: "#F44336", fontSize: 16 }}
                                  >
                                    <b>{item}</b>
                                  </TableCell>
                                  <TableCell style={{ display: "flex" }}>
                                    {sslTlsResults[item]}
                                    <Icon
                                      color="error"
                                      style={{ marginLeft: 5, marginTop: -2 }}
                                    >
                                      error
                                    </Icon>
                                  </TableCell>
                                </TableRow>
                              </Table>
                            ))}
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item md={6}>
                            <GaugeChart
                              id="gauge-chart3"
                              percent={resolvePercentage()}
                              nrOfLevels={5}
                              colors={[
                                "#EA4228",
                                "rgb(234 91 40)",
                                "rgb(242 122 35)",
                                "#F5CD19",
                                "#5BE12C",
                              ]}
                              hideText={true}
                              textColor="rgb(19 19 19)"
                              // style={{ width: "600px" }}
                              animDelay={0}
                            />
                          </Grid>
                          <Grid item md={6}>
                            {sslTlsResults["TLSv1.3"] == "enabled" && (
                              <Alert severity="success">
                                Great! The tested resource is running on the
                                latest TLS 1.3.
                              </Alert>
                            )}
                            <Typography variant="caption">
                              Enabled TLS protocol versions
                            </Typography>
                            <Table>
                              {sslTlsResults &&
                                Object.keys(sslTlsResults).map(
                                  (item, index) => (
                                    <>
                                      {item.includes("TLS") && (
                                        <TableRow
                                          style={
                                            index % 2 === 0
                                              ? { backgroundColor: "#f5f5f5" }
                                              : {
                                                  backgroundColor: "#ffffff",
                                                }
                                          }
                                        >
                                          <TableCell width="300px">
                                            {item}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              color:
                                                item == "TLSv1.3" &&
                                                sslTlsResults[item] == "enabled"
                                                  ? "green"
                                                  : item == "TLSv1.2" &&
                                                    sslTlsResults[item] ==
                                                      "enabled"
                                                  ? "green"
                                                  : item == "TLSv1.1" &&
                                                    sslTlsResults[item] ==
                                                      "disabled"
                                                  ? "green"
                                                  : item == "TLSv1" &&
                                                    sslTlsResults[item] ==
                                                      "disabled"
                                                  ? "green"
                                                  : item == "SSLv3" &&
                                                    sslTlsResults[item] ==
                                                      "disabled"
                                                  ? "green"
                                                  : item == "SSLv2Hello" &&
                                                    sslTlsResults[item] ==
                                                      "disabled"
                                                  ? "green"
                                                  : "red",
                                            }}
                                          >
                                            {sslTlsResults[item]}
                                          </TableCell>
                                        </TableRow>
                                      )}{" "}
                                    </>
                                  )
                                )}
                            </Table>
                            <Typography variant="caption">
                              Enabled SSL protocol versions
                            </Typography>
                            <Table>
                              {sslTlsResults &&
                                Object.keys(sslTlsResults).map(
                                  (item, index) => (
                                    <>
                                      {item.includes("SSL") && (
                                        <TableRow
                                          style={
                                            index % 2 === 0
                                              ? { backgroundColor: "#f5f5f5" }
                                              : {
                                                  backgroundColor: "#ffffff",
                                                }
                                          }
                                        >
                                          <TableCell width="300px">
                                            {item}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              color:
                                                item == "TLSv1.3" &&
                                                sslTlsResults[item] == "enabled"
                                                  ? "green"
                                                  : item == "TLSv1.2" &&
                                                    sslTlsResults[item] ==
                                                      "enabled"
                                                  ? "green"
                                                  : item == "TLSv1.1" &&
                                                    sslTlsResults[item] ==
                                                      "disabled"
                                                  ? "green"
                                                  : item == "TLSv1" &&
                                                    sslTlsResults[item] ==
                                                      "disabled"
                                                  ? "green"
                                                  : item == "SSLv3" &&
                                                    sslTlsResults[item] ==
                                                      "disabled"
                                                  ? "green"
                                                  : item == "SSLv2Hello" &&
                                                    sslTlsResults[item] ==
                                                      "disabled"
                                                  ? "green"
                                                  : "red",
                                            }}
                                          >
                                            {sslTlsResults[item]}
                                          </TableCell>
                                        </TableRow>
                                      )}{" "}
                                    </>
                                  )
                                )}
                            </Table>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
