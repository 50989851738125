import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Redirect, useParams } from "react-router-dom";
// @material-ui/core components
import {
  Grid,
  Typography,
  TextField,
  Link,
  CircularProgress,
  Hidden,
  Button,
  Paper,
  Avatar,
  IconButton,
  InputAdornment,
  Icon,
  Divider,
} from "@material-ui/core";
// @material-ui/styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/layouts/appStyle";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PersonIcon from "@material-ui/icons/Person";
import SignUpIcon from "@material-ui/icons/PersonAdd";
import LockIcon from "@material-ui/icons/Lock";
// core components
import { changeLanguage, LOCALE } from "../../properties/Locale";
import {
  notifyShowSuccessMessage,
  notifyShowErrorMessage,
  COMMON_ACTIONS,
} from "../../common/actions/CommonActions";
import { AuthService } from "../../auth/AuthService";
import Snackbar from "../../common/components/Snackbar/Snackbar.js";
import { validateLoginForm, validateCreateForm } from "./LoginValidations";
import { AuthRepository } from "../../auth/AuthRepository";
import { LoginRepository } from "./LoginRepository";
import EmailIcon from "@material-ui/icons/Email";
import DnsIcon from "@material-ui/icons/Dns";
import QRCode from "react-qr-code";
import { Alert, AlertTitle } from "@material-ui/lab";
import { SettingsRepository } from "../administration/settings/SettingsRepository";

const useStyles = makeStyles(styles);

export default function LoginComponent(props) {
  const classes = useStyles();
  const counter = useSelector((state) => state);
  const {
    handleSubmit: loginUserForm,
    register: registerLoginUser,
    errors: errorsLoginUser,
  } = useForm({
    validationSchema: validateLoginForm,
  });

  const {
    handleSubmit: createUserForm,
    register: registerCreateUser,
    errors: errorsCreateUser,
    getValues,
    watch,
  } = useForm({
    mode: "onChange",
    validationSchema: validateCreateForm,
  });

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(counter.loading);
  const [redirect, setRedirect] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(0);
  const [isDataValid, setIsDataValid] = useState(false);

  // const watchImapHost = watch("imapHost", false);
  // const watchImapEmail = watch("imapEmail", false);
  // const watchImapPassword = watch("imapPassword", false);
  const [createdUser, setCreatedUser] = React.useState();
  const [qrCode, setQrCode] = React.useState();
  const [mfaValidationError, setMfaValidationError] = React.useState();
  const [mfaToken, setMfaToken] = React.useState();
  const [isMfaEnabled, setIsMfaEnabled] = React.useState();
  const [resetPasswordEmail, setResetPasswordEmail] = useState();

  useEffect(() => {
    SettingsRepository.isMfaEnabled().then(
      (res) => {
        setIsMfaEnabled(res.data.value);
      },
      (err) => {}
    );
    if (window.location.search.includes("register=true")) {
      setStep(1);
    }
  }, []);

  useEffect(() => {
    setQrCode("");
    setMfaValidationError("");
  }, [step]);

  // useEffect(() => {
  //   setIsDataValid(false);
  // }, [watchImapHost, watchImapEmail, watchImapPassword]);

  const handleChangeLocale = (e) => {
    changeLanguage(e);
  };

  const onLoginUser = (values) => {
    setLoading(true);
    AuthRepository.fetchToken(values).then(
      (res) => {
        AuthService.setToken(res.data);
        setTimeout(function () {
          setRedirect("/app/dashboard");
        }, 500);
      },
      (err) => {
        setLoading(false);
        if (err?.response?.data?.error === "mfa_required") {
          setMfaToken(err.response.data.mfa_token);
          LoginRepository.checkMfaToken(err.response.data.mfa_token).then(
            (res) => {
              if (res.data.authorities[0].includes("PRE_AUTH")) {
                setStep(3);
              }
            },
            (err) => {}
          );
        } else {
          if (err?.response?.data?.error_description) {
            if (
              err?.response?.data?.error_description.includes("Bad credentials")
            ) {
              dispatch(notifyShowErrorMessage("Невалидна лозинка"));
            } else {
              dispatch(
                notifyShowErrorMessage(err.response.data.error_description)
              );
            }
          } else if (err?.response?.data?.error) {
            if (err?.response?.data?.error.includes("unauthorized")) {
              dispatch(
                notifyShowErrorMessage(
                  "Корисникот не постои или сеуште не е одобрен од операторите"
                )
              );
            } else {
              dispatch(notifyShowErrorMessage(err?.response?.data?.error));
            }
          }
        }
      }
    );
  };

  // const onCreateUser = (values) => {
  //   setLoading(true);
  //   LoginRepository.testImapDetails(values).then(
  //     (res) => {
  //       setIsDataValid(true);
  //       setLoading(false);
  //     },
  //     (err) => {
  //       if (
  //         err?.response?.data?.message?.includes("Couldn't connect to host")
  //       ) {
  //         dispatch(notifyShowErrorMessage("Непостоечки IMAP домен."));
  //       } else if (
  //         err?.response?.data?.message?.includes("Authentication failed.")
  //       ) {
  //         dispatch(
  //           notifyShowErrorMessage("Невалидна IMAP е-пошта или лозника.")
  //         );
  //       } else {
  //         dispatch(
  //           notifyShowErrorMessage(
  //             err?.response?.data?.message
  //               ? err?.response?.data?.message
  //               : "Error"
  //           )
  //         );
  //       }
  //       setLoading(false);
  //     }
  //   );
  // };

  const handleSubmit = () => {
    setLoading(true);
    var validFormData = true;
    if (!validateCreateForm.isValidSync(getValues())) {
      validFormData = false;
    }
    if (validFormData) {
      let values = getValues();
      values.type = "CLIENT";
      LoginRepository.createUser(values).then(
        (res) => {
          dispatch(
            notifyShowSuccessMessage(
              LOCALE.login_createuser_success + res.data.username
            )
          );
          setTimeout(function () {
            if (isMfaEnabled.includes("false")) {
              setStep(0);
            } else {
              setCreatedUser(res.data);
              setStep(2);
            }
            setLoading(false);
          }, 700);
        },
        (err) => {
          setLoading(false);
          dispatch(notifyShowErrorMessage(err?.response?.data));
        }
      );
    } else {
      setLoading(false);
    }
  };

  const handleClose = () => {
    if (counter.open) {
      dispatch({
        type: COMMON_ACTIONS.CLEAR_NOTIFICATIONS,
        payload: null,
      });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const confirmMfaRegistration = () => {
    if (qrCode !== undefined && qrCode !== "") {
      LoginRepository.confirmMfaRegistration(qrCode, createdUser.username).then(
        (res) => {
          dispatch(notifyShowSuccessMessage(LOCALE.confirmMfaRegistration));
          setStep(0);
        },
        (err) => {
          setMfaValidationError(err.response?.data);
          dispatch(notifyShowErrorMessage(LOCALE.invalidCode));
        }
      );
    } else {
      setMfaValidationError("Внесете го кодот");
    }
  };

  const mfaLogin = () => {
    LoginRepository.mfaTokenLogin(mfaToken, qrCode).then(
      (res) => {
        AuthService.setToken(res.data);
        setTimeout(function () {
          setRedirect("/app/dashboard");
        }, 500);
      },
      (err) => {
        setMfaValidationError(err.response.data.error_description);
        dispatch(notifyShowErrorMessage(err.response.data.error_description));
      }
    );
  };

  const forgotPassword = () => {
    LoginRepository.getResetPasswordLink(resetPasswordEmail).then((res) => {
      dispatch(
        notifyShowSuccessMessage(LOCALE.succesfuly_send_password_reset_mail)
      );
    });
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <div className={classes.loginWrapper}>
      <Hidden smDown implementation="css">
        <div className={classes.loginLeftPanel}>
          <IconButton
            onClick={() => setRedirect("/")}
            style={{
              color: "white",
              float: "left",
              padding: "20px",
              position: "absolute",
              left: 0,
            }}
            size="medium"
          >
            <Icon>arrow_back_ios</Icon>
          </IconButton>
          <img
            alt=""
            src={require("../../assets/img/aeklogo.png")}
            width="300px"
            style={{ marginTop: "15%", opacity: "50%" }}
          ></img>
          <Typography component="h1" variant="h2" style={{ opacity: "60%" }}>
            <p style={{ fontSize: "18pt" }}>{LOCALE.login_h1}</p>
            {LOCALE.login_h2}
          </Typography>
        </div>
      </Hidden>

      <div className={classes.loginRightPanel}>
        <Hidden mdUp implementation="css">
          <div style={{ padding: "10px" }}>
            <IconButton
              onClick={() => setRedirect("/")}
              style={{
                color: "white",
                float: "left",
                padding: "20px",
                position: "absolute",
                left: 0,
              }}
              size="medium"
            >
              <Icon>arrow_back_ios</Icon>
            </IconButton>
            <img
              alt=""
              src={require("../../assets/img/aeklogo.png")}
              width="70px"
              style={{ opacity: "50%" }}
            ></img>
            <Typography component="h1" variant="h2" style={{ opacity: "60%" }}>
              <p style={{ fontSize: "7pt" }}>{LOCALE.login_h1}</p>
              <p style={{ fontSize: "10pt" }}>{LOCALE.login_h2}</p>
            </Typography>
          </div>
        </Hidden>
        {/* Login step */}
        {step === 0 && (
          <Paper className={classes.paperLogin}>
            <Grid container className={classes.gridMargin}>
              <Grid item xs={3}></Grid>
              <Grid item xs={6}>
                <Avatar className={classes.avatarLogin}>
                  <LockOutlinedIcon />
                </Avatar>
                <h2 className={classes.cardTitleGray}>{LOCALE.login_signin}</h2>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
            <div className={classes.panelLogin}>
              <form onSubmit={loginUserForm(onLoginUser)}>
                <div>
                  <TextField
                    variant="outlined"
                    error={errorsLoginUser?.username}
                    helperText={errorsLoginUser?.username?.message}
                    margin="normal"
                    fullWidth
                    label={LOCALE.login_username}
                    name="username"
                    autoFocus
                    autoComplete="off"
                    inputRef={registerLoginUser}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    error={errorsLoginUser?.password}
                    helperText={errorsLoginUser?.password?.message}
                    fullWidth
                    name="password"
                    autoComplete="off"
                    label={LOCALE.login_password}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    inputRef={registerLoginUser}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div style={{ marginTop: "3%", marginBottom: "3%" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    color="primary"
                    disabled={loading}
                  >
                    {loading && (
                      <CircularProgress size={24} style={{ color: "white" }} />
                    )}
                    {!loading && LOCALE.login_signin}
                  </Button>
                </div>
              </form>
              <Grid
                container
                style={{ marginTop: "15px", paddingBottom: "70px" }}
              >
                <Grid item xs style={{ textAlign: "left" }}>
                  <Link
                    variant="body2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setStep(4)}
                  >
                    {LOCALE.login_forgot_password_link}
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    variant="body2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setStep(1)}
                  >
                    {LOCALE.login_signup_link}
                  </Link>
                </Grid>
              </Grid>
              {/* <Grid container style={{ marginTop: "25px" }}>
              <Grid item xs={9}></Grid>
              <Grid item xs>
                <Link
                  href="#"
                  variant="body2"
                  onClick={() => handleChangeLocale("mk")}
                >
                  МК
                </Link>
              </Grid>
              <Grid item xs>
                <Link
                  href="#"
                  variant="body2"
                  onClick={() => handleChangeLocale("al")}
                >
                  AL
                </Link>
              </Grid>
            </Grid> */}
            </div>
          </Paper>
        )}
        {step === 1 && (
          <Paper className={classes.paperLogin}>
            <Grid container className={classes.gridMargin1}>
              <Grid item xs={3}>
                <IconButton
                  style={{
                    color: "black",
                  }}
                  onClick={() => setStep(0)}
                >
                  <Icon>arrow_back_ios</Icon>
                </IconButton>
              </Grid>
              <Grid item xs={6}>
                <Avatar className={classes.avatarLogin}>
                  <SignUpIcon />
                </Avatar>
                <h2 className={classes.cardTitleGray}>{LOCALE.login_signup}</h2>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
            <div className={classes.panelLogin}>
              <form onSubmit={createUserForm(handleSubmit)}>
                <Paper variant="outlined">
                  <Typography variant="h6">Основни податоци</Typography>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                    >
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={LOCALE.login_name}
                        placeholder="Агенција за електронски комуникации"
                        name="name"
                        autoComplete="off"
                        size="small"
                        error={errorsCreateUser?.name}
                        helperText={errorsCreateUser?.name?.message}
                        inputRef={registerCreateUser}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                    >
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={LOCALE.login_username}
                        name="username"
                        autoComplete="off"
                        size="small"
                        error={errorsCreateUser?.username}
                        helperText={errorsCreateUser?.username?.message}
                        inputRef={registerCreateUser}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                    >
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={LOCALE.email}
                        name="email"
                        size="small"
                        autoComplete="off"
                        inputRef={registerCreateUser}
                        error={errorsCreateUser?.email}
                        helperText={errorsCreateUser?.email?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                    >
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={LOCALE.domain}
                        name="domain"
                        size="small"
                        placeholder="aec.mk"
                        autoComplete="off"
                        inputRef={registerCreateUser}
                        error={errorsCreateUser?.domain}
                        helperText={errorsCreateUser?.domain?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DnsIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                    >
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={LOCALE.password}
                        name="password"
                        autoComplete="off"
                        type="password"
                        size="small"
                        inputRef={registerCreateUser}
                        error={errorsCreateUser?.password}
                        helperText={errorsCreateUser?.password?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                <br />
                {/* <Paper variant="outlined">
                  <Typography variant="h6">Податоци за IMAP</Typography>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                    >
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={LOCALE.login_imapHost}
                        name="imapHost"
                        size="small"
                        inputRef={registerCreateUser}
                        error={errorsCreateUser?.imapHost}
                        helperText={errorsCreateUser?.imapHost?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                    >
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={LOCALE.email}
                        name="imapEmail"
                        size="small"
                        inputRef={registerCreateUser}
                        error={errorsCreateUser?.imapEmail}
                        helperText={errorsCreateUser?.imapEmail?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                    >
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={LOCALE.password}
                        name="imapPassword"
                        size="small"
                        type="password"
                        inputRef={registerCreateUser}
                        error={errorsCreateUser?.imapPassword}
                        helperText={errorsCreateUser?.imapPassword?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper> */}
                {/* {!isDataValid && (
                  <div style={{ marginTop: "3%", marginBottom: "3%" }}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="secondary"
                      type="submit"
                      disabled={loading}
                      size="small"
                    >
                      {loading && (
                        <CircularProgress
                          size={24}
                          style={{ color: "white" }}
                        />
                      )}
                      {!loading && "Провери податоци"}
                    </Button>
                  </div>
                )} */}
                {/* {isDataValid && ( */}
                <div style={{ marginTop: "3%", marginBottom: "3%" }}>
                  {/* <Typography variant="h6">
                    <p style={{ fontSize: "12pt" }}>
                      Податоците се во ред, можете да се регистрирате!
                    </p>
                  </Typography> */}
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    autoFocus
                    color="secondary"
                    disabled={loading}
                    size="small"
                  >
                    {loading && (
                      <CircularProgress size={24} style={{ color: "white" }} />
                    )}
                    {!loading && LOCALE.login_signup}
                  </Button>
                </div>
                {/* )} */}
              </form>
            </div>
          </Paper>
        )}
        {step === 2 && (
          <Paper className={classes.paperLogin}>
            <Grid container className={classes.gridMargin}>
              <Grid item xs={3}></Grid>
              <Grid item xs={6}>
                <Avatar className={classes.avatarLogin}>
                  <Icon>screen_lock_portrait</Icon>
                </Avatar>
                <h2 className={classes.cardTitleGray}>{LOCALE.mfa}</h2>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
            <div className={classes.panelLogin}>
              <Grid
                item
                xs={12}
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  marginBottom: "20px",
                  textAlign: "left",
                }}
              >
                <Alert fullwidth="true" severity="info">
                  <AlertTitle>Конфигурирајте го вашиот токен!</AlertTitle>
                  За да ја комплетирате регистрацијата, треба да го
                  конфигурирате вашиот токен во апликацијата Google
                  Authenticator. Овој токен ќе ви треба дополнително за да
                  можете да се најавите.<br></br>
                  Скенирајте го QR-кодот прикажан подолу или внесете го токенот
                  рачно.
                </Alert>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginLeft: "10px", marginRight: "10px" }}
              >
                <QRCode
                  value={`otpauth://totp/AEK%20Mail%20Checker:${createdUser?.username}?secret=${createdUser?.mfaKey}`}
                  level="L"
                  size={185}
                />
                <Typography variant="h6">{createdUser?.mfaKey}</Typography>
              </Grid>
              <Divider
                style={{ marginTop: "20px", marginBottom: "20px" }}
              ></Divider>
              <Typography variant="subtitle2">
                Потврдете ја конфигурацијата со внесување на 6 цифрениот код што
                го обезбедува Google Authenticator
              </Typography>
              <Grid
                item
                xs={12}
                style={{ marginLeft: "10px", marginRight: "10px" }}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={LOCALE.qrCode}
                  autoComplete="off"
                  name="imapEmail"
                  size="small"
                  type="number"
                  value={qrCode}
                  onChange={(e) => setQrCode(e.target.value)}
                  error={mfaValidationError}
                  helperText={mfaValidationError}
                />
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  marginBottom: "50px",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  size="small"
                  disabled={loading}
                  onClick={() => confirmMfaRegistration()}
                >
                  {LOCALE.confirmRegistrationConfiguration}
                </Button>
              </Grid>
            </div>
          </Paper>
        )}
        {step === 3 && (
          <Paper className={classes.paperLogin}>
            <Grid container className={classes.gridMargin}>
              <Grid item xs={3}></Grid>
              <Grid item xs={6}>
                <Avatar className={classes.avatarLogin}>
                  <Icon>screen_lock_portrait</Icon>
                </Avatar>
                <h2 className={classes.cardTitleGray}>{LOCALE.mfa}</h2>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
            <div className={classes.panelLogin}>
              <Typography variant="subtitle2">
                Bнесете го кодот за верификација што го обезбедува <br></br>
                Google Authenticator
              </Typography>
              <Grid
                item
                xs={12}
                style={{ marginLeft: "10px", marginRight: "10px" }}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={LOCALE.qrCode}
                  autoComplete="off"
                  name="imapEmail"
                  size="small"
                  type="number"
                  value={qrCode}
                  onChange={(e) => setQrCode(e.target.value)}
                  error={mfaValidationError ? true : false}
                  helperText={mfaValidationError}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  marginBottom: "50px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="small"
                  disabled={loading}
                  onClick={() => mfaLogin()}
                >
                  {LOCALE.login_signin}
                </Button>
              </Grid>
            </div>
          </Paper>
        )}
        {/* Forgot Password */}
        {step === 4 && (
          <Paper className={classes.paperLogin}>
            <Grid container className={classes.gridMargin}>
              <Grid item xs={3}>
                <IconButton
                  style={{
                    color: "black",
                  }}
                  onClick={() => setStep(0)}
                >
                  <Icon>arrow_back_ios</Icon>
                </IconButton>
              </Grid>
              <Grid item xs={6}>
                <Avatar className={classes.avatarLogin}>
                  <Icon>vpn_key</Icon>
                </Avatar>
                <h2 className={classes.cardTitleGray}>
                  {LOCALE.login_forgot_password}
                </h2>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
            <div className={classes.panelLogin}>
              <form>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  autoComplete="off"
                  label={LOCALE.email}
                  name="email"
                  autoFocus
                  value={resetPasswordEmail}
                  onChange={(e) => setResetPasswordEmail(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <div style={{ marginTop: "3%", marginBottom: "3%" }}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    disabled={loading}
                    onClick={() => forgotPassword()}
                  >
                    {loading && (
                      <CircularProgress size={24} style={{ color: "white" }} />
                    )}
                    {!loading && "Промени лозинка"}
                  </Button>
                </div>
              </form>
            </div>
          </Paper>
        )}
      </div>

      {counter.open && (
        <Snackbar
          place="br"
          icon={counter.variant}
          color={counter.variant}
          message={counter.message}
          open={true}
          closeNotification={handleClose}
          close
        />
      )}
    </div>
  );
}
