import Axios from "axios";
import { SETTINGS } from "../../../properties/ApiProps";

export const ClientChartRepo = {
  getSpfReport: (dateFrom, dateTo) => {
    return Axios({
      url: SETTINGS.API_URL + "reports/client/spf",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    });
  },
  getDkimReport: (dateFrom, dateTo) => {
    return Axios({
      url: SETTINGS.API_URL + "reports/client/dkim",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    });
  },
  getSpfAuthResultReport: (dateFrom, dateTo) => {
    return Axios({
      url: SETTINGS.API_URL + "reports/client/spf/auth",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    });
  },
  getDkimAuthResultReport: (dateFrom, dateTo) => {
    return Axios({
      url: SETTINGS.API_URL + "reports/client/dkim/auth",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    });
  },
  getTotalEmailCount: (dateFrom, dateTo) => {
    return Axios({
      url: SETTINGS.API_URL + "reports/client/emailCount",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    });
  },

  getCountReport: (dateFrom, dateTo) => {
    return Axios({
      url: SETTINGS.API_URL + "reports/client/count",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    });
  },
  getSpfReportDate: (dateFrom, dateTo) => {
    return Axios({
      url: SETTINGS.API_URL + "reports/client/spf/date",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    });
  },
  getDkimReportDate: (dateFrom, dateTo) => {
    return Axios({
      url: SETTINGS.API_URL + "reports/client/dkim/date",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    });
  },
  getDispositionReportDate: (dateFrom, dateTo) => {
    return Axios({
      url: SETTINGS.API_URL + "reports/client/disposition/date",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    });
  },
  getCountDispositionReportDate: (dateFrom, dateTo) => {
    return Axios({
      url: SETTINGS.API_URL + "reports/client/count/disposition/date",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    });
  },
  getFailReportSourceIpSpf: (dateFrom, dateTo) => {
    return Axios({
      url: SETTINGS.API_URL + "reports/client/sourceIp/spf",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    });
  },
  getFailReportSourceIpDkim: (dateFrom, dateTo) => {
    return Axios({
      url: SETTINGS.API_URL + "reports/client/sourceIp/dkim",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    });
  },
  getReportByOrgName: (dateFrom, dateTo) => {
    return Axios({
      url: SETTINGS.API_URL + "reports/client/orgName",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    });
  },
  getMyFeedbacks: (page, size, dateFrom, dateTo) => {
    return Axios({
      url: SETTINGS.API_URL + "reports/client/feedback",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        page: page,
        size: size,
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    });
  },
};
