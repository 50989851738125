import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import LockIcon from "@material-ui/icons/Lock";
import Slide from "@material-ui/core/Slide";
// @material-ui/core components
import {
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  InputAdornment,
  Grid,
  Typography,
} from "@material-ui/core";
// @material-ui/styles
import EmailIcon from "@material-ui/icons/Email";
import DnsIcon from "@material-ui/icons/Dns";
import PersonIcon from "@material-ui/icons/Person";
// core components
import { UsersRepository } from "../UsersRepository";
import { LOCALE } from "../../../../properties/Locale";
import EntityMetadata from "../../../../common/components/EntityMetadata";
import { useSelector } from "react-redux";
import {
  notifyShowSuccessMessage,
  notifyShowErrorMessage,
  triggerRerender,
} from "../../../../common/actions/CommonActions";
import { clientCreateUserForm } from "./Validations";
import { VpnKey } from "@material-ui/icons";
import { LoginRepository } from "../../../login/LoginRepository";
import { Alert } from "@material-ui/lab";

export default function ClientFormComponent(props) {
  const [disabled] = useState(props.disabled);
  const counter = useSelector((state) => state);
  const [loading, setLoading] = useState(counter.loading);
  const [action] = useState(props.action);
  // const [showPassword, setShowPassword] = useState(false);
  // const [isDataValid, setIsDataValid] = useState(false);
  const [redirectTo, setRedirectTo] = useState();

  const {
    handleSubmit: userForm,
    register: registerForm,
    errors: errorsUser,
    setValue,
    getValues,
    watch,
    control,
  } = useForm({
    mode: "onChange",
    validationSchema: clientCreateUserForm,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    loadUser();
  }, []);

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpen(false);
  // };

  // const testImap = () => {
  //   var validFormData = true;
  //   if (!clientCreateUserForm.isValidSync(getValues())) {
  //     validFormData = false;
  //   }
  //   if (validFormData) {
  //     const values = {
  //       imapHost: getValues("imapHost"),
  //       imapEmail: getValues("imapEmail"),
  //       imapPassword: getValues("imapPassword"),
  //     };
  //     setLoading(true);
  //     LoginRepository.testImapDetails(values).then(
  //       (res) => {
  //         setOpen(true);
  //         setIsDataValid(true);
  //         setLoading(false);
  //       },
  //       (err) => {
  //         setLoading(false);
  //         setOpen(true);
  //       }
  //     );
  //   }
  // };

  const onSubmit = () => {
    let values = getValues();
    var validFormData = true;
    if (!clientCreateUserForm.isValidSync(getValues())) {
      validFormData = false;
    }
    if (validFormData) {
      if (props.form === "myProfile") {
        props.showLoadingBar();
        UsersRepository.updateMyProfile(values)
          .then((res) => {
            props.hideLoadingBar();
            dispatch(triggerRerender());
            dispatch(
              notifyShowSuccessMessage(LOCALE.user_update_success_message)
            );
            setRedirectTo("/app/");
          })
          .catch((err) => {
            props.hideLoadingBar();
            if (err.response?.data) {
              dispatch(notifyShowErrorMessage(err.response?.data));
            } else {
              dispatch(notifyShowErrorMessage(err.message));
            }
          });
      }
    }
  };

  const loadUser = () => {
    Object.keys(props.data).map(function (item) {
      if (Object.keys(getValues()).indexOf(item) == -1) {
        registerForm({ name: item, type: "custom" });
      }
    });
    Object.entries(props.data).map(function (item) {
      setValue(item[0], item[1]);
    });
  };

  // const handleClickShowPassword = () => {
  //   setShowPassword(!showPassword);
  // };

  // const handleSubmit = () => {
  //   UsersRepository.resetPassword(getValues())
  //     .then((res) => {
  //       props.hideLoadingBar();
  //       dispatch(triggerRerender());
  //       dispatch(notifyShowSuccessMessage(LOCALE.user_update_success_message));
  //       if (props.form == "myProfile") {
  //         setRedirectTo("/app/profile");
  //       } else {
  //         setRedirectTo("/app/admin/users");
  //       }
  //     })
  //     .catch((err) => {
  //       props.hideLoadingBar();
  //       dispatch(notifyShowErrorMessage(err.message));
  //     });
  // };

  return (
    <div>
      {redirectTo && <Redirect to={redirectTo} />}
      <div>
        <form onSubmit={userForm(onSubmit)}>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Typography style={{ textAlign: "center" }} variant="subtitle1">
                Општи податоци
              </Typography>
            </Grid>
            <Grid item md={12}>
              <FormControl
                variant="outlined"
                fullWidth
                margin="normal"
                size="small"
              >
                <InputLabel>{LOCALE.role}</InputLabel>
                <Controller
                  as={
                    <Select label={LOCALE.role}>
                      <MenuItem value="">None</MenuItem>
                      <MenuItem value="ADMINISTRATOR">
                        {LOCALE.administrator}
                      </MenuItem>
                      <MenuItem value="CLIENT">{LOCALE.CLIENT}</MenuItem>
                    </Select>
                  }
                  name="type"
                  control={control}
                  defaultValue=""
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <TextField
                id="name"
                label={LOCALE.name}
                name="name"
                fullWidth={true}
                size="small"
                margin="dense"
                variant="outlined"
                inputRef={registerForm}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  disabled: disabled,
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                error={errorsUser?.name}
                helperText={errorsUser?.name?.message}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                id="email"
                label={LOCALE.email}
                fullWidth={true}
                size="small"
                name="email"
                margin="dense"
                variant="outlined"
                inputRef={registerForm}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  disabled: disabled,
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                error={errorsUser?.email}
                helperText={errorsUser?.email?.message}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                id="domain"
                label={LOCALE.domain}
                fullWidth={true}
                size="small"
                name="domain"
                disabled={true}
                margin="dense"
                variant="outlined"
                inputRef={registerForm}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  disabled: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <DnsIcon />
                    </InputAdornment>
                  ),
                }}
                error={errorsUser?.domain}
                helperText={errorsUser?.domain?.message}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                id="id"
                label={LOCALE.username}
                fullWidth={true}
                size="small"
                name="username"
                margin="dense"
                variant="outlined"
                inputRef={registerForm}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  disabled: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                error={errorsUser?.username}
                helperText={errorsUser?.username?.message}
              />
            </Grid>
            {/* <Grid item sm={12} xs={12}>
              <Typography style={{ textAlign: "center" }} variant="subtitle1">
                Податоци за IMAP серверот на клиентот
              </Typography>
            </Grid>
            <Grid item md={12}>
              <TextField
                id="imapEmail"
                label={LOCALE.imapEmail}
                fullWidth={true}
                size="small"
                name="imapEmail"
                margin="dense"
                onChange={() => {
                  setIsDataValid(false);
                }}
                variant="outlined"
                inputRef={registerForm}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  disabled: disabled,
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                error={errorsUser?.imapEmail}
                helperText={errorsUser?.imapEmail?.message}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                id="imapHost"
                label={LOCALE.imapHost}
                fullWidth={true}
                size="small"
                name="imapHost"
                margin="dense"
                onChange={() => {
                  setIsDataValid(false);
                }}
                variant="outlined"
                inputRef={registerForm}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  disabled: disabled,
                  startAdornment: (
                    <InputAdornment position="start">
                      <DnsIcon />
                    </InputAdornment>
                  ),
                }}
                error={errorsUser?.imapHost}
                helperText={errorsUser?.imapHost?.message}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                id="imapPassword"
                label={LOCALE.imapPassword}
                fullWidth={true}
                size="small"
                type="password"
                name="imapPassword"
                margin="dense"
                onChange={() => {
                  setIsDataValid(false);
                  setImapPasswordChanged(true);
                }}
                variant="outlined"
                inputRef={registerForm}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  disabled: disabled,
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKey />
                    </InputAdornment>
                  ),
                }}
                error={errorsUser?.imapPassword}
                helperText={errorsUser?.imapPassword?.message}
              />
            </Grid> */}
          </Grid>
          <EntityMetadata data={getValues()} />
          <DialogActions>
            {/* {action != "details" && (
              <div>
                <Snackbar
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Alert onClose={handleClose} severity="error">
                    Податоците не се во ред, Ве молиме внесете ги повторно!
                  </Alert>
                </Snackbar>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={testImap}
                  type="submit"
                  disabled={loading}
                  size="small"
                >
                  {loading && (
                    <CircularProgress size={24} style={{ color: "white" }} />
                  )}
                  {!loading && "Провери податоци"}
                </Button>
              </div>
            )} */}
            {/* {isDataValid && ( */}
            <div>
              {/* <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  Податоците се во ред, можете да ажурирате!
                </Alert>
              </Snackbar> */}
              <Button
                variant="contained"
                fullWidth
                autoFocus
                type="submit"
                color="primary"
                disabled={loading}
                size="small"
              >
                {loading && (
                  <CircularProgress size={24} style={{ color: "white" }} />
                )}
                {!loading && "Зачувај"}
              </Button>
            </div>
            {/* )} */}
          </DialogActions>
        </form>
      </div>
    </div>
  );
}
