import Axios from "axios";
import { SETTINGS } from "../../../properties/ApiProps";

export const DomainChecksRepository = {
  whois: (domain) => {
    return Axios({
      url: SETTINGS.API_URL + "domain/whois",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        domain: domain,
      },
    });
  },

  dnsLookup: (domain) => {
    return Axios({
      url: SETTINGS.API_URL + "domain/dnsLookup",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        domain: domain,
      },
    });
  },
  headersCheck: (domain) => {
    return Axios({
      url: SETTINGS.API_URL + "domain/headers",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        domain: domain,
      },
    });
  },
  sslCheck: (domain) => {
    return Axios({
      url: SETTINGS.API_URL + "domain/ssl",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        domain: domain,
      },
    });
  },
  mailSettingsCheck: (domain, mailServerHost, mailServerPort, dkimSelector) => {
    return Axios({
      url: SETTINGS.API_URL + "domain/mailSettings",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        domain: domain,
        mailServerHost: mailServerHost,
        mailServerPort: mailServerPort,
        dkimSelector: dkimSelector,
      },
    });
  },

  tlsSslChecker: (domain) => {
    return Axios({
      url: SETTINGS.API_URL + "domain/tlsSslChecker",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        domain: domain,
      },
    });
  },

  whoisAdmin: (domain) => {
    return Axios({
      url: SETTINGS.API_URL + "domain/admin/whois",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        domain: domain,
      },
    });
  },

  dnsLookupAdmin: (domain) => {
    return Axios({
      url: SETTINGS.API_URL + "domain/admin/dnsLookup",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        domain: domain,
      },
    });
  },
  headersCheckAdmin: (domain) => {
    return Axios({
      url: SETTINGS.API_URL + "domain/admin/headers",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        domain: domain,
      },
    });
  },
  sslCheckAdmin: (domain) => {
    return Axios({
      url: SETTINGS.API_URL + "domain/admin/ssl",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        domain: domain,
      },
    });
  },

  mailSettingsCheckAdmin: (
    domain,
    mailServerHost,
    mailServerPort,
    dkimSelector
  ) => {
    return Axios({
      url: SETTINGS.API_URL + "domain/admin/mailSettings",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        domain: domain,
        mailServerHost: mailServerHost,
        mailServerPort: mailServerPort,
        dkimSelector: dkimSelector,
      },
    });
  },

  tlsSslCheckerAdmin: (domain) => {
    return Axios({
      url: SETTINGS.API_URL + "domain/admin/tlsSslChecker",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        domain: domain,
      },
    });
  },
};
