import React, { useEffect } from "react";
import classNames from "classnames";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Hidden,
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { NavLink } from "react-router-dom";
import { AuthService } from "../../auth/AuthService";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/sidebarStyle";
import HeaderLinks from "./HeaderLinks";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { COMMON_ACTIONS } from "../actions/CommonActions";
import { ContactMailSharp } from "@material-ui/icons";

const useStyles = makeStyles(styles);
export default function Sidebar(props) {
  const classes = useStyles();
  const [currentPosition, setCurrentPosition] = React.useState(1);
  const [isCollapse, setisCollapse] = React.useState([]);
  const sidePanel = React.createRef();

  const counter = useSelector((state) => state);
  const dispatch = useDispatch();
  function activeRoute(routeName) {
    if (routeName === "/") {
      return false;
    } else {
      return window.location.href.indexOf(routeName) > -1 ? true : false;
    }
  }

  function toogle() {
    if (counter.mobileOpen) {
      dispatch({
        type: COMMON_ACTIONS.MOBILE_SCREEN,
        payload: {
          mobileOpen: false,
        },
      });
    } else {
      dispatch({
        type: COMMON_ACTIONS.MOBILE_SCREEN,
        payload: {
          mobileOpen: true,
        },
      });
    }
  }

  useEffect(() => {
    const filter1 = props.routes.filter((obj) => obj.children !== undefined);
    const filtered = filter1.filter((obj) =>
      obj.children.find((o) => o.url === window.location.pathname)
    );
    if (filtered.length > 0) {
      setCurrentPosition(2);
      setisCollapse({ [filtered[0].name]: true });
    }
  }, [currentPosition]);

  function handleClick(item, position) {
    if (position === undefined) {
      setisCollapse({ [item]: !isCollapse[item] });
    } else {
      if (position > currentPosition) {
        setCurrentPosition({ currentPosition: position });
      } else {
        if (position < currentPosition) {
          setisCollapse({ isCollapse: [] });
        }
      }
    }
  }

  function handler(children, classes) {
    function intersection(a, b) {
      return a.filter(Set.prototype.has, new Set(b));
    }
    const authorities = AuthService.getCurrentUser()?.authorities?.map(
      (obj) => obj.authority
    );

    children = children.filter(
      (item) => intersection(item.hasAccess, authorities).length > 0
    );

    return children.map((subOption, index) => {
      var listItemClass;
      listItemClass = classNames({
        [" " + classes["theme"]]: activeRoute(subOption.url),
      });
      if (!subOption.children) {
        return (
          <NavLink
            to={subOption.url}
            className={classes.item}
            activeClassName="active"
            key={index}
            onClick={() => handleClick(subOption.name, subOption.position)}
          >
            <ListItem
              button
              className={classNames(classes.itemLink + listItemClass)}
            >
              <Icon className={classNames(classes.itemIcon)}>
                {subOption.icon}
              </Icon>
              <ListItemText
                primary={subOption.name}
                className={classNames(classes.itemText)}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      }
      return (
        <div key={index}>
          <ListItem
            button
            className={classNames(classes.itemLink)}
            onClick={() => handleClick(subOption.name, subOption.position)}
          >
            <div style={{ display: "flex" }}>
              <Icon className={classNames(classes.itemIcon, classes.whiteFont)}>
                {subOption.icon}
              </Icon>
              <ListItemText
                primary={subOption.name}
                className={classNames(classes.itemText, classes.whiteFont)}
                disableTypography={true}
              ></ListItemText>
              {isCollapse[subOption.name] ? (
                <Icon className={classes.whiteFont}>expand_less</Icon>
              ) : (
                <Icon className={classes.whiteFont}>expand_more</Icon>
              )}
            </div>
          </ListItem>
          <Collapse
            in={isCollapse[subOption.name]}
            timeout="auto"
            unmountOnExit
          >
            {handler(subOption.children, classes)}
          </Collapse>
        </div>
      );
    });
  }

  var brand = (
    <div className={classes.logo}>
      <a
        href=""
        className={classes.logoLink}
        style={{ fontSize: "13px", marginTop: "5px" }}
      >
        <div className={classes.logoImage}>
          <img src={props.logo} alt="logo" className={classes.img} />
        </div>
        {props.logoText}
      </a>
    </div>
  );

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={props.open}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={toogle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper} ref={sidePanel}>
            <HeaderLinks />
            <List className={classes.list}>
              {handler(props.routes, classes)}
            </List>
          </div>
          <div
            className={classes.background}
            style={{ backgroundImage: "url(" + props.image + ")" }}
          />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={"left"}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          open
        >
          {brand}
          <div className={classes.sidebarWrapper} ref={sidePanel}>
            <List className={classes.list}>
              {handler(props.routes, classes)}
            </List>
          </div>
          <div
            className={classes.background}
            style={{ backgroundImage: "url(" + props.image + ")" }}
          />
        </Drawer>
      </Hidden>
    </div>
  );
}
