import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import filesize from "filesize";
import FileIcon, { defaultStyles } from "react-file-icon";
import { LOCALE } from "../../properties/Locale";

export default function Upload(props) {
  const [files, setFiles] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
    if (props.onClose) props.onClose();
    if (props.onComponentClose) props.onComponentClose(props.type, files);
  };

  useEffect(() => {}, [files]);

  const handleChangeFiles = (files) => {
    setFiles(files);
  };

  return (
    <Paper>
      <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <DialogContent style={{ textAlign: "center" }}>
            {!props.disabled && (
              <DropzoneArea
                multiple={true}
                showFileNamesInPreview={false}
                onChange={(files) => handleChangeFiles(files)}
                showPreviews={true}
                showFileNames={true}
                showFileNamesInPreview={true}
                useChipsForPreview={true}
                fileObjects={files}
                showPreviewsInDropzone={false}
                filesLimit={5}
                maxFileSize={5000000}
                disableRejectionFeedback
                dropzoneText={LOCALE.dragNDrop}
                previewText="Прикачени датотеки:"
                acceptedFiles={[".xml"]}
              ></DropzoneArea>
            )}
          </DialogContent>
          {files.length > 0 && (
            <Button
              variant="contained"
              fullWidth
              size="small"
              style={{ margin: "10px" }}
              color="primary"
              onClick={() => handleDialogClose()}
            >
              Прикачи и прегледај
            </Button>
          )}
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </Paper>
  );
}
