import React, { useState, useEffect } from "react";
import { LOCALE } from "../../../properties/Locale";
import { useDispatch, useSelector } from "react-redux";
import { changePageTitle } from "../../../common/actions/CommonActions";
import Upload from "../../../common/components/Upload";
import { Redirect } from "react-router-dom";
import { UploadReportRepository } from "./UploadReportRepository";
import styles from "../../../assets/jss/layouts/appStyle";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
  triggerRerender,
} from "../../../common/actions/CommonActions";
import { setDate } from "date-fns";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import {
  Badge,
  Box,
  Button,
  Collapse,
  DialogActions,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import "./styles.css";
import { green, red } from "@material-ui/core/colors";
import Card from "../../../common/components/Card/Card";
import CardHeader from "../../../common/components/Card/CardHeader";
import CardBody from "../../../common/components/Card/CardBody";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { format } from "date-fns";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

export default function ClientChartComponent() {
  const greenTheme = createMuiTheme({ palette: { primary: green } });
  const redTheme = createMuiTheme({ palette: { primary: red } });
  const useStyles = makeStyles(styles);
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  const classes = useStyles();
  const [feedback, setFeedback] = useState();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [fileDetails, setFileDetails] = useState();
  const [redirectTo, setRedirectTo] = useState();
  const [open, setOpen] = React.useState({ index: 0, open: false });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changePageTitle(LOCALE.upload_report));
  }, []);

  const saveFile = () => {
    setLoading(true);
    for (let key of Object.keys(fileDetails)) {
      UploadReportRepository.uploadedToNew(fileDetails[key], key)
        .then((res) => {
          setLoading(false);
          dispatch(triggerRerender());
          dispatch(notifyShowSuccessMessage(LOCALE.file_saved_successfully));
          window.location.href = "/app/upload_report";
          dispatch(changePageTitle(LOCALE.starting_page));
        })
        .catch((err) => {
          setLoading(false);
          dispatch(notifyShowErrorMessage(err.message));
        });
    }
  };

  const cancel = () => {
    setLoading(true);
    window.location.href = "/app/upload_report";
    dispatch(changePageTitle(LOCALE.starting_page));
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setStep(1);
      return <React.Fragment className="timer">Пренасочување</React.Fragment>;
    }

    return (
      <React.Fragment>
        <Typography style={{ fontSize: "10px" }}>{remainingTime}</Typography>
      </React.Fragment>
    );
  };

  const handleExpandTable = (index, isOpen) => {
    let tmp;
    if (index !== open.index) {
      tmp = { index: index, open: true };
    } else {
      tmp = { index: index, open: isOpen };
    }
    setOpen(tmp);
  };

  const uploadFiles = (type, files) => {
    if (files.length === 0 || files === undefined) {
      dispatch(notifyShowErrorMessage("Немате прикачени датотеки"));
      return;
    }
    setLoading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    UploadReportRepository.mapXml(formData)
      .then((res) => {
        setLoading(false);
        dispatch(triggerRerender());
        setFeedback(res.data.feedbacks);
        setFileDetails(res.data.fileDetails);
        setStep(2);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(notifyShowErrorMessage(err.message));
      });
  };

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} />}
      {step === 1 && (
        <Upload type="dmarcXmls" onComponentClose={uploadFiles}></Upload>
      )}
      {step === 2 && (
        <React.Fragment>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Card>
                <CardHeader color="theme">Прикачени извештаи</CardHeader>
                <CardBody>
                  {feedback && (
                    <Table aria-label="users table" className={classes.table}>
                      <TableHead>
                        <TableRow className={classes.tableHeadRow}>
                          <TableCell>Org Name</TableCell>
                          <TableCell>Date From</TableCell>
                          <TableCell>Date To</TableCell>
                          <TableCell>Policy domain</TableCell>
                          <TableCell>Dkim alignment</TableCell>
                          <TableCell>SPF alignment</TableCell>
                          <TableCell>Policy</TableCell>
                          <TableCell>Subdomain Policy</TableCell>
                          <TableCell>Expand</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {feedback.map((row, index) => (
                          <React.Fragment key={index}>
                            <TableRow
                              key={index}
                              className={classes.tableBodyRow}
                              style={index % 2 ? { background: "#f0f0f0" } : {}}
                            >
                              <TableCell className={classes.tableCell}>
                                {row.report_metadata.org_name}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {format(
                                  new Date(
                                    row.report_metadata.date_range.begin * 1000
                                  ),
                                  ["dd-MM-yyyy HH:mm:ss"]
                                )}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {format(
                                  new Date(
                                    row.report_metadata.date_range.end * 1000
                                  ),
                                  ["dd-MM-yyyy HH:mm:ss"]
                                )}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {row.policy_published.domain}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                style={{ textAlign: "center" }}
                              >
                                <Badge
                                  color={
                                    row.policy_published.adkim === "r"
                                      ? "primary"
                                      : "secondary"
                                  }
                                  badgeContent={
                                    row.policy_published.adkim === "r"
                                      ? "Relaxed"
                                      : "Strict"
                                  }
                                ></Badge>
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                style={{ textAlign: "center" }}
                              >
                                <Badge
                                  color={
                                    row.policy_published.aspf === "r"
                                      ? "primary"
                                      : "secondary"
                                  }
                                  badgeContent={
                                    row.policy_published.aspf === "r"
                                      ? "Relaxed"
                                      : "Strict"
                                  }
                                ></Badge>
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {row.policy_published.p}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {row.policy_published.sp}
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() =>
                                    handleExpandTable(index, !open.open)
                                  }
                                >
                                  {index === open.index && open.open ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                style={{
                                  paddingBottom: 0,
                                  paddingTop: 0,
                                  backgroundColor: "#f5f2b34f",
                                }}
                                colSpan={9}
                              >
                                <Collapse
                                  in={index === open.index && open.open}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box margin={1}>
                                    <Typography
                                      variant="h6"
                                      gutterBottom
                                      component="div"
                                    >
                                      Records
                                    </Typography>
                                    <Table className={classes.table}>
                                      <TableHead>
                                        <TableRow
                                          className={classes.tableHeadRow}
                                        >
                                          <TableCell>Source Ip</TableCell>
                                          <TableCell>Emails count</TableCell>
                                          <TableCell>Header from</TableCell>
                                          <TableCell
                                            style={{
                                              borderLeft: "1px solid black",
                                            }}
                                          >
                                            SPF alignment
                                          </TableCell>
                                          <TableCell>DKIM alignment</TableCell>
                                          <TableCell
                                            style={{
                                              borderRight: "1px solid black",
                                            }}
                                          >
                                            Policy applied
                                          </TableCell>
                                          <TableCell>SPF domain</TableCell>
                                          <TableCell>SPF Auth result</TableCell>
                                          <TableCell>DKIM domain</TableCell>
                                          <TableCell>
                                            DKIM Auth result
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>

                                      <TableBody>
                                        {row.record?.map((record, index) => (
                                          <TableRow
                                            key={record.id}
                                            style={
                                              index % 2
                                                ? { background: "#f0f0f0" }
                                                : {}
                                            }
                                          >
                                            <TableCell>
                                              {record?.row.source_ip}
                                            </TableCell>
                                            <TableCell>
                                              {record?.row.count}
                                            </TableCell>
                                            <TableCell>
                                              {record?.identifiers?.header_from}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                textAlign: "center",
                                                borderLeft: "1px solid black",
                                              }}
                                            >
                                              <Badge
                                                color={
                                                  record?.row?.policy_evaluated
                                                    ?.spf === "pass"
                                                    ? "primary"
                                                    : "secondary"
                                                }
                                                badgeContent={
                                                  record?.row?.policy_evaluated
                                                    ?.spf
                                                }
                                              ></Badge>
                                            </TableCell>
                                            <TableCell
                                              style={{ textAlign: "center" }}
                                            >
                                              <Badge
                                                color={
                                                  record?.row?.policy_evaluated
                                                    ?.dkim === "pass"
                                                    ? "primary"
                                                    : "secondary"
                                                }
                                                badgeContent={
                                                  record?.row?.policy_evaluated
                                                    ?.dkim
                                                }
                                              ></Badge>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                textAlign: "center",
                                                borderRight: "1px solid black",
                                              }}
                                            >
                                              <Badge
                                                color={
                                                  record?.row?.policy_evaluated
                                                    ?.disposition === "none"
                                                    ? "primary"
                                                    : "secondary"
                                                }
                                                badgeContent={
                                                  record?.row?.policy_evaluated
                                                    ?.disposition
                                                }
                                              ></Badge>
                                            </TableCell>
                                            <TableCell>
                                              {
                                                record?.auth_results?.spf
                                                  ?.domain
                                              }
                                            </TableCell>
                                            <TableCell
                                              style={{ textAlign: "center" }}
                                            >
                                              <Badge
                                                color={
                                                  record?.auth_results?.spf
                                                    ?.result === "pass"
                                                    ? "primary"
                                                    : "secondary"
                                                }
                                                badgeContent={
                                                  record?.auth_results?.spf
                                                    ?.result
                                                }
                                              ></Badge>
                                            </TableCell>
                                            <TableCell>
                                              {
                                                record?.auth_results?.dkim
                                                  ?.domain
                                              }
                                            </TableCell>
                                            <TableCell
                                              style={{ textAlign: "center" }}
                                            >
                                              <Badge
                                                color={
                                                  record?.auth_results?.dkim
                                                    ?.result === "pass"
                                                    ? "primary"
                                                    : "secondary"
                                                }
                                                badgeContent={
                                                  record?.auth_results?.dkim
                                                    ?.result
                                                }
                                              ></Badge>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </CardBody>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}></Grid>
              <Grid item xs={5}>
                {" "}
                <Button
                  variant="contained"
                  style={{
                    float: "right",
                    marginRight: "10px",
                    color: "white",
                  }}
                  color="primary"
                  onClick={() => saveFile()}
                  endIcon={
                    <div className="App">
                      <CountdownCircleTimer
                        strokeWidth={2}
                        size={25}
                        isPlaying
                        duration={300}
                        colors={[
                          ["#FFFFFF", 0.33],
                          ["#ffff00", 0.33],
                          ["#FF0000"],
                        ]}
                        onComplete={() => [true, 1000]}
                      >
                        {renderTime}
                      </CountdownCircleTimer>
                    </div>
                  }
                >
                  Зачувај во мои извештаи
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => cancel()}
                >
                  {LOCALE.cancel}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
