import Axios from "axios";
import { SETTINGS } from "../../../properties/ApiProps";

export const UploadReportRepository = {
  mapXml: (data) => {
    return Axios({
      url: SETTINGS.API_URL + "upload/mapXml",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      method: "POST",
      data: data,
    });
  },
  uploadedToNew: (fullPath, fileName) => {
    return Axios({
      url: SETTINGS.API_URL + "upload/uploadedToNew",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      method: "GET",
      params: {
        fullPath: fullPath,
        fileName: fileName,
      },
    });
  },
};
