import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  IconButton,
  Icon,
} from "@material-ui/core";
// @material-ui/styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/layouts/appStyle";
// core components
import { UsersRepository } from "./UsersRepository";
import { LOCALE } from "../../../properties/Locale";
import CardBody from "../../../common/components/Card/CardBody";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import AdministratorFormComponent from "./forms/AdministratorFormComponent";
import ClientFormComponent from "./forms/ClientFormComponent";

const useStyles = makeStyles(styles);

export default function UserDetailsComponent(props) {
  const classes = useStyles();
  const [userId] = useState(props.match.params.userId);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const loadUser = (id) => {
      showLoadingBar();
      UsersRepository.fetchUser(id).then((res) => {
        setData(res.data);
        hideLoadingBar();
      });
    };
    loadUser(userId);
  }, [userId]);

  const showLoadingBar = () => {
    setLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
  };

  return (
    <div>
      <Card>
        <Dialog
          open={true}
          maxWidth="md"
          fullWidth={true}
          onClose={() => {}}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              overflowY: "unset",
            },
          }}
        >
          <CardHeader color="theme">
            <h4 className={classes.cardTitleWhite}>
              {`${LOCALE.user_details}${
                data?.username ? ": " + data?.username : ""
              }`}
              <Link to={`/app/admin/users/`} style={{ textDecoration: "none" }}>
                <IconButton
                  style={{ float: "right", color: "white" }}
                  aria-label="close"
                >
                  <Icon>close</Icon>
                </IconButton>
              </Link>
            </h4>
          </CardHeader>
          <CardBody>
            {loading ? <LinearProgress color="secondary" /> : ""}
            <div>
              <DialogContent>
                {data?.type === "ADMINISTRATOR" && (
                  <div>
                    <AdministratorFormComponent
                      data={data}
                      disabled={true}
                      action={"details"}
                      showLoadingBar={showLoadingBar}
                      hideLoadingBar={hideLoadingBar}
                    />
                  </div>
                )}
                {data?.type === "CLIENT" && (
                  <div>
                    <ClientFormComponent
                      data={data}
                      disabled={true}
                      action={"details"}
                      showLoadingBar={showLoadingBar}
                      hideLoadingBar={hideLoadingBar}
                    />
                  </div>
                )}
              </DialogContent>
              <DialogActions>
                <Link
                  to={`/app/admin/users/`}
                  style={{ textDecoration: "none" }}
                >
                  <Button size="small" variant="outlined" color="secondary">
                    {LOCALE.close}
                  </Button>
                </Link>
              </DialogActions>
            </div>
          </CardBody>
        </Dialog>
      </Card>
    </div>
  );
}
