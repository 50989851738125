import { LOCALE } from "./Locale";

export const routes = [
  {
    name: LOCALE.starting_page,
    url: "/",
    icon: "home",
    position: "1",
    hasAccess: ["ROLE_ADMINISTRATION", "ROLE_CLIENT"],
  },
  {
    name: LOCALE.domain_checks,
    url: "/app/domain_checks",
    icon: "dns",
    position: "1",
    hasAccess: ["ROLE_CLIENT"],
  },
  {
    name: LOCALE.domain_checks,
    url: "/app/admin/domain_checks",
    icon: "dns",
    position: "1",
    hasAccess: ["ROLE_ADMINISTRATION"],
  },
  {
    name: LOCALE.charts,
    url: "/app/charts",
    icon: "pie_chart",
    position: "1",
    hasAccess: ["ROLE_CLIENT"],
  },
  {
    name: LOCALE.dmarc_reports,
    url: "/app/admin/dmarc_reports",
    icon: "pie_chart",
    position: "1",
    hasAccess: ["ROLE_ADMINISTRATION"],
  },
  {
    name: LOCALE.upload_report,
    url: "/app/upload_report",
    icon: "cloud_upload",
    position: "1",
    hasAccess: ["ROLE_CLIENT"],
  },
  {
    name: LOCALE.automaticReports,
    url: "/app/automatization",
    icon: "cloud_download",
    position: "1",
    hasAccess: ["ROLE_CLIENT"],
  },
  {
    name: LOCALE.administration,
    icon: "settings",
    hasAccess: ["ROLE_ADMINISTRATION"],
    children: [
      {
        name: LOCALE.users,
        url: "/app/admin/users",
        icon: "person",
        position: "2",
        hasAccess: ["ROLE_ADMINISTRATION"],
      },
      {
        name: LOCALE.groups,
        url: "/app/admin/groups",
        icon: "groups",
        position: "2",
        hasAccess: ["ROLE_ADMINISTRATION"],
      },
      {
        name: LOCALE.privileges,
        url: "/app/admin/privileges",
        icon: "report",
        position: "2",
        hasAccess: ["ROLE_ADMINISTRATION"],
      },
      {
        name: LOCALE.settings,
        url: "/app/admin/settings",
        icon: "settings",
        position: "2",
        hasAccess: ["ROLE_ADMINISTRATION"],
      },
      {
        name: LOCALE.audit_log,
        url: "/app/admin/auditLog",
        icon: "track_changes",
        position: "2",
        hasAccess: ["ROLE_ADMINISTRATION"],
      },
      {
        name: LOCALE.mail_log,
        url: "/app/admin/mailLog",
        icon: "all_inbox",
        position: "2",
        hasAccess: ["ROLE_ADMINISTRATION"],
      },
      {
        name: LOCALE.mail_template,
        url: "/app/admin/mailTemplate",
        icon: "square_foot",
        position: "2",
        hasAccess: ["ROLE_ADMINISTRATION"],
      },
    ],
  },
];
