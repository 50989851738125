import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { useDispatch } from "react-redux";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  TextField,
  CircularProgress,
  IconButton,
  Icon,
  Tab,
  Tabs,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
// @material-ui/styles
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import styles from "../../assets/jss/layouts/appStyle";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import bgImg from "../../assets/img/bg.jpg";
import bgImg2 from "../../assets/img/bg1.jpg";
import productCurvyLines from "../../assets/img/productCurvyLines.png";
import MagicHover from "magic-hover";
import Footer from "../../common/components/Footer";
import DialogComponent from "../../common/components/DialogComponent";

const useStyles = makeStyles(styles);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function DmarcPage(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState();
  const [openDmarcGeneratorDialog, setDmarcGeneratorDialog] = useState();
  const [openDmarcValidatorDialog, setDmarcValidatorDialog] = useState();

  const dispatch = useDispatch();

  const closeDialog = (type) => {
    if (type === "dmarcGenerator") {
      setDmarcGeneratorDialog(false);
    } else if (type === "dmarcValidator") {
      setDmarcValidatorDialog(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function createData(tag, meaning) {
    return { tag, meaning };
  }

  const rows = [
    createData(
      "pct",
      "Поставете го процентот на неуспешни пораки на кои треба да се применува поставената полиса. Како во примерот погоре, да се испраќаат во „quarantine“ само 10% од пораките, другите 90% ќе бидат третирани како да не се опфатени со полиса. Вредноста треба да биде цел број помеѓу 1 и 100."
    ),
    createData(
      "sp",
      "Постaвување посебна полиса за пораки испратени од поддомени. На пример, можете да изберете да ги игнорирате неуспешните пораки испратени од главниот домен (p = 'none'), но да ги ставите во карантин оние кои се испратени од поддомени."
    ),
    createData(
      "adkim",
      "Овде можете да одредите колку строг треба да биде DMARC при споредување Стандардно, вредноста е „relaxed“."
    ),
    createData(
      "aspf",
      "Исто како за adkim, само што се однесува на SPF усогласување. Вие одлучувате дали кај SPF треба целосно да се совпаѓаат „envelop from“ доменот и „return-path“ адресата или ако може да се дозволат и поддомени на доменот „envelop from“. Исто како погоре, можете да изберете да биде „strict“ или „relaxed“."
    ),
    createData(
      "ri",
      "Интервал за праќање на збирните извештаи (ознаката 'rua'). Вредноста се изразува во секунди, стандардно е 86400 (на секои 24 часа)."
    ),
    createData(
      "fo",
      "Поставки за извештаите за форензика (ознака „ruf“). Можете да изберете да се испраќа извештајот ако: '0' - ако сите основни проверки не успеат да вратат позитивен резултат на DMARC, '1' - ако некој проверка не успее, 'd' - ако не успее DKIM проверката, 's' - ако SPF проверката не успее. Стандардна вредност е '0'."
    ),
  ];

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} />}
      <Grid
        id="start"
        container
        style={{
          backgroundImage:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)),url(" +
            bgImg +
            ")",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <ScrollLink
          activeClass="active"
          to="start"
          spy={true}
          smooth={true}
          duration={500}
        >
          <IconButton
            style={{
              position: "fixed",
              bottom: "0",
              right: "0",
              margin: "15px",
            }}
          >
            <ArrowUpwardIcon fontSize="large"></ArrowUpwardIcon>
          </IconButton>
        </ScrollLink>
        <Grid item sm={12} xs={12}>
          <IconButton
            onClick={() => setRedirectTo("/")}
            style={{ color: "white", padding: "5vh" }}
          >
            <Icon>arrow_back_ios</Icon>
          </IconButton>
        </Grid>
        <Grid item sm={3} xs={false}></Grid>
        <Grid item sm={6} xs={12} style={{ textAlign: "center" }}>
          <Typography
            variant="h1"
            style={{
              marginTop: "3vh",
              color: "white",
              opacity: "70%",
            }}
          >
            DMARC
          </Typography>{" "}
          <Typography
            variant="h4"
            style={{
              marginBottom: "10vh",
              textAlign: "center",
              color: "white",
              opacity: "70%",
            }}
          >
            Domain-based Message Authentication Reporting and Conformance
          </Typography>
          <div
            style={{
              marginRight: "auto",
              marginLeft: "auto",
              marginBottom: "12vh",
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              style={{
                color: "white",
                verticalAlign: "top",
                marginTop: "15px",
                marginRight: "10px",
                marginLeft: "10px",
              }}
              onClick={() => setDmarcGeneratorDialog(true)}
            >
              DMARC Generator
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              style={{
                color: "white",
                verticalAlign: "top",
                marginTop: "15px",
                marginRight: "10px",
                marginLeft: "10px",
              }}
              onClick={() => setDmarcValidatorDialog(true)}
            >
              DMARC Validator
            </Button>
          </div>
        </Grid>
        <Grid item sm={3} xs={false}></Grid>
        {openDmarcGeneratorDialog && (
          <DialogComponent
            dialogTitle={"DMARC Record Generator"}
            closeDialog={() => closeDialog("dmarcGenerator")}
            contentText="contentText"
            dialogType="dmarcGenerator"
          ></DialogComponent>
        )}
        {openDmarcValidatorDialog && (
          <DialogComponent
            dialogTitle={"DMARC Validator"}
            closeDialog={() => closeDialog("dmarcValidator")}
            contentText="contentText"
            dialogType="dmarcValidator"
          ></DialogComponent>
        )}
      </Grid>
      <Grid
        container
        style={{
          backgroundImage: "url(" + productCurvyLines + ")",
          backgroundSize: "auto",
          paddingBottom: "5vh",
          textAlign: "initial",
        }}
      >
        <Grid item xs={2} sm={2}></Grid>
        <Grid
          item
          xs={12}
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
          sm={8}
        >
          <br></br>
          <Typography variant="subtitle1" id="content">
            DMARC ги користи претходно дефинирате стандарди за SPF и DMARK, за
            подобра заштита од спуфинг и фиинг. Секоја организација што користи
            корпоративна електронска пошта треба да имплементира DMARC политика,
            и дополнително да воведе DMARC верификација на серверите за е-пошта
            што ги користи, во делот на прием на пораки. DMARC вовоедува
            испраќање на извештаи преку чија анализа може да се утврди дали
            постојат неусогласености со DKIM , DMARC и SPF политиките.
          </Typography>
          <br></br>
          <Typography variant="h4">Содржина</Typography>
          <br></br>
          <ol className="nav-items">
            <li className="nav-item">
              <ScrollLink
                activeClass="active"
                to="whatIsDmarc"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Typography variant="subtitle1">Што е DMARC?</Typography>
              </ScrollLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                activeClass="active"
                to="howDmarcWorks"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Typography variant="subtitle1">Како работи DMARC</Typography>
              </ScrollLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                activeClass="active"
                to="whatDoesDmarcRecordLookLike"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Typography variant="subtitle1">
                  Како изгледа запис за DMARC?
                </Typography>
              </ScrollLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                activeClass="active"
                to="whyYouShouldUseDmarc"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Typography variant="subtitle1">
                  Зошто треба да користите DMARC?
                </Typography>
              </ScrollLink>
            </li>
          </ol>
          <br></br>
          <br></br>
          <Typography variant="h4" id="whatIsDmarc">
            Што е DMARC?
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            DMARC ги користи DKIM и SPF за да изврши понапредна проверка на
            секоја примена порака по е-пошта. Со DMARC, сопственикот на домен
            може да определи сопствена политика за автентикација (позната како
            DMARC политика). <br></br>Со неа се даваат инструкции на серверот за
            дојдовна е-пошта како да постапи со пораката доколку таа не го
            помине DMARC тестот. DMARC политиката обезбедува и извештаи со
            детали за секоја проверка што може да помогне во спречување на
            испраќање на лажни пораки и да го подобри кредибилитетот на
            организацијата.
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" id="howDmarcWorks">
            Како работи DMARC
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            DMARC побарува имплементација и користење барем на една од двете
            претходни политики за SPF и/или DKIM. Кога е примена порака,
            серверот што ја прима пораката прави пребарување на DNS серверите и
            проверува дали за доменот од кој е испратена пораката има валиден
            DMARC запис. Оваа валидација не е достапна кај сите меил сервери, но
            најголемите како Gmail или Yahoo Mail имаат вклучено верификација на
            DMARC доколку DMARC политиката за доменот од кој се праќа пораката е
            јавно објавен.
            <br></br>
            <br></br>
            Со DMARC верификацијата се потврдува:
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Ако е поставена само една од проверките за SPF или за DKIM,
                  проверка мора да биде успешна заедно со соодветниот тест за
                  усогласување.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Ако се поставени и SPF и DKIM, тестот за било кој од нив треба
                  да биде успешен и да има усогласување. При тоа не се потребни
                  и двете.
                </Typography>
              </li>
            </ul>
            Ако се поставени двете автентикации, се вршат и двата теста за
            усогласување. Барањата за усогласување можат да бидат „strict“
            (домените треба прецизно да се совпаднат) или „relaxed“ (основните
            домени треба да се совпаднат, но дозволени се различни поддомени).
            <br></br>
            <br></br>
            Верификацијата за DMARC ќе биде успешна во следниве сценарија:
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Ако е поставена само една од автентикациите, нејзината
                  проверка мора да биде успешна заедно со соодветниот тест за
                  усогласување
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Ако се поставени двете, било кој од нив треба да биде успешен
                  со соодветниот тест за усогласување, не е потребно и двата
                </Typography>
              </li>
            </ul>
            Верификацијата за DMARC може да биде успешна дури и ако на пр. DKIM
            заедно со усогласувањето на DKIM не успеат, но SPF и неговото
            усогласување успеат (или обратно).
            <br></br>
            <br></br>
            DMARC ви овозможува да го конфигурирате дојдовниот сервер за е-пошта
            за тоа што треба да се случи со пораката за која проверката не
            успеала. Има три опции, познаи како полици - „polices“:
            <ul>
              <li>
                <Typography variant="subtitle1">
                  „None “- тоа значи дека пораката треба да се третира исто како
                  да не е поставен DMARC (пораката сè уште може да биде
                  доставена, ставена во spam или отфрлена врз основа на другите
                  фактори). Ова обично се користи за да се следи поставеноста на
                  трите политики и да се анализираат извештаите, без да се
                  влијае врз испораката на пораката
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  „Quarantine “–пораката се смета за дозволена, но не се
                  доставува во сандачето. Вообичаено ваквите пораки одат во
                  spam.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  „Reject “- веднаш се отфрла пораката кај која проверката била
                  неуспешна
                </Typography>
              </li>
            </ul>
            Горе-наведените „полиси“ може дополнително да се менуваат. Со полиса
            за “quarantine“ можете да му кажете на серверот да испрати само 10%
            од е-пошта со неуспешна проверка во spam и да ги игнорира (полиса -
            “none”) останатите 90% од пораките. На тој начин ќе и мате поголема
            контрола отколку при индивидуално користење на DKIM и SPF
            политиките.
            <br></br>
            <br></br>
            Конечно, серверот што прима пораки ќе испрати извештаи за секоја
            неуспешна проверка на DMARC и збирни извештаи за неуспешни проверки.
            Многу е корисно да се прави анализа на перформансите на вашите
            пораки преку овие извештаи и да бидете во тек, доколку се појават
            обиди за фишинг.
          </Typography>
          <img
            alt=""
            src={require("../../assets/img/dmarc.png")}
            width="70%"
            style={{
              marginTop: "3vh",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          ></img>
          <br></br>
          <br></br>
          <Typography variant="h4" id="whatDoesDmarcRecordLookLike">
            Како изгледа DMARC записот ?
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            Ова е пример за DMARC запис:<br></br>
            <br></br>
            <span style={{ backgroundColor: "#f9f97275" }}>
              v=DMARC1; p=reject; rua=mailto:dmarc-rua@square.com,
              mailto:dmarc_agg@vali.email, mailto:postmasters@squareup.com;
              ruf=mailto:dmarc-ruf@squareup.com
            </span>
            <br></br>
            <br></br> <Divider></Divider>
            <br></br>
            <span style={{ backgroundColor: "#f9f97275" }}>v=DMARC1</span>
            <br></br>
            <br></br>
            Ова е идентификаторот (верзија на DMARC) кој секогаш треба да биде
            вклучен во записот.
            <br></br>
            <br></br>
            <span style={{ backgroundColor: "#f9f97275" }}>p=reject</span>
            <br></br>
            <br></br>
            Полисата з аовој домен е поставена да ги одбива сите пораки кај кои
            DMARC проверката е неуспешна. Тие пораки сепак може да бидат
            доставени во одредени случаи.
            <br></br>
            <br></br>
            <span style={{ backgroundColor: "#f9f97275" }}>
              rua=mailto:dmarc-rua@square.com, mailto:dmarc_agg@vali.email,
              mailto:postmasters@squareup.com
            </span>
            <br></br>
            <br></br>
            Овие две адреси ќе добиваат дневни, сумарни извештаи за пораки кај
            кои не успеала верификацијата. Извештаите се општи податоци за
            причините за неуспешните верификации, без детали за соджрината на
            пораките. Пред секоја додадена адреса треба да стои „mailto:“
            <br></br>
            <br></br>
            <span style={{ backgroundColor: "#f9f97275" }}>
              ruf=mailto:dmarc-ruf@squareup.com{" "}
            </span>
            <br></br>
            <br></br>
            Ова е адресата на која во реално време ќе се испраќаат
            индивидуалните извештаи за форензика, вклучувајќи деталите за секоја
            неуспешна верификација.
            <br></br>
            <br></br>
            Постојат изборни полиња што може да се додадат на записот за
            дополнително прилагодување:
            <br></br>
            <br></br>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Ознака</StyledTableCell>
                  <StyledTableCell align="left">Значење</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <StyledTableRow key={row.tag}>
                    <StyledTableCell component="th" scope="row">
                      {row.tag}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.meaning}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <br></br>
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h4" id="whyYouShouldUseDmarc">
            Зошто треба да користите DMARC?
          </Typography>
          <br></br>
          <Typography variant="subtitle1">
            DMARC е најефективниот начин да се заштитите од измама по е-пошта.{" "}
            <br></br>
            <br></br> Ако веќе имате поставено SPF и/или DKIM записи, додавањето
            DMARC е само неколку дополнителни линии што треба да бидат вклучени
            во вашите DNS записи.
            <br></br>
            <br></br>
            Познато е дека DMARC е најефикасното оружје против спуферите. Две
            главни придобивки од DMARC:
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Сајбер криминалците многу веројатно ќе се откажат од обидот за
                  измама на доменот ако видат (правилно конфигурирани) DMARC
                  записи во DNS.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Серверите за примање на електронска пошта со вклучена DMARC
                  верификација можат да проценат дали пораката што доаѓа е од
                  кредибилен, верификуван и автентициран извор, т.е. испраќач.
                </Typography>
              </li>
            </ul>
          </Typography>
          <br></br>
          <br></br>
        </Grid>
        <Grid item xs={2} sm={2}></Grid>
      </Grid>
      <Footer></Footer>
    </React.Fragment>
  );
}
