import Axios from "axios";
import { SETTINGS } from "../../../properties/ApiProps";

export const UsersRepository = {
  fetchCurrentUser: () => {
    return Axios.get(SETTINGS.API_URL + "users/me");
  },
  fetchUser: (id) => {
    return Axios.get(`${SETTINGS.API_URL}users/${id}`);
  },
  fetchCurrentUserDetails: () => {
    return Axios.get(`${SETTINGS.API_URL}users/me/details`);
  },
  fetchAllUsers: (payload) => {
    return Axios({
      url: `${SETTINGS.API_URL}users`,
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        page: payload.page,
        size: payload.size,
        orderBy: payload.orderBy,
        orderDirection: payload.orderDirection,
        filterProperty: payload.filterBy,
        filterValue: payload.filterValue,
      },
    });
  },
  updateUser: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}users`,
      method: "PUT",
      data: data,
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },
  changeMyPassword: (data, oldPassword) => {
    return Axios({
      url: `${SETTINGS.API_URL}users/change_my_password`,
      method: "PUT",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      data: data,
      params: {
        oldPassword: oldPassword,
      },
    });
  },
  resetPassword: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}users/reset_password`,
      method: "PUT",
      data: data,
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },
  updateMyProfile: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}users/my`,
      method: "PUT",
      data: data,
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },
  createUser: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}users`,
      method: "POST",
      data: data,
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },
  deleteUser: (id) => {
    return Axios.delete(`${SETTINGS.API_URL}users/${id}`);
  },

  getUserTypes: () => {
    return Axios({
      url: `${SETTINGS.API_URL}users/types`,
      method: "GET",
    });
  },

  getAllClients: () => {
    return Axios({
      url: `${SETTINGS.API_URL}users/clients`,
      method: "GET",
    });
  },
};
