import { LOCALE } from "../../../../properties/Locale";
import * as yup from "yup";

export const administratorCreateUserForm = yup.object().shape({
  name: yup.string().required(LOCALE.name + LOCALE.required_field),
  email: yup
    .string()
    .required(LOCALE.email + LOCALE.required_field)
    .email(LOCALE.invalid_email_address_format),
  username: yup
    .string()
    .matches(/^[A-Za-z_-][A-Za-z0-9_-]{5,}$/, {
      message:
        LOCALE.incorrect_format +
        " (минимум 6 карактери, дозволени се мали и големи букви, цифри и -_)",
    })
    .required(LOCALE.username + LOCALE.required_field),
});

export const clientCreateUserForm = yup.object().shape({
  name: yup.string().required(LOCALE.name + LOCALE.required_field),
  email: yup
    .string()
    .required(LOCALE.email + LOCALE.required_field)
    .email(LOCALE.invalid_email_address_format),
  username: yup
    .string()
    .matches(/^[A-Za-z_-][A-Za-z0-9_-]{5,}$/, {
      message:
        LOCALE.incorrect_format +
        " (минимум 6 карактери, дозволени се мали и големи букви, цифри и -_)",
    })
    .required(LOCALE.username + LOCALE.required_field),
  domain: yup
    .string()
    .matches(
      /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
      {
        message:
          LOCALE.incorrect_format + " на " + LOCALE.domain + " (пр. aec.mk)",
      }
    )
    .required(LOCALE.username + LOCALE.required_field),
});
