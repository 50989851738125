import { LOCALE } from "../../../properties/Locale";
import * as yup from "yup";
import { options } from "date-fns/locale/af";

export const validateCreateUserForm = yup.object().shape({
  name: yup.string().required(LOCALE.name + LOCALE.required_field),
  username: yup
    .string()
    .matches(/^[A-Za-z_-][A-Za-z0-9_-]{5,}$/, {
      message:
        LOCALE.incorrect_format +
        " (минимум 6 карактери, дозволени се мали и големи букви, цифри и -_)",
    })
    .required(LOCALE.username + LOCALE.required_field),
  email: yup
    .string()
    .required(LOCALE.email + LOCALE.required_field)
    .email(LOCALE.invalid_email_address_format),
  password: yup
    .string()
    .required(LOCALE.login_password + LOCALE.required_field)
    .matches(
      /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      "Мора да содржи минимум 8 карактери, барем една голема буква, барем една мала буква, една цифра и еден специјален знак"
    ),
});

export const validateCreateUserFormClient = yup.object().shape({
  name: yup.string().required(LOCALE.name + LOCALE.required_field),
  username: yup
    .string()
    .matches(/^[A-Za-z_-][A-Za-z0-9_-]{5,}$/, {
      message:
        LOCALE.incorrect_format +
        " (минимум 6 карактери, дозволени се мали и големи букви, цифри и -_)",
    })
    .required(LOCALE.username + LOCALE.required_field),
  email: yup
    .string()
    .required(LOCALE.email + LOCALE.required_field)
    .email(LOCALE.invalid_email_address_format),
  password: yup
    .string()
    .required(LOCALE.login_password + LOCALE.required_field)
    .matches(
      /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      "Мора да содржи минимум 8 карактери, барем една голема буква, барем една мала буква, една цифра и еден специјален знак"
    ),
  domain: yup
    .string()
    .matches(
      /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
      {
        message:
          LOCALE.incorrect_format + " на " + LOCALE.domain + " (пр. aec.mk)",
      }
    )
    .required(LOCALE.domain + LOCALE.required_field),
});

export const validateEditUserForm = yup.object().shape({
  username: yup
    .string()
    .matches(/^[A-Za-z_-][A-Za-z0-9_-]{5,}$/, {
      message:
        LOCALE.incorrect_format +
        " (минимум 6 карактери, дозволени се мали и големи букви, цифри и -_)",
    })
    .required(LOCALE.username + LOCALE.required_field),
  name: yup
    .string()
    .nullable()
    .required(LOCALE.name + LOCALE.required_field),
  email: yup
    .string()
    .nullable()
    .required(LOCALE.email + LOCALE.required_field)
    .email(LOCALE.invalid_email_address_format),
});

export const validateEditUserFormClient = yup.object().shape({
  username: yup
    .string()
    .matches(/^[A-Za-z_-][A-Za-z0-9_-]{5,}$/, {
      message:
        LOCALE.incorrect_format +
        " (минимум 6 карактери, дозволени се мали и големи букви, цифри и -_)",
    })
    .required(LOCALE.username + LOCALE.required_field),
  name: yup
    .string()
    .nullable()
    .required(LOCALE.name + LOCALE.required_field),
  email: yup
    .string()
    .nullable()
    .required(LOCALE.email + LOCALE.required_field)
    .email(LOCALE.invalid_email_address_format),
  domain: yup
    .string()
    .matches(
      /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
      {
        message:
          LOCALE.incorrect_format + " на " + LOCALE.domain + " (пр. aec.mk)",
      }
    )
    .required(LOCALE.domain + LOCALE.required_field),
});

export const validatePassword = yup.object().shape({
  password: yup
    .string()
    .required(LOCALE.login_password + LOCALE.required_field)
    .matches(
      /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      "Мора да содржи минимум 8 карактери, барем една голема буква, барем една мала буква, една цифра и еден специјален знак"
    ),
});
