import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import { MailRepository } from "./MailRepository";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
// @material-ui/core components
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
// core components
import { LOCALE } from "../../../properties/Locale";
import {
  notifyShowSuccessMessage,
  notifyShowErrorMessage,
  changePageTitle,
} from "../../../common/actions/CommonActions";
import { UsersRepository } from "../users/UsersRepository";
import { SettingsRepository } from "../settings/SettingsRepository";
import Card from "../../../common/components/Card/Card.js";
import CardHeader from "../../../common/components/Card/CardHeader.js";
import CardBody from "../../../common/components/Card/CardBody.js";

export default function MailTemplateComponent() {
  const dispatch = useDispatch();
  const [mailTemplates, setMailTemplates] = useState(undefined);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [saveLoading, setSaveLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [systemProps, setSystemProps] = useState(undefined);

  const exportOptions = {
    inlineStyleFn: (styles) => {
      let key = "color-";
      let color = styles.filter((value) => value.startsWith(key)).first();

      if (color) {
        return {
          element: "span",
          style: {
            color: color.replace(key, ""),
          },
        };
      }
    },
  };

  const importOptions = {
    customInlineFn: (element, { Style }) => {
      if (element.style.color) {
        return Style("color-" + element.style.color);
      }
    },
  };

  useEffect(() => {
    dispatch(changePageTitle(LOCALE.mail_template));
    MailRepository.getAllMailTemplates().then((res) => {
      setMailTemplates(res.data);
      setSelectedTemplate(res.data[0]);
      setEditorState(
        EditorState.createWithContent(
          stateFromHTML(res.data[0].content, importOptions)
        )
      );
    });
    UsersRepository.fetchCurrentUserDetails().then((res) => {
      setCurrentUser(res.data);
    });
    SettingsRepository.fetchProbByKey("SMTP_EMAIL_FROM").then((res) => {
      setSystemProps(res.data);
    });
  }, []);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleSelectTemplate = (event, item) => {
    setEditorState(
      EditorState.createWithContent(stateFromHTML(item.content, importOptions))
    );
    setSelectedTemplate(item);
  };

  const saveTemplate = () => {
    setSaveLoading(true);
    let template = selectedTemplate;
    template.content = stateToHTML(
      editorState.getCurrentContent(),
      exportOptions
    );
    MailRepository.saveMailTemplate(template).then(
      (res) => {
        dispatch(notifyShowSuccessMessage(LOCALE.mail_template_save_success));
        setSaveLoading(false);
      },
      (err) => {
        dispatch(notifyShowErrorMessage(LOCALE.mail_template_save_failure));
        setSaveLoading(false);
      }
    );
  };

  const sendTestMail = () => {
    setSaveLoading(true);
    let template = selectedTemplate;
    let templateType = template.name;
    let params = {};
    let mailMessage = {
      sender: systemProps.value,
      receiver: currentUser?.email,
      subject: "",
    };
    for (let i = 0; i < template.mailTemplateParameters.length; i++) {
      params[template.mailTemplateParameters[i]] =
        "test" + template.mailTemplateParameters[i];
    }

    MailRepository.sendTestMail(mailMessage, templateType, params).then(
      (res) => {
        setSaveLoading(false);
      },
      (err) => {
        setSaveLoading(false);
      }
    );
  };

  return (
    <div>
      <Card>
        <CardHeader color="theme"></CardHeader>
        <CardBody>
          <Grid container spacing={2}>
            <Grid item md={3} sm={12} xs={12}>
              <List
                component="nav"
                aria-labelledby="privileges-list-subheader"
                aria-label="all-privileges"
              >
                {mailTemplates?.map((item) => (
                  <div key={item.name}>
                    <ListItem
                      button
                      selected={item.id === selectedTemplate?.id}
                      onClick={(event) => handleSelectTemplate(event, item)}
                    >
                      <ListItemText primary={LOCALE[item.name]} />
                    </ListItem>
                    <Divider />
                  </div>
                ))}
              </List>
            </Grid>
            <Grid item md={7} sm={12} xs={12}>
              <Card style={{ padding: "15px" }}>
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                />
                <Divider />
                <br />
                <div align="right">
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    disabled={saveLoading}
                    onClick={() => saveTemplate()}
                  >
                    {saveLoading && (
                      <CircularProgress size={24} style={{ color: "white" }} />
                    )}
                    {!saveLoading && LOCALE.save}
                  </Button>
                  <Button
                    style={{ marginLeft: "10px" }}
                    color="secondary"
                    size="small"
                    disabled={sendLoading}
                    variant="contained"
                    onClick={() => sendTestMail()}
                  >
                    {sendLoading && (
                      <CircularProgress size={24} style={{ color: "white" }} />
                    )}
                    {!sendLoading && LOCALE.mail_send_test_mail}
                  </Button>
                </div>
              </Card>
            </Grid>
            <Grid item md={2} xs={12} sm={12}>
              <Typography variant="h6">
                {LOCALE.mail_template_available_params}
              </Typography>
              <List
                component="nav"
                aria-labelledby="privileges-list-subheader"
                aria-label="all-privileges"
              >
                {selectedTemplate?.mailTemplateParameters?.map((param) => (
                  <div key={param}>
                    <ListItem key={param}>
                      <ListItemText primary={"${" + param + "}"} />
                    </ListItem>
                    <Divider />
                  </div>
                ))}
              </List>
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    </div>
  );
}
