import Axios from "axios";
import { SETTINGS } from "../../properties/ApiProps";

export const MainRepositroy = {
  checkSpfRecord: (domain, recaptchaToken) => {
    return Axios({
      url: SETTINGS.API_URL + "dns/lookup/spf",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        domain: domain,
        "g-recaptcha-response": recaptchaToken,
      },
    });
  },
  checkDkimRecord: (domain, selector, recaptchaToken) => {
    return Axios({
      url: SETTINGS.API_URL + "dns/lookup/dkim",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        selector: selector,
        domain: domain,
        "g-recaptcha-response": recaptchaToken,
      },
    });
  },
  checkDmarcRecord: (domain, recaptchaToken) => {
    return Axios({
      url: SETTINGS.API_URL + "dns/lookup/dmarc",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        domain: domain,
        "g-recaptcha-response": recaptchaToken,
      },
    });
  },
};
