import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
// @material-ui/core components
import {
  Dialog,
  IconButton,
  Icon,
  Grid,
  CircularProgress,
  Typography,
  Hidden,
  Paper,
  Avatar,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core";
// @material-ui/styles
import styles from "../../assets/jss/layouts/appStyle";
import { makeStyles } from "@material-ui/core/styles";
// core components
import { LOCALE } from "../../properties/Locale";
import { LoginRepository } from "./LoginRepository";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import LockIcon from "@material-ui/icons/Lock";
import { useDispatch, useSelector } from "react-redux";
import {
  notifyShowSuccessMessage,
  notifyShowErrorMessage,
  COMMON_ACTIONS,
} from "../../common/actions/CommonActions";
import Snackbar from "../../common/components/Snackbar/Snackbar.js";

const useStyles = makeStyles(styles);

export default function ResetPasswordComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const counter = useSelector((state) => state);

  const [redirectTo, setRedirectTo] = React.useState();
  const [newPassword, setNewPassword] = React.useState();
  const [confirmPassword, setConfirmPassword] = React.useState();

  useEffect(() => {}, []);

  const changePassword = () => {
    if (newPassword && newPassword?.trim() != "") {
      if (newPassword === confirmPassword) {
        LoginRepository.resetPassword(
          props.match.params.token,
          newPassword
        ).then(
          (res) => {
            dispatch(
              notifyShowSuccessMessage(LOCALE.successfuly_changed_password)
            );
            setRedirectTo("/login");
          },
          (err) => {
            dispatch(notifyShowErrorMessage(LOCALE.not_modified));
          }
        );
      } else {
        dispatch(notifyShowErrorMessage(LOCALE.password_doesnt_match));
      }
    } else {
      dispatch(notifyShowErrorMessage("Внесете нова лозинка"));
    }
  };
  const handleClose = () => {
    if (counter.open) {
      dispatch({
        type: COMMON_ACTIONS.CLEAR_NOTIFICATIONS,
        payload: null,
      });
    }
  };
  return (
    <div className={classes.loginWrapper}>
      {redirectTo && <Redirect to={redirectTo} />}
      <Hidden smDown implementation="css">
        <div className={classes.loginLeftPanel}>
          <img
            alt=""
            src={require("../../assets/img/grb.png")}
            width="200px"
            style={{ marginTop: "15%", opacity: "50%" }}
          ></img>
          <Typography component="h1" variant="h2" style={{ opacity: "60%" }}>
            <p style={{ fontSize: "18pt" }}>{LOCALE.login_h1}</p>
            {LOCALE.login_h2}
          </Typography>
        </div>
      </Hidden>

      <div className={classes.loginRightPanel}>
        <Hidden mdUp implementation="css">
          <div style={{ padding: "10px" }}>
            <img
              alt=""
              src={require("../../assets/img/grb.png")}
              width="40px"
              style={{ opacity: "50%" }}
            ></img>
            <Typography component="h1" variant="h2" style={{ opacity: "60%" }}>
              <p style={{ fontSize: "7pt" }}>{LOCALE.login_h1}</p>
              <p style={{ fontSize: "10pt" }}>{LOCALE.login_h2}</p>
            </Typography>
          </div>
        </Hidden>
        <Paper className={classes.paperLogin}>
          <Grid container className={classes.gridMargin}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <Avatar className={classes.avatarLogin}>
                <LockOutlinedIcon />
              </Avatar>
              <h2 className={classes.cardTitleGray}>
                {LOCALE.user_profile_change_password}
              </h2>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <div className={classes.panelLogin}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label={LOCALE.new_password}
              name="new_password"
              autoFocus
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Потврди лозинка"
              name="confirm_password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={2}></Grid>
            </Grid>
            <div style={{ marginTop: "3%", marginBottom: "3%" }}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => changePassword()}
              >
                Промени лозинка
              </Button>
            </div>
          </div>
        </Paper>
      </div>
      {counter.open && (
        <Snackbar
          place="br"
          icon={counter.variant}
          color={counter.variant}
          message={counter.message}
          open={true}
          closeNotification={handleClose}
          close
        />
      )}
    </div>
  );
}
